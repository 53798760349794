import { useState } from 'react';
import './EMI_Calculator.css'

import { withStyles } from '@material-ui/core/styles'; 
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { HiOutlineArrowRight } from 'react-icons/hi2';
import { Pie } from 'react-chartjs-2';

import { MdArrowBackIos } from 'react-icons/md'

import {Chart, ArcElement} from 'chart.js'
Chart.register(ArcElement);

const PrettoSlider =  withStyles({
    root: {color: '#3778A0', height: 10},
    thumb: {height: 22, width: 22, backgroundColor: 'white', border: "0px solid black", marginTop: -9, marginleft: -9},
    track: {height: 5, borderRadius: 4}, 
    rail: {height: 5, borderRadius: 4},
})(Slider);

function nFormatter(num) {
    const lookup = [
      { value: 1, symbol: "" },
    //   { value: 1e3, symbol: " k" },
      { value: 1e5, symbol: " Lakh" },
      { value: 1e7, symbol: " Crore" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(2).replace(rx, "$1") + item.symbol : "0";
}

export default function EMI_Calculator() {

    const [pDisplayAmount, setpDisplayAmount] = useState(46);

    const minDisplayValue = 1
    const maxDisplayValue = 116

    // const [interest, setinterest] = useState(7);
    const [pAmount, setpAmount] = useState(5000000);
    const [duration, setDuration] = useState(2);

    const minValue = 500000
    const minDuration = 1

    const maxValue = 200000000;
    const maxDuration = 20;
    
    
    
    // for emi calculation
    const [checkBox, setCheckBox] = useState()
    const [intrest, setInterest] = useState(0)

    // EMI = P x R x (1+R)^N / [(1+R)^N-1] 
    // where-
    // P = Principal loan amount
    // N = Loan tenure in months
    // R = Monthly interest rate
    // The rate of interest (R) on your loan is calculated per month.
    // R = Annual Rate of interest/12/100
    // If rate of interest is 7.2% p.a. then r = 7.2/12/100 = 0.006
    // The total amount payable will be EMI * 120 = ₹14,05,703

    var principalAmt = pAmount;
    var tDur = duration * 12
    // console.log(principalAmt, tDur);
    

    
    // Secured Loan
    const secMin = 8.25;
    const secMax = 10;
    const intSecMin = (secMin/12/100);
    const intSecMax = (secMax/12/100);

    var preSecLoanEMI = Math.round(((principalAmt * intSecMin) * (((1 + intSecMin)**tDur))) / ((1 + intSecMin)**tDur - 1)).toFixed(2)
    var postSecLoanEMI = Math.round(((principalAmt * intSecMax) * (((1 + intSecMax)**tDur))) / ((1 + intSecMax)**tDur - 1)).toFixed(2)
    // console.log((1 + 1)**(5-1));
    var preSecTotalPayable = (tDur * preSecLoanEMI).toFixed(2)
    var postSecTotalPayable = (tDur * postSecLoanEMI).toFixed(2)
    var preSectotalIntrest = Math.round(preSecTotalPayable - principalAmt).toFixed(2)
    var postSectotalIntrest = Math.round(postSecTotalPayable - principalAmt).toFixed(2)

    // Unsecured Loan 
    const unSecMin = 14;
    const unSecMax = 24;
    const intUnsecMin = (unSecMin/12/100);
    const intUnsecMax = (unSecMax/12/100);

    var preUnsecLoanEMI = Math.round(((principalAmt * intUnsecMin) * (((1 + intUnsecMin)**tDur))) / ((1 + intUnsecMin)**tDur - 1)).toFixed(2)
    var postUnsecLoanEMI = Math.round(((principalAmt * intUnsecMax) * (((1 + intUnsecMax)**tDur))) / ((1 + intUnsecMax)**tDur - 1)).toFixed(2)
    var preUnsecTotalPayable = ((tDur * preUnsecLoanEMI)).toFixed(2)
    var postUnsecTotalPayable = ((tDur * postUnsecLoanEMI)).toFixed(2)
    var preUnsectotalIntrest = Math.round(((preUnsecTotalPayable - principalAmt))).toFixed(2)
    var postUnsectotalIntrest = Math.round(((postUnsecTotalPayable - principalAmt))).toFixed(2)


    var lowEMI = document.getElementById('lowEMI')
    var highEMI = document.getElementById('highEMI')
    var lowROI = document.getElementById('lowROI')
    var highROI = document.getElementById('highROI')
    var prinAmt = document.getElementById('prinAmt')
    var lowInt = document.getElementById('lowInt')
    var highInt = document.getElementById('highInt')
    var lowTotalPay = document.getElementById('lowTotalPay')
    var highTotalPay = document.getElementById('highTotalPay')

    var div1 = document.getElementById('EMI-div-1')
    var div2 = document.getElementById('EMI-div-2')

    const getEMI = (data) => {
        div1.classList.remove('d-block')
        div1.classList.add('d-none')
        div2.classList.remove('d-none')
        div2.classList.add('d-block')
        
        
        if(data === '1'){
            setInterest(preSectotalIntrest)

            lowEMI.innerHTML = `₹ ${nFormatter(preSecLoanEMI)}`
            highEMI.innerHTML = `₹ ${nFormatter(postSecLoanEMI)}`
            lowROI.innerHTML = `${secMin}`
            highROI.innerHTML = `${secMax}%`
            prinAmt.innerHTML = `₹ ${nFormatter(pAmount)}`
            lowInt.innerHTML = `₹ ${nFormatter(preSectotalIntrest)}`
            highInt.innerHTML = `${nFormatter(postSectotalIntrest)}`
            lowTotalPay.innerHTML = `₹ ${nFormatter(preSecTotalPayable)}`
            highTotalPay.innerHTML = `${nFormatter(postSecTotalPayable)}`
        }

        if(data === '2'){
            setInterest(preUnsectotalIntrest)

            lowEMI.innerHTML = `₹ ${nFormatter(preUnsecLoanEMI)}`
            highEMI.innerHTML = `₹ ${nFormatter(postUnsecLoanEMI)}`
            lowROI.innerHTML = `${unSecMin}`
            highROI.innerHTML = `${unSecMax} %`
            prinAmt.innerHTML = `₹ ${nFormatter(pAmount)}`
            lowInt.innerHTML = `₹ ${nFormatter(preUnsectotalIntrest)}`
            highInt.innerHTML = `${nFormatter(postUnsectotalIntrest)}`
            lowTotalPay.innerHTML = `₹ ${nFormatter(preUnsecTotalPayable)}`
            highTotalPay.innerHTML = `${nFormatter(postUnsecTotalPayable)}`
        }
    }

    const getBack = () => {
        div1.classList.add('d-block')
        div1.classList.remove('d-none')
        div2.classList.add('d-none')
        div2.classList.remove('d-block')
    }

    const handleChange = (event, minu, maxu) => {
        const {name, value} = event.target
        const valu = Math.max(minu, Math.min(maxu, Number(value)));
        // console.log(valu);

        if(name === 'loanAmount'){
            if(valu/100000 <= 100){
                setpDisplayAmount(valu/100000 - 4)
            }else{
                setpDisplayAmount(valu/1000000 - 4)
            }
            setpAmount(value)
        }
        // else{
        //     setValues({
        //         ...values,
        //         [name] : parseFloat(value)
        //     })
        // }
    };

    

    return (
        <div className="row">

            <div id="EMI-div-1" className='d-block'>
                <div className="container d-flex back-light">
                    <div className="col-md-6 mt-4 px-sm-3 px-2 "> {/* px-sm-3 px-2 */}
                        <div>
                            <div className='d-flex justify-content-between'>
                                <Typography gutterBottom><strong>Loan Amount</strong></Typography>
                                {/* <p className='mb-1'>₹ {nFormatter(pAmount)} </p> */}
                                <input type='number' className='loan-amount text-center' name='loanAmount' value={pAmount} onChange={(e)=>{setpAmount(e.target.value);handleChange(e, 500000, 200000000)}} maxLength={maxValue} />
                            </div>
                            <PrettoSlider value={pDisplayAmount} onChange={(e,vAmt)=>{setpDisplayAmount(vAmt);setpAmount(vAmt <= 96 ? (vAmt + 4) * 100000 : (vAmt - 96) * 10000000 )}} step={1} defaultValue={pDisplayAmount} max={maxDisplayValue} min={minDisplayValue} />
                            <div className="d-flex justify-content-between">
                                <p className="mb-3">₹ {nFormatter(minValue)}</p>
                                <p>₹ {nFormatter(maxValue)}</p>
                            </div>
                        </div>
                        <div>
                            <div className='d-flex justify-content-between'>
                                <Typography gutterBottom><strong>Loan Tenure</strong></Typography>
                                {/* <p className="mb-1">{duration} years</p> */}
                                <input type='number' className='w-25 text-center' name='loanTerm' value={duration} onChange={(e)=>{setDuration(e.target.value)}} maxLength={maxDuration} />
                            </div>
                            <PrettoSlider value={duration} step={1} onChange={(e,vDur)=>setDuration(vDur)} defaultValue={duration} max={maxDuration} min={minDuration} />
                            <div className="d-flex justify-content-between">
                                <p className="mb-3">{nFormatter(minDuration)}</p>
                                <p>{nFormatter(maxDuration)}</p>
                            </div>
                        </div>

                        <div className="text-center mb-4">
                            <p><b>Select Loan Product Type</b></p>
                            <div className='px-1 d-flex'>
                                <div className='px-1'>
                                    <input type="radio" name="loneType" value={1} className='me-1' onChange={(e)=>setCheckBox('1')} />Secured Business Loan
                                </div>
                                <div className='px-1'>
                                    <input type="radio" name="loneType" value={2} className='me-1' onClick={()=>setCheckBox('2')} />Unsecured Business Loan
                                </div>
                            </div>
                            <button disabled={checkBox === undefined ? true : false} onClick={(e)=>{getEMI(checkBox);e.preventDefault()}} className="btn btn-primary w-100 mt-4">
                                Next {'>>'}
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 d-none d-md-block p-4 emi-part1-img">
                        <img src={require("../images/EMI-1.png")} className='border border-1 rounded-4' alt="EMI" width='100%' />
                    </div>
                </div>
            </div>

            <div id="EMI-div-2" className='d-none '>
                <div className="container back-light">
                    <button onClick={getBack} className='mt-2 mb-0'><MdArrowBackIos /> Back</button>
                    <div className="container d-flex flex-wrap  ">
                        <div className="col-md-6 mt-4 px-3">
                            <p><b>Your Estimated EMI</b></p>
                            <div className="emi-part2-box p-2 text-center rounded-4 bg-white">
                                <h4 className='fw-bold'><span id="lowEMI"></span> - <span id="highEMI"></span></h4>
                                <p className='mb-2'>ROI : <span id="lowROI"></span> - <span id="highROI"></span></p>
                            </div>
                            <div className="row mt-4 small-text">
                                <div className="container chartHome d-md-flex align-items-md-center">
                                    <div className="col-md-4 charts px-sm-5 px-2 pb-4 ps-md-0 pe-md-3">
                                        <Pie data={{
                                            labels : ['Total Intrest', 'Total Amount'],
                                            datasets : [{
                                                data : [intrest, principalAmt],
                                                backgroundColor : ['#79BE43', '#FFDE3C']
                                            }],
                                        }} 
                                        width={200}
                                        height={200}
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="d-flex justify-content-between">
                                            <p className='mb-1'>🟡 Principal Amount</p>
                                            <p id="prinAmt"></p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className='mb-1'>🟢 Interest Amount</p>
                                            <p className='mb-1'><span id="lowInt"></span> - <span id="highInt"></span></p>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between">
                                            <p className='mb-1'>Total amount payable</p>
                                            <p className='mb-1 h5'><span id="lowTotalPay"></span> - <span id="highTotalPay"></span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 ">
                                <div className="container">
                                <div className="text-center mb-4 col-12">
                                    <a href="/resources/check-eligibility" className="btn btn-primary w-100 py-2">Check Eligibility<HiOutlineArrowRight className='ms-2'/></a>
                                </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-6 d-none d-md-block p-4 emi-part1-img">
                            <img src={require("../images/EMI-2.png")} className='border border-1 rounded-4' alt="EMI" width='100%' />
                        </div>
                    </div>
                </div>
            </div>


                
                
        </div>
    )
}
