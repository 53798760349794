import BusinessLoanSwiper from '../components/BusinessLoanSwiper'
import HelpSection from '../components/Common/HelpSection'
import { HomeHelpSlider } from '../components/Common/HelpSliderItems'
import WhatsappConnect from '../components/Common/whatsappConnect'
import EMICalculator from '../components/EMI_Calculator'
import {FAQ} from '../components/FAQ'
import FundingOpportunities from '../components/FundingOpportunities'
import GettingStarted from '../components/GettingStarted'
import PatnersSwiper from '../components/PartnersSwiper'
import Slideshow from '../components/Slideshow'
import USP from '../components/USP'
import './home.css'
import { CiBank, CiShop } from 'react-icons/ci'
import { HiOutlineCalendarDays } from 'react-icons/hi2'

export default function Home(){
    return (
        <>
            {/* Slideshow  */}
            <div className="sildeshow home">
                <Slideshow />
                <div className="container statistics">
                    <div className="box-div col-lg-9 col-12 mx-auto d-flex justify-content-evenly"> 
                        <div className="shops d-md-flex px-1 px-md-auto mt-2">
                            <CiShop />
                            <div className='ps-1 '>
                                <p className="primary-color mb-0">5,000+</p>
                                <p>SMEs Assisted</p>
                            </div>
                        </div>
                        <div className="calender d-md-flex px-1 px-md-auto mt-2">
                            <HiOutlineCalendarDays />
                            <div className='ps-1 '>
                                <p className="primary-color mb-0">3-7 Days</p>
                                <p>Business Loan Disbursed</p>
                            </div>
                        </div>
                        <div className="bank d-md-flex px-1 px-md-auto mt-2">
                            <CiBank />
                            <div className='ps-1 '>
                                <p className="primary-color mb-0">20+</p>
                                <p>Banks Tie-ups</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Eligibility  */}
            <div className="bg-light-gray help-section">
                <div className="eligibility container d-flex justify-content-between align-items-center flex-wrap">
                    <HelpSection prop={HomeHelpSlider} />
                </div>
            </div>

            {/* Partners  */}
            <div className="partners container mt-5 pb-4 text-center">
                <p className="primary-color mb-1">Bank Tie-ups</p>
                <h2>20+ SME Lending Partners</h2>
                <PatnersSwiper />
            </div>

            {/* Business Loan  */}
            <div className='bg-light-green'>
                <div className="container pt-4">
                    <BusinessLoanSwiper />
                </div>
            </div>

            {/* Funding / OLS  */}
            <div className="funding my-5">
                <div className="container mt-5">
                    <div className="text-center">
                        <p className="primary-color mb-1">Our Loan Services</p>
                        <h2>Explore Limitless  Funding Opportunities</h2>
                    </div>
                </div>
                <div className="container d-flex flex-wrap my-4">
                    <FundingOpportunities />
                </div>
            </div>

            {/* Bank tieup / Emi calculator  */}
            <div className="bg-dark-blue py-5">
                <div className="container mb-4 text-center">
                    <p className="text-white">Bank Tie-ups</p>
                    <h2 className="text-white">Business Loan EMI Calculator</h2>
                </div>
                <div className="emi-calculator container">
                    <div className="col-lg-8 col-12 mx-auto">
                        <EMICalculator />
                    </div>
                </div>
            </div>
            
            {/* Getting started  */}
            <div className="process my-5">
                <div className="px-5 container text-center">
                    <p className='primary-color mb-1'>How it works</p>
                    <h2 className='pb-4'>Getting Started is Easy!</h2>
                    <img src={require("../images/numberLine.png")} className='mb-4 d-none d-md-block' alt="Number Line" width='100%' />
                </div>
                <div className="container d-flex flex-wrap">
                    <GettingStarted />
                </div>
            </div>

            {/* USP  */}
            <div className="usp bg-cream">
                <div className="container text-center pt-5 my-4">
                    <p className='primary-color mb-1'>Our USP</p>
                    <h2>What sets us apart?</h2>
                </div>
                <div className="container d-flex flex-wrap pb-4">
                    <USP />
                </div>
            </div>

            {/* Frequently asked questions  */}
            <div className="faq">
                <div className="container text-center">
                    <p className="primary-color mb-0 pt-5">Useful Links</p>
                    <h2 className='my-4'>Frequently Asked Questions</h2>
                    <p>Everything you need to know about  loan  services and other finance option</p>
                </div>
                <div className="container">
                    <FAQ />
                </div>
            </div>

            {/* Whatsapp connect  */}
            <WhatsappConnect />

        
        </>
    )
}