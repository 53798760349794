import React from 'react'
import './aboutUs.css'
import BannerWithoutPartner from '../components/Common/BannerWithoutPartner'
import { aboutUsBanner } from '../components/Common/BannerItems'
import HelpSection from '../components/Common/HelpSection'
import { AUCoreValues, AUHelpSlider } from '../components/Common/HelpSliderItems'
import GettingStarted from '../components/GettingStarted'
import USP from '../components/USP'
import WhatsappConnect from '../components/Common/whatsappConnect'

export default function aboutUs() {
  return(
    <>
      {/* Banner */}
      <div className="banner about">
        <BannerWithoutPartner prop={aboutUsBanner} />
      </div>
      
      {/* Help section  */}
      <div className="bg-light-gray help-section">
        <div className="eligibility container d-flex justify-content-between align-items-center flex-wrap">
          <HelpSection prop={AUHelpSlider} />
        </div>
      </div>

      {/* Getting started  */}
      <div className="process my-5">
        <div className="px-5 container text-center">
          <p className='primary-color mb-1'>How it works</p>
          <h2 className='pb-4'>Our Loan Sanction Process is easy!</h2>
          <img src={require("../images/numberLine.png")} className='mb-4 d-none d-md-block' alt="Number Line" width='100%' />
        </div>
        <div className="container d-flex flex-wrap">
          <GettingStarted />
        </div>
      </div>

      {/* Our Mission  */}
      <div className="bg-mission py-lg-0 py-4">
        <div className="container d-flex flex-wrap justify-content-center align-items-center">
          <div className="col-md-6 p-lg-5">
            <img src={require("../images/AU-mission.png")} alt="Img" className="img-fluid w-100 p-lg-5 p-md-0 p-3" />
          </div>
          <div className="col-md-6 text-white px-lg-5 ps-md-2 pe-md-0 px-3">
            <p className='pb-2 pt-3 py-md-0'>Our Mission</p>
            <h1 className='pb-2 pb-md-0 mb-4'>Paving the Path to Excellence</h1>
            <p style={{ textAlign : 'justify' }}>Our mission at Finexa Capital is to be a trusted partner on our client's journey towards financial prosperity. We are committed to offering a diverse range of financial consultancy services, including Business Loans, Home Loans, and more, designed to meet the unique needs of every individual and business we serve. With a focus on understanding, transparency, and personalized solutions, we aim to deliver exceptional value by leveraging our expertise and market insights. By fostering strong relationships built on trust and integrity, we aspire to be the driving force that helps our clients achieve their financial goals and realize their ambitions.</p>
          </div>
        </div>
      </div>

      {/* USP  */}
      <div className="usp bg-cream mt-0">
        <div className="container text-center pt-5 mb-4">
          <p className='primary-color mb-1'>Our USP</p>
          <h2>What sets us apart?</h2>
        </div>

        <div className="container d-flex flex-wrap pb-4">
          <USP />
        </div>
      </div>

      {/* Core Values  */}
      <div className="bg-light-gray help-section core">
        <div className="eligibility container d-flex justify-content-between align-items-center flex-wrap pt-md-4 pt-lg-0">
          <HelpSection prop={AUCoreValues} />
        </div>
      </div>

      {/* Opportunities  */}
      <div className="opportunities position-relative">
        <img src={require("../images/AU-background.png")} alt="Contact" className="img-fluid w-100 bg-img" />
        <div className="abso w-100 top-0">
          <div className="container d-flex flex-wrap justify-content-around align-content-center pt-4">
            <div className="col-lg-4 col-md-6 "> {/* d-none d-md-block */}
              <img src={require("../images/AU-contact.png")} alt="Img" className="img-fluid w-100 p-lg-4 mt-md-3 mt-lg-0 p-3" />
            </div>
            <div className="col-md-5 py-lg-4 py-4 px-md-0 px-2">
              <h1>Explore Limitless Funding Opportunities</h1>
              <p className='py-2' style={{ textAlign : 'justify' }}>Whether you're a budding entrepreneur or an established business, we've got the right financial solutions just for you. Let's turn your ideas into reality. Connect with us today and let's discuss your desired funding needs. Your journey to financial success starts here!</p>
              <a href='/contact-us' className="btn btn-primary px-4 py-2 mt-2 mb-3 mb-lg-0">Contact Us Now !</a>
            </div>
            {/* <div className="col-lg-4 col-md-6 d-md-none">
              <img src={require("../images/AU-contact.png")} alt="Img" className="img-fluid w-100 p-lg-4 mt-md-3 mt-lg-0 p-3" />
            </div> */}
          </div>
        </div>
      </div>
      

      {/* Whatsapp connect  */}
      <WhatsappConnect />
    </>
  )
}

