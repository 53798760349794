import React, { useState } from 'react'
import './Benifits.css'
import RetailFeatures from './RetailFeatures'
import { HLFeatures } from '../Common/FeatureItems'

export default function HomeBenifits() {
    const [opened, setOpened] = useState(1)

    const handleChange = (data) => {
        setOpened(data)
    }


    return (
        <>
            <div className="back-gray">
                <div className="container d-flex flex-wrap text-center">
                    <div className="col-6">
                        <button className={`py-md-2 py-3 px-md-4 px-2 h-100 ${opened === 1 ? 'current' : ''}`} id='FButton' onClick={()=>handleChange(1)} >
                            Features & Benefits
                        </button>
                    </div>
                    <div className="col-6">
                        <button className={`py-md-2 py-3 px-md-4 px-2 h-100 ${opened === 2 ? 'current' : ''}`} id='EButton' onClick={()=>handleChange(2)} >
                            Eligibility
                        </button>
                    </div>
                </div>
            </div>

            {
                opened === 1 ?
                <div id="Ftxt" className=' mt-5'>
                    <div className="container pb-4 px-md-5">
                        <div className=' '>
                            <p className='primary-color'>Home Loan</p>
                            <h1>Benefits of taking a Home Loan</h1>
                        </div>
                    </div>
                    <RetailFeatures prop={HLFeatures} />
                    <div className="container px-md-5 px-3">
                        <div className="pb-4">
                            <h1 className='mb-3'>Home Loan Overview</h1>
                            <p>A home loan, also known as a mortgage, is a fixed amount of money borrowed from banks and NBFCs to purchase a property. Housing loans can be taken out for the purchase of new houses, apartments, and plots, while home loans are typically used for renovation, repairs, or extensions on an existing property. It is essential to examine the home loan interest rate carefully to ensure that you can afford the EMIs.</p>
                            <p>The soaring cost of real estate has led more people to opt for home loans to become homeowners. When you obtain a home loan, the most crucial factor is that the lender keeps your property as a mortgage until you fully pay off the debt through monthly instalments. The lender uses a home loan calculator to determine the EMI amount you owe.</p>
                        </div>

                        <div className="pb-4">
                            <h1 className="mb-3">Home Loan Tax Benefits</h1>
                            <p>Maximize your tax benefits on home loan interest payments with Section 80 EEA. Claim deductions of up to INR 1.5 lakhs on the interest component, ensuring significant tax savings while repaying your home loan.</p>

                            <div className="col-12 col-lg-8 col-md-10 mt-4 mb-5 mx-auto">
                                <table className='table table-bordered text-center'>
                                    <thead>
                                        <th className='border py-2'>Particulars</th>
                                        <th className='border py-2'>Quantum of Amount Deduction</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Under Section 24</td>
                                            <td className='text-start'>
                                                a. For self-occupied property: ₹ 2,00,000 <br />
                                                b. For non-self occupied property: No set limit
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Under Section 24</td>
                                            <td className='text-start'>
                                                a. For self-occupied property: ₹ 2,00,000 <br />
                                                b. For non-self occupied property: No set limit
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Under Section 24</td>
                                            <td className='text-start'>
                                                a. For self-occupied property: ₹ 2,00,000 <br />
                                                b. For non-self occupied property: No set limit
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div id="Etxt" className='mt-5'>
                    <div className="container px-md-5 px-3">
                        <div className="pb-4">
                            <p className="primary-color">Home Loan</p>
                            <h1 className='mb-3'>Eligibility for Home Loan</h1>
                            <p>Banks and finance companies have pre-determined home loan eligibility criteria to verify and provide maximum ease to loan borrowers. Apart from the eligibility criteria, banks look at the applicant's credit history to establish creditworthiness and repayment patterns.</p>
                            <ul className="ul-style">
                                <li>Applicant must be between 21 to 65 years</li>
                                <li>Salaried or self employed</li>
                                <li>Annual income</li>
                                <li>Collateral security</li>
                                <li>Assets, financial stability and occupational continuity</li>
                                <li>Residence status (Indian or NRI)</li>
                            </ul>
                        </div>
                        <div className="pb-4">
                            <h1 className="mb-3">Reasons for Home Loan Rejection</h1>
                            <p>Common reasons for home loan rejections include:</p>
                            <ul className="ul-style">
                                <li>Outstanding debts and unpaid dues</li>
                                <li>Applicant's address appearing on the defaulter's list</li>
                                <li>Age of the applicant falling outside the eligible range</li>
                                <li>Low credit score below 600</li>
                                <li>Previous rejections of loan applications</li>
                                <li>High frequency of job changes</li>
                                <li>Irregular repayment of existing debts</li>
                                <li>Insufficient documentation</li>
                            </ul>
                        </div>
                        <div className="pb-4">
                            <h1 className="mb-3">Required Home Loan Document</h1>
                            <p>You need these  documents to get the Home loan approved</p>

                            <div className="col-lg-8 col-md-10 col-12 mt-4 mb-5 mx-auto">
                                <table className='table table-bordered text-center'>
                                    <thead>
                                        <th className='border py-2'>Type</th>
                                        <th className='border py-2'>Documents include</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Identity Poof</td>
                                            <td  className='text-start'>PAN and Aadhar Card, Applicant's Passport, Voter ID Card, Driving Licence</td>
                                        </tr>
                                        <tr>
                                            <td>Address Proof</td>
                                            <td  className='text-start'>Utility bills (electricity or telephone bill), Salary slips of last 6 months and Form 16, Identity proof that contain address, like Aadhaar CardBank account statements, Income proof and employment proof</td>
                                        </tr>
                                        <tr>
                                            <td>Other Document</td>
                                            <td  className='text-start'>Passport-size photographs of the applicant, <br />
                                            For self-employed, business continuity proof will be required (5 years)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </>
    )
}

