// Main navbar 
export const navItems = [
    {
        id: 1,
        title : 'SME Products',
        path : '#',
        cName : 'nav-item'
    },
    {
        id: 2,
        title : 'Retail Loan',
        path : '#',
        cName : 'nav-item'
    },
    {
        id: 3,
        title : 'Resources',
        path : '#',
        cName : 'nav-item'
    },
    {
        id: 4,
        title : 'About Us',
        path : '/about-us',
        cName : 'nav-item'
    },
]



// Vertical dropdown 
export const smeProducts = [
    {
        id: 1,
        title : 'Secured Business Loans',
        path : '/secured-business-loan/secured-term-loan',
        cName : 'submenu-item'
    },
    {
        id: 2,
        title : 'Unsecured Business Loans',
        path : '/unsecured-business-loan/unsecured-term-loan',
        cName : 'submenu-item'
    },
    {
        id: 3,
        title : 'Balance Transfer',
        path : '/balance-transfer',
        cName : 'submenu-item'
    },
    {
        id: 4,
        title : 'Other services',
        path : '/other-services',
        cName : 'submenu-item'
    },
]

export const retailLoan = [
    {
        id: 1,
        title : 'Home Loan',
        path : '/retail-loan/home-loan',
        cName : 'submenu-item'
    },
    {
        id: 2,
        title : 'Education Loan',
        path : '/retail-loan/education-loan',
        cName : 'submenu-item'
    },
    {
        id: 3,
        title : 'Loan Against Property (LAP)',
        path : '/retail-loan/loan-against-property',
        cName : 'submenu-item'
    },
    {
        id: 4,
        title : 'Balance Transfer',
        path : '/retail-loan/balance-transfer',
        cName : 'submenu-item'
    }
]

export const resources = [
    // {
    //     id: 1,
    //     title : 'Enquiry',
    //     path : '/resources/enquiry',
    //     cName : 'submenu-item'
    // },
    {
        id: 2,
        title : 'EMI Calculator',
        path : '/resources/emi-calculator',
        cName : 'submenu-item'
    },
    {
        id: 3,
        title : 'Eligibility Check',
        path : '/resources/check-eligibility',
        cName : 'submenu-item'
    }
]

export const aboutUs = [
    // {
    //     id: 1,
    //     title : 'About Finexa Capital',
    //     path : '#',
    //     cName : 'submenu-item'
    // },
    // {
    //     id: 2,
    //     title : 'Privacy Policy',
    //     path : '#',
    //     cName : 'submenu-item'
    // }
]



// Horizontal dropdown 
export const securedBusinessLoans = [
    {
        id: 1,
        title : 'Secured term loans',
        path : '/secured-business-loan/secured-term-loan',
        cName : 'submenu-item horizontal-submenu-item'
    },
    {
        id: 2,
        title : 'Machinery / Equipment loan',
        path : '/secured-business-loan/machinery-equipment-loan',
        cName : 'submenu-item horizontal-submenu-item'
    },
    {
        id: 3,
        title : 'Working capital loan',
        path : '/secured-business-loan/working-capital-loans',
        cName : 'submenu-item horizontal-submenu-item'
    },
    // {
    //     id: 4,
    //     title : 'Secured cash credit',
    //     path : '/secured-business-loan/secured-cash-credit',
    //     cName : 'submenu-item horizontal-submenu-item'
    // },
    {
        id: 5,
        title : 'Loan against property',
        path : '/secured-business-loan/loan-against-property',
        cName : 'submenu-item horizontal-submenu-item'
    },
    // {
    //     id: 6,
    //     title : 'Secured overdraft',
    //     path : '/secured-business-loan/secure-overdraft',
    //     cName : 'submenu-item horizontal-submenu-item'
    // },
    {
        id: 7,
        title : 'Loans under CGTMSE scheme',
        path : '/loans-under-cgtmse-scheme',
        cName : 'submenu-item'
    },
]

export const unsecuredBusinessLoans = [
    {
        id: 1,
        title : 'Unsecured term loans',
        path : '/unsecured-business-loan/unsecured-term-loan',
        cName : 'submenu-item horizontal-submenu-item'
    },
    {
        id: 2,
        title : 'Invoice discounting',
        path : '/unsecured-business-loan/invoice-discounting',
        cName : 'submenu-item horizontal-submenu-item'
    },
    // {
    //     id: 3,
    //     title : 'Line of credit',
    //     path : '/unsecured-business-loan/line-of-credit',
    //     cName : 'submenu-item horizontal-submenu-item'
    // },
    // {
    //     id: 4,
    //     title : 'Unsecured cash credit',
    //     path : '/unsecured-business-loan/unsecured-cash-credit',
    //     cName : 'submenu-item horizontal-submenu-item'
    // }
]

export const otherServices = [
    {
        id: 1,
        title : 'Credit card',
        path : '/other-services/credit-card',
        cName : 'submenu-item horizontal-submenu-item'
    },
    {
        id: 2,
        title : 'Insurance',
        path : '/other-services/insurance',
        cName : 'submenu-item horizontal-submenu-item'
    },
    {
        id: 3,
        title : 'Investments',
        path : '/other-services/investments',
        cName : 'submenu-item horizontal-submenu-item'
    },
    {
        id: 4,
        title : 'Funding for start ups',
        path : '/other-services/funding-for-startups',
        cName : 'submenu-item horizontal-submenu-item'
    }
]


