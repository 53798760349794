// Secured Business Loans 
export const STLLoanSwiper = [
    {
        id : 1,
        img : require('../../images/SBL-Images/loanSliderImages/Loan-Against-Property.png'),
        top : 'Other Secured Business Loan Options',
        heading : 'Loan Against Property',
        bottom : 'Unlock the Value of Your Property with Loan Against Property. Utilize your property worth as collateral to access significant funds for various business expenses. Benefit from competitive interest rates and flexible repayment options, empowering your business to thrive.'
    },
    {
        id : 2,
        img : require('../../images/SBL-Images/loanSliderImages/Machinery-Equipment-Loan.png'),
        top : 'Secured Terms Loan Options',
        heading : 'Equipment / Machinery Loan',
        bottom : 'Designed exclusively to support the purchase or upgrade of essential machinery and equipment, our financing solutions provide the capital you need to invest in productivity-enhancing assets. Boost operational efficiency, unlock growth potential, and take your business to new heights with our tailored financing options.'
    },
    {
        id : 3,
        img : require('../../images/SBL-Images/loanSliderImages/Invoice-Discounting.png'),
        top : 'Secured Terms Loan Options',
        heading : 'Invoice Discounting',
        bottom : 'Obtain instant working capital by selling your unpaid invoices to a lender at a discounted rate. Meet your businesses financial needs and ensure smooth operations while leveraging the value of your invoices.'
    },
    {
        id : 4,
        img : require('../../images/SBL-Images/loanSliderImages/Working-Capital.png'),
        top : 'Secured Terms Loan Options',
        heading : 'Working Capital Loan',
        bottom : 'Access essential funds to support day-to-day operations, manage cash flow, and meet immediate expenses. Ensure smooth business operations and foster growth with our flexible short-term financing solutions.'
    },
    {
        id : 5,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Overdraft.png'),
        top : 'Secured Terms Loan Options',
        heading : 'Secured Overdraft',
        bottom : 'Access a flexible credit line for your business, allowing you to withdraw funds beyond your account balance, up to a predetermined limit. Manage short-term cash flow gaps and handle unexpected expenses with ease. Apply now to seize this opportunity!'
    }

]

export const MELLoanSwiper = [
    {
        id : 1,
        img : require('../../images/SBL-Images/loanSliderImages/Loan-Against-Property.png'),
        top : "Other Secured Business Loan Options",
        heading : "Loan Against Property",
        bottom : "Unlock the Value of Your Property with Loan Against Property. Utilize your property's worth as collateral to access significant funds for various business expenses. Benefit from competitive interest rates and flexible repayment options, empowering your business to thrive."
    },
    {
        id : 2,
        img : require('../../images/SBL-Images/loanSliderImages/Invoice-Discounting.png'),
        top : "Other Secured Business Loan Options",
        heading : "Invoice Discounting",
        bottom : "Accelerate your cash flow by selling unpaid invoices at a discounted rate, gaining immediate access to cash. Enhance your working capital and improve cash flow instantly through our invoice discounting financial solution."
    },
    {
        id : 3,
        img : require('../../images/SBL-Images/loanSliderImages/Balance-Transfer.png'),
        top : "Other Secured Business Loan Options",
        heading : "Balance Transfer",
        bottom : "Transfer your existing business loan balance to a new lender offering improved terms, interest rates, and repayment options. Save money and manage your loans more effectively with our balance transfer solution. Apply now to seize this opportunity!"
    },
    {
        id : 4,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Overdraft.png'),
        top : "Other Secured Business Loan Options",
        heading : "Secured Overdraft",
        bottom : "Gain flexible access to additional funds beyond your business bank account balance, up to a predetermined limit. Effectively manage short-term cash flow gaps, tackle unexpected expenses, and enjoy greater financial flexibility through our overdraft facilities."
    },
    {
        id : 5,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Term-Loan.png'),
        top : "Other Secured Business Loan Options",
        heading : "Secured Term Business Loan",
        bottom : "Secured business loans are a type of financing alternative that necessitates collateral, including property or assets, to reduce the lender's risk and provide borrowers with more favorable interest rates."
    }

]

export const LAPLoanSwiper = [
    {
        id : 1,
        img : require('../../images/SBL-Images/loanSliderImages/Machinery-Equipment-Loan.png'),
        top : "Other Secured Business Loan Options",
        heading : "Equipment / Machinery Loan",
        bottom : "A loan for equipment or machinery provides you with the required funds to obtain or improve your business equipment. This type of financing allows you to maintain your cash flow while obtaining important assets, improving how your business operates, and facilitating its expansion."
    },
    {
        id : 2,
        img : require('../../images/SBL-Images/loanSliderImages/Working-Capital.png'),
        top : "Other Secured Business Loan Options",
        heading : "Working Capital Loan",
        bottom : "A loan for working capital provides you with the funding required to handle everyday operational costs, manage fluctuations in cash flow, bridge temporary gaps, and take advantage of growth opportunities. This ensures the continuity and success of your business."
    },
    {
        id : 3,
        img : require('../../images/SBL-Images/loanSliderImages/Balance-Transfer.png'),
        top : "Other Secured Business Loan Options",
        heading : "Balance Transfer",
        bottom : "A balance transfer for business loans enables you to move your current loan balance to a new lender who offers improved terms, interest rates, and repayment options. This process helps you save money and enhances your overall loan management."
    },
    {
        id : 4,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Overdraft.png'),
        top : "Other Secured Business Loan Options",
        heading : "Secured Overdraft",
        bottom : "A secured overdraft provides your business with a flexible credit line that allows you to withdraw funds beyond your account balance, up to a predetermined limit. This facility assists you in conveniently managing short-term cash flow gaps and unexpected expenses."
    },
    {
        id : 5,
        img : require('../../images/SBL-Images/loanSliderImages/Invoice-Discounting.png'),
        top : "Other Secured Business Loan Options",
        heading : "Invoice Discounting",
        bottom : "Invoice discounting loans provide you with the opportunity to generate immediate cash flow by selling your unpaid invoices to a lender at a reduced price. This form of business loan grants you working capital and improves your liquidity, ensuring seamless operations and facilitating growth."
    }

]

export const SCCLoanSwiper = [
    {
        id : 1,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Term-Loan.png'),
        top : "Other Secured Business Loan Options",
        heading : "Secured Business Loan",
        bottom : "Secured business loans are a type of financing alternative that necessitates collateral, including property or assets, to reduce the lender's risk and provide borrowers with more favorable interest rates."
    },
    {
        id : 2,
        img : require('../../images/SBL-Images/loanSliderImages/Machinery-Equipment-Loan.png'),
        top : "Other Secured Business Loan Options",
        heading : "Equipment / Machinery Loan",
        bottom : "An equipment/machinery loan is a specialized financing solution created to assist you in acquiring the machinery, tools, or equipment necessary for your business operations. In some cases, the equipment itself can be used as collateral for the loan."
    },
    {
        id : 3,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Overdraft.png'),
        top : "Other Secured Business Loan Options",
        heading : "Secured Overdraft",
        bottom : "A business overdraft facility offers your company the flexibility to access extra funds beyond your current account balance. This allows you to effectively handle short-term cash flow gaps and effectively manage unforeseen expenses."
    },
    {
        id : 4,
        img : require('../../images/SBL-Images/loanSliderImages/Working-Capital.png'),
        top : "Other Secured Business Loan Options",
        heading : "Working Capital Loan",
        bottom : "A working capital loan offers you the essential funds required to address your everyday operational expenses, including inventory, payroll, and overhead costs. This financing solution ensures the seamless functioning of your business and effective management of cash flow."
    },
    {
        id : 5,
        img : require('../../images/SBL-Images/loanSliderImages/Loan-Against-Property.png'),
        top : "Other Secured Business Loan Options",
        heading : "Loan Against Property",
        bottom : "A property-backed loan is a secured financing option that enables you to borrow funds by utilizing your owned immovable property as collateral. This type of loan provides access to capital for a variety of business requirements."
    }
]

export const WCLLoanSwiper = [
    {
        id : 1,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Term-Loan.png'),
        top : "Other Secured Business Loan Options",
        heading : "Secured Term Business Loan",
        bottom : "Secured term business loans are financial solutions that necessitate collateral, providing you with the advantage of lower interest rates and extended repayment duration's. These loans grant you access to funds that facilitate growth, expansion, and cater to your various operational requirements."
    },
    {
        id : 2,
        img : require('../../images/SBL-Images/loanSliderImages/Machinery-Equipment-Loan.png'),
        top : "Other Secured Business Loan Options",
        heading : "Equipment / Machinery Loan",
        bottom : "An equipment/machinery loan is a specialized business financing option tailored to support the acquisition, upgrade, or repair of machinery and equipment. These loans provide businesses with the essential funds needed to boost productivity, optimize operations, and foster growth."
    },
    {
        id : 3,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Overdraft.png'),
        top : "Other Secured Business Loan Options",
        heading : "Secured Overdraft",
        bottom : "A secured overdraft facility provides small businesses with flexible access to funds exceeding the balance in their business bank account, up to a predetermined limit. This facility is designed to address short-term financial requirements effectively."
    },
    {
        id : 4,
        img : require('../../images/SBL-Images/loanSliderImages/Balance-Transfer.png'),
        top : "Other Secured Business Loan Options",
        heading : "Balance Transfer",
        bottom : "A balance transfer for business loans refers to the process of moving existing loans from one lender to another in order to secure improved terms, such as lower interest rates or a longer repayment period. This transfer enables businesses to save costs and enhance their loan arrangements."
    },
    {
        id : 5,
        img : require('../../images/SBL-Images/loanSliderImages/Loan-Against-Property.png'),
        top : "Other Secured Business Loan Options",
        heading : "Loan Against Property",
        bottom : "A Loan Against Property is a secured financing choice that utilizes an immovable property as collateral to secure funds. This type of loan provides businesses with advantages such as lower interest rates, higher loan amounts, and longer repayment periods to fulfill their financial requirements."
    }

]

export const SODLoanSwiper = [
    {
        id : 1,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Term-Loan.png'),
        top : "Other Secured Business Loan Options",
        heading : "Secured Term Business Loan",
        bottom : "Secured Term Business Loans are a type of loan that requires borrowers to pledge collateral, such as property or assets, to the lender. By providing this security, businesses can access longer loan terms and potentially enjoy lower interest rates."
    },
    {
        id : 2,
        img : require('../../images/SBL-Images/loanSliderImages/Machinery-Equipment-Loan.png'),
        top : "Other Secured Business Loan Options",
        heading : "Equipment / Machinery Loan",
        bottom : "Equipment/Machinery Loans are specialized financing options designed to provide funds for the acquisition or leasing of equipment. These loans leverage the equipment itself as collateral, making it easier to qualify for the loan and secure favorable terms."
    },
    {
        id : 3,
        img : require('../../images/SBL-Images/loanSliderImages/Loan-Against-Property.png'),
        top : "Other Secured Business Loan Options",
        heading : "Loan Against Property",
        bottom : "A Loan Against Property is a secured loan that allows you to pledge your property as collateral in order to access funds. This type of loan typically offers lower interest rates and higher loan amounts compared to unsecured loans."
    },
    {
        id : 4,
        img : require('../../images/SBL-Images/loanSliderImages/Working-Capital.png'),
        top : "Other Secured Business Loan Options",
        heading : "Working Capital Loan",
        bottom : "Ensure the uninterrupted operation of your business and secure the necessary funds for your daily expenses and overhead with a Working Capital Loan, allowing your business operations to run smoothly without any cash flow disruptions."
    },
    {
        id : 5,
        img : require('../../images/SBL-Images/loanSliderImages/Balance-Transfer.png'),
        top : "Other Secured Business Loan Options",
        heading : "Balance Transfer",
        bottom : "A Balance Transfer for business loans refers to the process of transferring an existing loan from one lender to another in order to benefit from improved terms, reduced interest rates, or enhanced repayment options."
    }

]



// Unsecured Business Loans 
export const UTLLoanSwiper = [
    {
        id : 1,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Term-Loan.png'),
        top : 'Unsecured Business Loans',
        heading : 'Unsecured Term Loan',
        bottom : "An unsecured term loan is a type of business loan that doesn't require collateral. It provides a fixed amount of money that is repaid over a predetermined period, typically with regular installments. Unsecured term loans are suitable for businesses that don't have significant assets to pledge as collateral and need funding for various purposes such as expansion, equipment purchase, or working capital."
    },
    {
        id : 2,
        img : require('../../images/SBL-Images/loanSliderImages/Invoice-Discounting.png'),
        top : "Unsecured Business Loans",
        heading : "Invoice Discounting",
        bottom : "Invoice discounting is a financing solution where businesses can receive immediate cash by selling their accounts receivable (unpaid invoices) to a lender at a discount. This type of loan allows businesses to access funds tied up in invoices and maintain a steady cash flow, without waiting for customers to make payments. It is commonly used by businesses with outstanding invoices, offering a quick and flexible way to access working capital."
    },
    {
        id : 3,
        img : require('../../images/SBL-Images/loanSliderImages/Secured-Overdraft.png'),
        top : "Unsecured Business Loans",
        heading : "Line of Credit",
        bottom : "A line of credit is a flexible borrowing arrangement that provides businesses with access to a predetermined amount of funds. Similar to a credit card, businesses can withdraw funds from the line of credit as needed and only pay interest on the amount utilized. It offers a revolving credit facility, allowing businesses to manage cash flow fluctuations and meet short-term financing needs efficiently."
    },
    {
        id : 4,
        img : require('../../images/SBL-Images/loanSliderImages/Working-Capital.png'),
        top : "Unsecured Business Loans",
        heading : "Unsecured Cash Credit",
        bottom : "Unsecured cash credit is a type of business loan that provides a revolving line of credit without requiring collateral. It allows businesses to borrow funds up to a predetermined credit limit and repay the borrowed amount along with interest. This flexible financing option is suitable for businesses that need quick access to funds for various purposes, such as managing working capital, covering operational expenses, or seizing growth opportunities."
    }

]



// Balance Transfer 
export const BTLoanSwiper = [
    {
        id : 1,
        img : require('../../images/SBL-Images/loanSliderImages/Balance-Transfer.png'),
        top : "",
        heading : "Balance Transfer",
        bottom : "A Balance Transfer is a loan-swapping arrangement that allows you to transfer your existing loan from one financial institution to another, often with improved terms and interest rates. By taking advantage of this facility, borrowers can consolidate debt, reduce interest costs, and simplify repayments, resulting in potential savings and enhanced financial management."
    },
]


// CGTSME 
export const CGTSMELoanSwiper = [
    {
        id : 1,
        img : require('../../images/CGTSME-swiper.png'),
        top : "Other Secured Business Loan Options",
        heading : "Manufacturing Capabilities",
        bottom : "Elevate your manufacturing venture with our Machinery Loan. From easy EMIs and collateral-free options to enticing government subsidies and flexible moratoriums, we've got you covered. Unlock equipment purchases, explore startup benefits, and experience hassle-free disbursements with per-day interest calculations."
    },
]

