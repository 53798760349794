import React from 'react'
import './SME.css'
import Banner from '../../components/Common/Banner'
import { invoiceDiscounting } from '../../components/Common/BannerItems'
import HelpSection from '../../components/Common/HelpSection'
import Features from '../../components/Common/Features'
import InternalLoanSwiper from '../../components/Common/InternalLoanSwiper'
import { IDFeature } from '../../components/Common/FeatureItems'
import Collateral from '../../components/Common/Collateral'
import { UTLLoanSwiper } from '../../components/Common/LoanSwiperItems'
import DocValidation from '../../components/Common/DocValidation'
import OLS from '../../components/Common/OLS'
import { InternalFAQ } from '../../components/Common/InternalFAQ'
import { IDFAQ } from '../../components/Common/InternalFAQItems'
import { IDHelpSlider } from '../../components/Common/HelpSliderItems'
import WhatsappConnect from '../../components/Common/whatsappConnect'
import { IDDocValidation } from '../../components/Common/DocValidationItems'


export default function InvoiceDiscounting() {
    return (
        <>
            {/* Banner */}
            <div className="banner">
                <Banner prop={invoiceDiscounting} />
            </div>

            {/* help section  */}
            <div className="bg-internal pad-top">
                <div className="container d-flex flex-wrap align-items-center">
                    <HelpSection prop={IDHelpSlider} />
                </div>
            </div>

            {/* Distinctive Features */}
            <Features prop={IDFeature} />

            {/* Loan slider */}
            <div className='bg-light-green pb-5 z-indexx'>
                <div className="container pt-4 pb-5">
                    <InternalLoanSwiper prop={UTLLoanSwiper} />
                </div>
            </div>

            {/* who can apply  */}
            <Collateral noCollateral={'GO'} />

            {/* Required documents */}
            <div className="req-doc">
                <DocValidation prop={IDDocValidation} />
            </div>

            {/* Other loan services  */}
            <OLS />

            {/* Internal FAQ  */}
            <div className="faq">
                <div className="container text-center">
                    <p className="primary-color mb-0 pt-5">Useful Links</p>
                    <h2 className='my-4'>Frequently Asked Questions</h2>
                    <p>Everything you need to know about  loan  services and other finance option</p>
                </div>
                <div className="container">
                    <InternalFAQ prop={IDFAQ}/>
                </div>
            </div>


            {/* Whatsapp connect  */}
            <WhatsappConnect />
            
            
        </>
    )
}
