import React from 'react'
import './DocValidation.css'

export default function DocValidation({ prop }) {
    return (
        <>
            {
                prop ?
                <div className="row">
                    <div className="d-md-flex flex-wrap">
                        <div className="col-md-6 col-8 mx-auto mt-3 mb-5 val">
                            <img className='validateImg' src={require("../../images/SBL-Images/SBL-document.png")} alt="Verification" width='100%' height={480} />
                        </div>
                        <div className="col-md-6 col-12 validate-doc mb-2">
                            <div className="reqDoc-txt mt-5">
                                <div className="box-doc bg-white rounded-3 py-3 px-4">
                                    <h4><b>Required Documents</b></h4>
                                    <ul className='ps-3'>
                                        {
                                            prop.map((data,i)=>{
                                                return(
                                                    <li key={i}>{data.doc}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="d-md-flex flex-wrap">
                        <div className="col-md-6 col-8 mx-auto mt-3 mb-5 val">
                            <img className='validateImg' src={require("../../images/SBL-Images/SBL-document.png")} alt="Verification" width='100%' height={480} />
                        </div>
                        <div className="col-md-6 col-12 validate-doc mb-2">
                            <div className="reqDoc-txt mt-5">
                                <div className="box-doc bg-white rounded-3 py-3 px-4">
                                    <h4><b>Required Documents</b></h4>
                                    <ul className='ps-3'>
                                        <li>Promoter Aadhar Card</li>
                                        <li>Promoter PAN Card</li>
                                        <li>Residence & Office Ownership Proof</li>
                                        <li>Current Residential Address Proof</li>
                                        <li>Business Registration Proof</li>
                                        <li>Business Bank Account Statements</li>
                                        <li>Income Tax Returns (ITR) and Computation of Income</li>
                                        <li>Audited Financials</li>
                                        <li>GSTR - 3B</li>
                                        <li>Collateral Documents</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
