import React from 'react'
import './HelpInternalSlider.css'

// Swiper 
import { Navigation, Pagination, Autoplay, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'

 


export default function HelpInternalSlider({prop}) {
    return (
        <>
            <Swiper
            modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            // navigation
            autoplay={{delay : 3000}}
            pagination={{ clickable : true }}
            // scrollbar={{ draggable: true }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            className='internal-slider mt-3 mb-4'
            >

                {
                    prop.map((data,i)=>{
                        return(
                            <SwiperSlide key={i}>
                                <div className="">
                                    <p>{data.top}</p>
                                    <p style={{ textAlign : 'justify' }}>{data.bottom}</p>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>
        </>
    )
}
