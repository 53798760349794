// import { Link } from 'react-router-dom'
import './SubmenuDropdown.css'
import { securedBusinessLoans } from './NavItems'
import { useState } from 'react'

export default function Secured_Dropdown ({prop, perops}) {
    const [dropdown, setDropdown] = useState(false)

    return(
        <ul className={dropdown ? "vertical-dropdown clicked" : "vertical-dropdown"} onClick={()=>setDropdown(!dropdown)}>
            {
                securedBusinessLoans.map(data =>{
                    
                    return(
                        <li className={`${data.cName} mt-2 pb-0 text-decoration-none w-100`} key={data.id}>
                            <a href={data.path} className='text-decoration-none d-block w-100' onClick={()=>{setDropdown(false);prop(false);perops(true)}}>
                                {data.title} 
                            </a>
                        </li>
                    )
                })
            }
        </ul>
    )
}