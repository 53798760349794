// Secured Business Loans 
export const STLFAQ = [
    {
        title : 'What is a secured tenure loan?',
        para : 'A secured tenure loan is a type of loan that requires collateral, such as property or assets, to secure the loan amount. The collateral acts as a security for the lender in case the borrower defaults on the loan.'
    },
    {
        title : 'What types of collateral can be used for a secured tenure loan?',
        para : 'Collateral for secured tenure loans can include residential or commercial properties, vehicles, investments, or other valuable assets. The specific collateral requirements may vary depending on the lender and loan terms.'
    },
    {
        title : 'Can I repay a secured tenure loan early?',
        para : 'Yes, many lenders allow borrowers to repay a secured tenure loan before the agreed-upon tenure ends. However, it is essential to check with the lender regarding any prepayment penalties or fees that may apply.'
    },
    {
        title : 'How do I qualify for a secured tenure loan?',
        para : 'Qualification requirements for secured tenure loans typically include a good credit history, sufficient income to repay the loan, and the availability of acceptable collateral. The lender will assess these factors to determine your eligibility.'
    },
    {
        title : 'How long does it take to get approved for a secured tenure loan?',
        para : 'The approval time for a secured tenure loan can vary depending on the lender and the complexity of the loan application. It typically involves a comprehensive evaluation of the collateral, creditworthiness, and financial documents. The lender will provide you with an estimated timeline during the application process.'
    },
]
export const MELFAQ = [
    {
        title : 'What is a machinery/equipment loan?',
        para : 'A machinery/equipment loan is a type of loan specifically designed to finance the purchase or upgrade of machinery and equipment for businesses. It provides businesses with the necessary capital to invest in productivity-enhancing assets, thereby boosting operational efficiency and growth potential.'
    },
    {
        title : 'How can I use the funds from a machinery/equipment loan?',
        para : 'The funds from a machinery/equipment loan can be used to acquire new machinery or equipment, upgrade existing equipment, or finance the lease of equipment. These loans are typically utilized by businesses across various industries to enhance their production capabilities and stay competitive in the market.'
    },
    {
        title : 'What factors are considered when applying for a machinery/equipment loan?',
        para : "Lenders typically consider factors such as the borrower's credit history, business financials, the value and condition of the machinery/equipment being financed, and the borrower's ability to repay the loan. It's important to provide accurate information and supporting documents during the loan application process."
    },
    {
        title : 'What are the repayment terms for machinery/equipment loans?',
        para : "Repayment terms for machinery/equipment loans can vary depending on the lender and the specific loan agreement. It is common to have flexible repayment options, including monthly installments, quarterly payments, or customized repayment schedules based on the business's cash flow. It's important to review the loan terms and discuss repayment options with the lender."
    },
    {
        title : 'Can I get a machinery/equipment loan for used equipment?',
        para : 'Yes, many lenders offer machinery/equipment loans for both new and used equipment. However, the loan terms and conditions may vary based on the age, condition, and value of the used equipment. Lenders may require additional documentation, such as appraisal reports or equipment inspection reports, for used equipment loans.'
    },
]
export const LAPFAQ = [
    {
        title : "What is a loan against property?",
        para : "A loan against property is a type of secured loan where you pledge your owned property, such as a residential or commercial property, as collateral to borrow funds from a lender. The loan amount is typically a percentage of the property's market value."
    },
    {
        title : "How can I use the funds from a loan against property?",
        para : "The funds from a loan against property can be used for various purposes, including business expansion, debt consolidation, home renovation, education expenses, medical emergencies, or any other personal or business-related financial needs. The usage of funds is generally flexible, depending on the borrower's requirements."
    },
    {
        title : "What factors are considered when applying for a loan against property?",
        para : "Lenders typically consider factors such as the market value of the property, the borrower's creditworthiness, income stability, repayment capacity, and the legal and technical aspects of the property. The loan amount approved and the interest rate offered may vary based on these factors."
    },
    {
        title : "What is the repayment period for a loan against property?",
        para : "The repayment period for a loan against property can range from a few years to several years, depending on the lender and the borrower's preferences. It is common to have longer repayment tenures for these loans, which allows borrowers to repay the loan in affordable installments over an extended period of time."
    }
]
export const SCCFAQ = [
    {
        title : "What is a secured cash credit?",
        para : "Secured cash credit is a type of business financing that allows businesses to access a revolving line of credit by providing collateral, such as inventory, accounts receivable, or property. The collateral acts as security for the loan, and businesses can borrow funds up to a predetermined credit limit based on the value of the collateral."
    },
    {
        title : "How can I use the funds from a secured cash credit?",
        para : "The funds from a secured cash credit can be used for various business purposes, such as managing working capital, purchasing inventory, financing day-to-day operations, meeting short-term cash flow needs, or seizing business opportunities. It provides flexibility to access funds as and when required."
    },
    {
        title : "What are the advantages of secured cash credit?",
        para : "Secured cash credit offers several advantages, including lower interest rates compared to unsecured loans, higher credit limits based on collateral value, flexible repayment terms, and the ability to access funds on an ongoing basis as per business requirements. It provides businesses with financial stability and liquidity."
    },
    {
        title : "What collateral can be used for securing a cash credit?",
        para : "Collateral for securing a cash credit can vary based on the lender's requirements, but commonly accepted collateral includes accounts receivable, inventory, equipment, property, or other valuable business assets. The collateral provides security to the lender and reduces the risk associated with the loan, resulting in more favorable terms for the borrower."
    },
]
export const WCLFAQ = [
    {
        title : "What is a working capital loan?",
        para : "A working capital loan is a type of business financing designed to provide funding for day-to-day operations, manage cash flow fluctuations, and cover immediate business expenses. It helps businesses maintain sufficient liquidity to meet their short-term financial needs and ensure smooth operations."
    },
    {
        title : "How can I use the funds from a working capital loan?",
        para : "The funds from a working capital loan can be used for various purposes, such as purchasing inventory, paying suppliers, covering payroll expenses, managing overhead costs, investing in marketing initiatives, and handling unforeseen expenses. It provides businesses with the necessary capital to support their ongoing operations."
    },
    {
        title : "What are the benefits of a working capital loan?",
        para : "Working capital loans offer several benefits, including quick access to funds, flexible repayment terms, customized loan amounts based on business needs, improved cash flow management, and the ability to seize growth opportunities. It helps businesses navigate financial challenges and maintain stability during fluctuating market conditions."
    },
    {
        title : "How is the eligibility determined for a working capital loan?",
        para : "Eligibility for a working capital loan is typically based on various factors, including the business's creditworthiness, financial stability, cash flow projections, and collateral (if applicable). Lenders may also consider the industry, business history, and revenue generation capabilities. Meeting the lender's eligibility criteria and providing the necessary documentation will increase the chances of approval for a working capital loan."
    },
]
export const SODFAQ = [
    {
        title : "What is a secured overdraft?",
        para : "A secured overdraft is a type of business financing that allows you to access additional funds beyond your business bank account balance, up to a predetermined limit. It is secured by collateral, such as property or assets, which provides added security to the lender."
    },
    {
        title : "How does a secured overdraft work?",
        para : "With a secured overdraft, you have the flexibility to withdraw funds as needed, up to the approved limit, to manage short-term cash flow gaps, meet unexpected expenses, and have greater financial flexibility. The collateral acts as security for the lender, reducing the risk and enabling them to offer a higher overdraft limit and more favorable terms."
    },
    {
        title : "What are the benefits of a secured overdraft?",
        para : "Secured overdrafts offer several benefits, including access to immediate funds, lower interest rates compared to unsecured overdrafts, more substantial credit limits, and the ability to leverage your assets or property for better borrowing terms. It provides businesses with the flexibility to bridge temporary cash flow gaps and effectively manage their working capital needs."
    },
    {
        title : "How is the limit determined for a secured overdraft?",
        para : "The limit for a secured overdraft is typically determined based on the value of the collateral provided, such as property or assets. The lender assesses the value of the collateral and sets a limit based on a certain percentage of its appraised worth. Other factors, such as the business's financial health, creditworthiness, and repayment capacity, may also be considered during the evaluation process."
    },
]


// Unsecured Business Loans 
export const UTLFAQ = [
    {
        title : "What is an unsecured tenure loan?",
        para : "An unsecured tenure loan is a type of loan that does not require any collateral or security. It is granted based on your creditworthiness, income, and repayment capacity. The loan amount and interest rates are determined by the lender's assessment of your financial profile."
    },
    {
        title : "What is the maximum loan amount I can get with an unsecured tenure loan?",
        para : "The maximum loan amount for an unsecured tenure loan varies from lender to lender and depends on factors such as your credit history, income, and repayment capacity. Typically, the loan amount is lower compared to secured loans, as the absence of collateral increases the lender's risk."
    },
    {
        title : "What is the repayment tenure for unsecured tenure loans?",
        para : "The repayment tenure for unsecured tenure loans varies depending on the lender and the specific terms of the loan. It can range from a few months to several years. The tenure is usually determined based on factors such as the loan amount, interest rates, and your repayment capacity."
    },
    {
        title : "What is the interest rate for unsecured tenure loans?",
        para : "The interest rate for unsecured tenure loans can vary depending on the lender, prevailing market conditions, and your creditworthiness. Generally, unsecured loans tend to have higher interest rates compared to secured loans due to the increased risk for the lender. It is important to compare interest rates from different lenders and choose the one that offers the most competitive rate for your financial situation."
    },
]
export const IDFAQ = [
    {
        title : "What is invoice discounting?",
        para : "Invoice discounting is a financial solution where businesses sell their unpaid invoices to a lender at a discounted rate to access immediate cash flow."
    },
    {
        title : "How does invoice discounting work?",
        para : "Businesses submit their invoices to the lender, who advances a percentage of the invoice value (typically 70-90%). Once the customer pays the invoice, the remaining amount (minus fees) is released to the business."
    },
    {
        title : "What are the benefits of invoice discounting?",
        para : `- Improves cash flow by converting unpaid invoices into immediate funds. ${<br />}- Provides working capital for day-to-day operations and growth. ${<br />}- Allows businesses to focus on core operations instead of chasing payments.`
    },
    {
        title : "How is invoice discounting different from factoring?",
        para : `- Unlike factoring, invoice discounting allows businesses to maintain control over their customer relationships and debt collection process. ${<br />}- The business retains responsibility for managing credit control and collecting payments from customers.`
    },
]
export const LOCFAQ = [
    {
        title : "What is a line of credit?",
        para : "A line of credit is a flexible financing option that provides access to funds up to a predetermined credit limit. It allows businesses to borrow as needed, repay, and borrow again without the need for multiple loan applications."
    },
    {
        title : "How does a line of credit work?",
        para : "Once approved, businesses can withdraw funds from the line of credit whenever needed, up to the approved credit limit. Interest is charged only on the amount borrowed, and repayments replenish the available credit for future use."
    },
    {
        title : "What are the benefits of a line of credit?",
        para : `- Offers financial flexibility to manage cash flow gaps, unexpected expenses, and short-term funding needs. ${<br />}- Provides quick access to funds without the need for lengthy loan approval processes. ${<br />}- Allows businesses to pay interest only on the amount borrowed, reducing overall borrowing costs.`
    },
    {
        title : "How is a line of credit different from a term loan?",
        para : `- Unlike a term loan with a fixed repayment schedule, a line of credit provides ongoing access to funds that can be used and repaid multiple times. ${<br />}- With a line of credit, businesses have the flexibility to borrow and repay as needed, while a term loan provides a lump sum amount with structured repayments.`
    },
]
export const UCCFAQ = [
    {
        title : "What is the maximum amount I can borrow through unsecured cash credit?",
        para : "The maximum amount you can borrow through unsecured cash credit depends on various factors such as your business's financial health, creditworthiness, and the policies of the financial institution. It is typically determined after assessing your business's income, expenses, and repayment capacity."
    },
    {
        title : "How quickly can I access funds through unsecured cash credit?",
        para : "The time to access funds through unsecured cash credit can vary depending on the financial institution and their internal processes. In some cases, you may be able to receive the funds within a few business days after the approval of your cash credit facility."
    },
    {
        title : "Can I use unsecured cash credit for any business purpose?",
        para : "Yes, you can typically use unsecured cash credit for various business purposes, including working capital needs, inventory management, equipment purchase, marketing expenses, and other operational expenses. It provides flexibility in using the funds based on your business requirements."
    },
    {
        title : "Do I need to provide collateral or security for unsecured cash credit?",
        para : "No, unsecured cash credit does not require collateral or security. It is granted based on your business's creditworthiness and repayment capacity. This makes it a convenient financing option for businesses that may not have substantial assets to offer as collateral."
    },
]


// Balance Transfer 
export const BTFAQ = [
    {
        title : "What is a balance transfer?",
        para : "A balance transfer is a process of transferring the outstanding balance from one credit card to another, usually with the aim of taking advantage of lower interest rates or better terms offered by the new credit card."
    },
    {
        title : "How can I initiate a balance transfer?",
        para : "To initiate a balance transfer, you typically need to contact your new credit card provider and provide them with the necessary details of your existing credit card and the amount you wish to transfer. The new credit card provider will then handle the transfer process on your behalf."
    },
    {
        title : "Are there any fees associated with a balance transfer?",
        para : "Balance transfers may incur fees, such as balance transfer fees or processing fees. These fees vary depending on the credit card provider and the specific terms of the balance transfer offer. It's important to review and understand the fees associated with the balance transfer before proceeding."
    },
    {
        title : "How long does a balance transfer take?",
        para : "The time taken for a balance transfer to complete can vary. It typically takes a few business days to process the transfer, but it may take longer depending on the credit card providers involved. It's advisable to check with your new credit card provider for an estimated timeline for the balance transfer."
    },
]


// Other Services 
export const CCFAQ = [
    {
        title : "How can I apply for a credit card?",
        para : "You can apply for a credit card by visiting our website and filling out the online application form. Alternatively, you can contact us via email or phone to know the process."
    },
    {
        title : "What documents do I need to apply for a credit card?",
        para : "To apply for a credit card, you will typically need to provide proof of identity (such as a passport or driver's license), proof of address (such as a utility bill or bank statement), and proof of income (such as salary slips or tax returns)."
    },
    {
        title : "How long does it take to get approved for a credit card?",
        para : "The approval process for a credit card usually takes a few business days. Once your application is submitted, it undergoes a verification and assessment process. You will be notified of the approval status via email or phone."
    },
    {
        title : "What are the fees associated with a credit card?",
        para : "Credit cards may have annual fees, late payment fees, cash advance fees, and foreign transaction fees. The specific fees and charges vary depending on the credit card provider and the type of card you choose. It's important to carefully review the terms and conditions to understand the fees associated with your credit card."
    },
]
export const InsuranceFAQ = [
    {
        title : "What is insurance?",
        para : "Insurance is a contractual arrangement in which an individual or entity receives financial protection or reimbursement against potential losses, damages, or risks in exchange for regular premium payments."
    },
    {
        title : "Why is it important to renew an insurance policy?",
        para : "Renewing an insurance policy is important to maintain continuous coverage, comply with legal requirements, update your coverage, mitigate risks, enjoy policy benefits, and have peace of mind."
    },
    {
        title : "How many claims can be filed under an insurance policy?",
        para : "The number of claims that can be filed under an insurance policy varies based on the policy terms and conditions. Typically, there is no specific limit on the number of claims, but it's important to consider the coverage limits and potential impact on future premiums."
    },
    {
        title : "Is there any waiting period for insurance policies?",
        para : "Yes, insurance policies may have waiting periods, which are specified periods of time when certain coverages or conditions may not be immediately available or covered. It's important to review your policy documents to understand any waiting periods that may apply to your specific insurance coverage."
    },
]
export const InverstmentFAQ = [
    {
        title : "What do you mean by investing?",
        para : "Investment refers to the act of allocating money or resources with the expectation of generating profitable returns or future benefits."
    },
    {
        title : "How do I start investing?",
        para : "To start investing, set clear financial goals, educate yourself about investment options, determine your risk tolerance, create a diversified portfolio, and consider consulting with a financial advisor."
    },
    {
        title : "At what age do I start investing?",
        para : "Start investing as early as possible to take advantage of compounding and give your investments more time to grow, but it's never too late to begin investing and work towards your financial goals."
    },
    {
        title : "Where do I start investing?",
        para : "Start investing in options like stocks, mutual funds, bonds, real estate, ETFs, retirement accounts, robo-advisors, or online investment platforms based on your financial goals and risk tolerance."
    },
]
export const FFSFAQ = [
    {
        title : "What is a Startup?",
        para : "A startup is a newly established business venture driven by innovation and aimed at disrupting existing markets with its unique products, services, or business models, often characterized by rapid growth and scalability."
    },
    {
        title : "Startup funding:",
        para : "Startup funding refers to the process of raising financial resources from investors and other sources to support the growth and development of a startup business. It helps startups cover expenses, expand operations, and bring their innovative ideas to market."
    },
    {
        title : "Why startup needs funding?",
        para : "Startups need to raise funding to support their operations, develop innovative products or services, scale their business, acquire customers, attract top talent, gain a competitive edge, and ensure long-term sustainability. Funding is crucial for fueling growth and turning their ideas into successful businesses."
    },
    {
        title : "What are the popular sources of startup banking?",
        para : "Startup funding can be obtained from various sources, including bootstrapping (self-funding), angel investors, government loans, crowdfunding platforms, incubators, venture capital funds, and venture debt funds. These sources provide the necessary financial support for startups to launch their businesses, fuel their growth, and turn their innovative ideas into reality."
    },
]
export const CommonFAQ = [
    {
        title : "How can I apply for a credit card?",
        para : "You can apply for a credit card by visiting our website and filling out the online application form. Alternatively, you can contact us via email or phone to know the process."
    },
    {
        title : "What is insurance?",
        para : "Insurance is a contractual arrangement in which an individual or entity receives financial protection or reimbursement against potential losses, damages, or risks in exchange for regular premium payments."
    },
    {
        title : "What do you mean by investing?",
        para : "Investment refers to the act of allocating money or resources with the expectation of generating profitable returns or future benefits."
    },
    {
        title : "What is a Startup?",
        para : "A startup is a newly established business venture driven by innovation and aimed at disrupting existing markets with its unique products, services, or business models, often characterized by rapid growth and scalability."
    }
]


// CGTMSE 
export const CGTMSEFAQ = [
    {
        title : "What does CGTMSE stand for?",
        para : "CGTMSE stands for Credit Guarantee Fund Trust for Micro and Small Enterprises. It's a government initiative in India to provide credit guarantees to financial institutions that lend to micro and small enterprises."
    },
    {
        title : "How does CGTMSE benefit small businesses?",
        para : "CGTMSE helps small businesses secure loans without needing extensive collateral. It provides a credit guarantee to the lender, reducing the risk and enabling easier access to credit for small enterprises."
    },
    {
        title : "Who is eligible for CGTMSE coverage?",
        para : "Micro and small enterprises, including both manufacturing and service sectors, are eligible for CGTMSE coverage. The eligibility criteria may vary based on the nature of the enterprise."
    },
    {
        title : "How much coverage does CGTMSE provide?",
        para : "CGTMSE typically covers up to a certain percentage of the loan amount, which can vary based on the loan category and the borrower's profile. The coverage helps lenders recover part of the outstanding amount in case of default."
    },
    {
        title : "Is there a fee for availing CGTMSE coverage?",
        para : "Yes, there is a nominal fee that borrowers need to pay to avail CGTMSE coverage. This fee is usually a small percentage of the loan amount and is payable upfront or added to the loan amount."
    },
]


// Retail loan 
export const HLFAQ = [
    {
        title : "What are the eligibility criteria for a home loan?",
        para : "The eligibility criteria for a home loan typically include factors such as age, income, employment stability, credit score, and property valuation."
    },
    {
        title : "How much down payment is required for a home loan?",
        para : "The down payment required for a home loan varies, but it is usually a percentage of the property value, typically ranging from 10% to 20%. However, this can vary based on the lender's policies and the type of loan."
    },
    {
        title : "What is the maximum loan amount I can get for a home loan?",
        para : "The maximum loan amount for a home loan depends on various factors, including your income, creditworthiness, property value, and the lender's policies. Generally, lenders offer home loans that range from 70% to 90% of the property value."
    },
    {
        title : "What is the tenure for a home loan?",
        para : "The tenure for a home loan can vary from 5 years to 30 years, depending on the lender and the borrower's preference. However, longer tenures may result in higher interest costs over time."
    },
]
export const ELFAQ = [
    {
        title : "Who is eligible for an education loan?",
        para : "Eligibility for an education loan may vary among lenders, but generally, students who have secured admission to recognized educational institutions in India or abroad for higher studies can apply. The student's academic performance, co-applicant's income, and collateral may also be considered."
    },
    {
        title : "What expenses are covered by an education loan?",
        para : "Education loans typically cover tuition fees, examination fees, hostel fees, cost of books and study materials, and other related expenses. Some lenders may also include additional expenses like travel expenses and living expenses."
    },
    {
        title : "What is the repayment period for an education loan?",
        para : "The repayment period for an education loan usually starts after the completion of the course, and it can vary from 5 to 15 years, depending on the loan amount and the terms offered by the lender. Some lenders may also provide a grace period after course completion before the repayment begins."
    },
    {
        title : "Are there any tax benefits available on education loans?",
        para : "Yes, under Section 80E of the Income Tax Act, borrowers can avail tax benefits on the interest paid on education loans. However, it is important to consult a tax advisor or refer to the relevant tax laws for detailed information on eligibility and claiming deductions."
    },
]
