import React from 'react'
import './retailLoan.css'
import Banner from '../../components/Common/Banner'
import { educationLoan } from '../../components/Common/BannerItems'
import Estimates from '../../components/RetailComponents/Estimates'
import { educationLoanEstimate } from '../../components/RetailComponents/EstimatesItems'
import EducationBenifits from '../../components/RetailComponents/EducationBenifits'
import { InternalFAQ } from '../../components/Common/InternalFAQ'
import { ELFAQ } from '../../components/Common/InternalFAQItems'
import EducationForm from '../../components/ResourcesComponent/EducationForm'
import WhatsappConnect from '../../components/Common/whatsappConnect'
import OLS from '../../components/Common/OLS'


export default function EducationLoan() {
    return (
        <>
            {/* Banner */}
            <div className="banner educationLoan">
                <Banner prop={educationLoan} />
            </div>

            {/* Loan form  */}
            <div className="retail-form mb-5">
                <div className="container">
                    <div className="col-12 col-lg-10 mx-auto back-resource-emi mb-5">
                        <div className="back-menu d-flex">
                            <div id="education-loan" className={`education-loan loan-top-btn current`}>
                                <button >Education Loan</button>
                            </div>
                        </div>
                        <EducationForm />
                    </div>
                </div>
            </div>

            {/* Estimate EMI  */}
            <div className="bg-light-voilet">
                <Estimates prop={educationLoanEstimate} />
            </div>

            {/* Feature and benifits */}
            <EducationBenifits />

            {/* Other Loan Services  */}
            <OLS prop={'ImgChange'} />

            {/* FAQ  */}
            <div className="faq">
                <div className="container text-center">
                    <p className="primary-color mb-0 pt-5">Useful Links</p>
                    <h2 className='my-4'>Frequently Asked Questions</h2>
                    <p>Everything you need to know about  loan  services and other finance option</p>
                </div>
                <div className="container">
                    <InternalFAQ prop={ELFAQ}/>
                </div>
            </div>


            {/* Whatsapp connect  */}
            <WhatsappConnect />



        </>
    )
}
