import './Slideshow.css';
import React, { useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";

// Images 
import Banner1 from '../images/banner-1.jpg'
import Banner2 from '../images/banner-2.jpg'
import Banner3 from '../images/banner-3.jpg'
import Banner4 from '../images/banner-4.jpg'

export default function Slideshow(){
  
  const [imageNum, setImageNum] = useState(1);

  let width = window.innerWidth
  let height

  if (width > 1200) {
    height = 700
  }else if(width > 480 && width < 1200){
    height = 450
  }else{
    height = 300
  }

  const sliderImages = [ 
    {
      url: Banner1,
    },
    {
      url: Banner2,
    },
    {
      url: Banner3,
    },
    {
      url: Banner4,
    }
  ];



  let widthh = "100%";
  
  return (
    <div className='slideshow'>
      <SimpleImageSlider
        width={widthh}
        height={height}
        images={sliderImages}
        showBullets={height >= 450 ? true : false}
        showNavs={height >= 450 ? true : false}
        autoPlay={true}
        onStartSlide = {(index, length) => {
          setImageNum(index);
        }}
        autoPlayDelay = {5}
      />

      {
        imageNum === 1 && (
          <div className="banner-box">
            <h1>Get the Funds You Need, When You Need Them!</h1>
            <p>Ignite your financial possibilities with our empowering loans, designed to help you overcome obstacles and seize opportunities for success.</p>
            <a className='btn btn-primary px-5 py-3 mt-xl-4' href='/resources/check-eligibility'>Get Started</a>
          </div>
        )
      }

      {
        imageNum === 2 && (
          <div className="banner-box">
            <h1>Simplify business loan needs on one platform</h1>
            <p>Unify and simplify your business loan requirements through our comprehensive platform, providing a seamless experience tailored to your financing needs.</p>
            <a className='btn btn-primary px-5 py-3 mt-xl-4' href='/resources/check-eligibility'>Get Started</a>
          </div>
        )
      }

      {
        imageNum === 3 && (
          <div className="banner-box">
            <h1>Diverse Funding Solutions for Loans Above 50 L</h1>
            <p>Secure unsecured loans up to 5 cr with multiple lenders through our comprehensive business loan solutions, ensuring maximum funds at minimal interest rates.</p>
            <a className='btn btn-primary px-5 py-3 mt-xl-4' href='/resources/check-eligibility'>Get Started</a>
          </div>
        )
      }

      {
        imageNum === 4 && (
          <div className="banner-box">
            <h1>Unleash your aspirations, borrow with intelligence</h1>
            <p>Discover your eligibility, gain access to a network of 20+ lenders through a single application, and receive expert business loan advice at no cost.</p>
            <a className='btn btn-primary px-5 py-3 mt-xl-4' href='/resources/check-eligibility'>Get Started</a>
          </div>
        )
      }
      
    </div>
  );
}