import { Link } from 'react-router-dom'
import './MenuDropdown.css'
import { smeProducts } from './NavItems'
import { useState } from 'react'
import { BsChevronRight } from 'react-icons/bs'
import SecuredDropdown from './Secured_Dropdown'
import UnsecuredDropdown from './Unsecured_Dropdown'
// import ServicesDropdown from './Services_Dropdown'

export default function SME_Dropdown ({prop}) {
    const [dropdown, setDropdown] = useState(false)
    const [sblDropdown, setSblDropdown] = useState(false)
    const [ublDropdown, setUblDropdown] = useState(false)
    // const [servicesDropdown, setServicesDropdown] = useState(false)

    

    return(
        <>
            <ul className={dropdown ? "sme-dropdown clicked" : "sme-dropdown"} >
                {
                    smeProducts.map(data =>{
                        if(data.title === 'Secured Business Loans'){
                            return(
                                <div className='submenu-div' key={data.id}>
                                    <li className={`${data.cName} mt-2 pb-0 text-decoration-none w-100`} onClick={()=>setSblDropdown(!sblDropdown)} onMouseEnter={()=>setSblDropdown(true)} onMouseLeave={()=>setSblDropdown(false)} >
                                        <Link to={data.path} className='text-decoration-none d-block w-100' onClick={()=>{setSblDropdown(false);}}>
                                            {data.title === 'Balance Transfer' ? <></> : <BsChevronRight className='d-inline-block d-md-none' />  } 
                                            {data.title} {data.title === 'Balance Transfer' ? <></> : <BsChevronRight className='d-none d-md-inline-block' />  } 

                                        </Link>
                                        { sblDropdown && <SecuredDropdown prop={prop} perops={setDropdown} /> }
                                    </li>
                                </div>
                            )
                        }else if( data.title === 'Unsecured Business Loans'){
                            return(
                                <div className='submenu-div' key={data.id}>
                                    <li className={`${data.cName} mt-2 pb-0 text-decoration-none w-100`} onClick={()=>setUblDropdown(!ublDropdown)} onMouseEnter={()=>setUblDropdown(true)} onMouseLeave={()=>setUblDropdown(false)} >
                                        <Link to={data.path} className='text-decoration-none d-block w-100' onClick={()=>{setUblDropdown(false);}}>
                                            {data.title === 'Balance Transfer' ? <></> : <BsChevronRight className='d-inline-block d-md-none' />  } 
                                            {data.title} {data.title === 'Balance Transfer' ? <></> : <BsChevronRight className='d-none d-md-inline-block' />  } 
                                        </Link>
                                        { ublDropdown && <UnsecuredDropdown prop={prop} perops={setDropdown} /> }
                                    </li>
                                </div>
                            )
                        }
                        // else if( data.title === 'Other services'){
                        //     return(
                        //         <div className='submenu-div' key={data.id}>
                        //             <li className={`${data.cName} mt-2 pb-0 text-decoration-none w-100`} onClick={()=>setServicesDropdown(!servicesDropdown)} onMouseEnter={()=>setServicesDropdown(true)} onMouseLeave={()=>setServicesDropdown(false)} >
                        //                 <Link to={data.path} className='text-decoration-none d-block w-100' onClick={()=>{setServicesDropdown(false);}}>
                        //                     {data.title === 'Balance Transfer' ? <></> : <BsChevronRight className='d-inline-block d-md-none' />  } 
                        //                     {data.title} {data.title === 'Balance Transfer' ? <></> : <BsChevronRight className='d-none d-md-inline-block' />  } 
                        //                 </Link>
                        //                 { servicesDropdown && <ServicesDropdown prop={prop} perops={setDropdown} /> }
                        //             </li>
                        //         </div>
                        //     )
                        // }
                        
                        return(
                            <li className={`${data.cName} mt-2 pb-0 text-decoration-none w-100`} key={data.id}>
                                <a href={data.path} className='text-decoration-none d-block w-100' onClick={()=>{setDropdown(!dropdown);prop(false);}}>
                                    {data.title === 'Balance Transfer' || data.title === 'Other services' || data.title === 'Loans under CGTMSE scheme' ? <></> : <BsChevronRight className='d-inline-block d-md-none' />  } 
                                    {data.title} {data.title === 'Balance Transfer' || data.title === 'Other services' || data.title === 'Loans under CGTMSE scheme' ? <></> : <BsChevronRight className='d-none d-md-inline-block' />  }
                                </a>
                            </li>
                        )
                    })
                }
                
            </ul>
        </>
    )
}