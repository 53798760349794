import { useState } from 'react';
import './ResourcesComponent.css'
import { toast } from 'react-toastify';



export default function EducationForm() {

    function nFormatter(num) {
        const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e5, symbol: "L" },
          { value: 1e7, symbol: "Cr" },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        return item ? (num / item.value).toFixed(2).replace(rx, "$1") + item.symbol : "0";
    }

    const [formData, setFormData] = useState({})

    const handleChange = (event) => {
        const { name, value } = event.target
        // console.log('name',name, 'value', value);
        setFormData({ ...formData, [name] : value })
        console.log(formData);
    }

    const submitData = () => {
        const config = {
            SecureToken : "b23eeb83-56da-448d-aef0-5af8b919f17d",
            To : 'info@finexacapital.com',
            From : "preetamchinde@gmail.com",
            Subject : "New enquiry received for Education Loan",
            Body : `
                Full Name : ${formData.fullName} <br />
                Contact Number : ${formData.contactNo} <br />
                City : ${formData.city} <br />
                Occupation Type : ${formData.occupationType} <br />
                Required Loan Amount : ${nFormatter(formData.loanAmount)} <br />
                Net Salary : ${nFormatter(formData.netSalary)} <br />
                Montly Obligations : ${nFormatter(formData.montlyObligations)} <br />
                Tenure : ${formData.tenure} <br />
                Intrest Rate : ${formData.intrestRate}
            `
        }

        if(window.Email){
            window.Email.send(config)
            .then(
                document.getElementById("myForm3").reset(),
                toast.success('Education Loan application sent successfully', {
                    position: "top-right", // Toast position
                    autoClose: 3000, // Duration in milliseconds
                    hideProgressBar: false, // Whether to display a progress bar
                    closeOnClick: true, // Close toast on click
                    pauseOnHover: true, // Pause the autoClose timer on hover
                    draggable: true, // Enable dragging to dismiss the toast
                    progress: undefined // Custom progress bar component
                })
            );
        }

    }

    const options = [
        { option : 'Salaried'},
        { option : 'Self Employed Professional'},
        { option : 'Partner'},
        { option : 'Proprietorship'},
        { option : 'Partnership/LLP'},
        { option : 'Private Limited'},
    ]

    return (
        <>
            <div className="row d-none d-md-block">
                <div className="container d-flex flex-wrap ">
                    {/* Form  */}
                    <div className="col-12 p-5 loan-form">
                        <p className="gray-color mb-4 ps-2">Please fill in the information to check your Education loan eligibility.</p>
                        <form onSubmit={(e)=>{submitData(); e.preventDefault()}} id='myForm3'>
                            <div className="row">
                                <div className="container">
                                    <div className="w-100 d-flex justify-content-between">
                                        <div className='w-100 px-2'>
                                            <label className='fs-7'>Full Name</label><br />
                                            <input required type="text" className='w-100 py-2 px-3' placeholder='Full Name' name='fullName' onChange={handleChange} />
                                        </div>
                                        <div className='w-100 px-2'>
                                            <label className='fs-7'>Contact Number</label><br />
                                            <input required type="number" className='w-100 py-2 px-3' placeholder='9876543210' name='contactNo' size={10} onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="w-100 d-flex justify-content-between mt-3">
                                        <div className='w-100 px-2'>
                                            <label className='fs-7'>City</label><br />
                                            <input required type="text" className='w-100 py-2 px-3' placeholder='Pune' name='city' onChange={handleChange} />
                                        </div>
                                        <div className='w-100 px-2'>
                                            <label className='fs-7'>Current Occupation</label><br />
                                            <select required defaultValue='Select' name='occupationType' className='w-100 py-2 px-3' onChange={handleChange}>
                                                <option disabled value="Select">Select Occupation Type</option>
                                                {
                                                    options.map(data=>{
                                                        return(
                                                            <option value={data.option}>{data.option}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="w-100 d-flex justify-content-between mt-3">
                                        <div className='w-100 px-2'>
                                            <label className='fs-7'>Required Loan Amount</label><br />
                                            <input required type="number" className='w-100 py-2 px-3' placeholder='Loan Amount Required' name='loanAmount' onChange={handleChange} />
                                        </div>
                                        <div className='w-100 px-2'>
                                            <label className='fs-7'>Monthly Net Salary</label><br />
                                            <input required type="number" className='w-100 py-2 px-3' placeholder='In Rupees' name='netSalary' onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="w-100 d-flex justify-content-between mt-3">
                                        <div className='w-100 px-2'>
                                            <label className='fs-7'>Monthly Obligations</label><br />
                                            <input required type="number" className='w-100 py-2 px-3' placeholder='In In Rupees' name='montlyObligations' onChange={handleChange} />
                                        </div>
                                        <div className='w-100 px-2'>
                                            <label className='fs-7'>Tenure</label><br />
                                            <input required type="text" className='w-100 py-2 px-3' placeholder='In Years' name='tenure' onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="w-100 d-flex justify-content-between mt-3">
                                        <div className='w-100 px-2'>
                                            <label className='fs-7'>Loan Interest Rate</label><br />
                                            <input required type="number" className='w-100 py-2 px-3' placeholder='In Percent' name='intrestRate' onChange={handleChange} />
                                        </div>
                                        <div className='w-100 px-2'></div>
                                    </div>

                                    <input type="submit" className="btn btn-primary mt-5 ms-2 w-25 py-2" value='Apply Now' /> 

                                </div>
                            </div>
                        </form>
                        
                    </div>
                    
                </div>
                
                
            </div>
        
            <div className="row d-block d-md-none">
                <div className="container d-flex flex-wrap ">
                    {/* Form  */}
                    <div className="col-12 px-2 pt-5 loan-form">
                        <p className="gray-color mb-4 ps-2">Please fill in the information to check your Education loan eligibility.</p>
                        <form onSubmit={(e)=>{submitData(); e.preventDefault()}} id='myForm3'>
                            <div className="row">
                                <div className="container">
                                    
                                    <div className='w-100 px-2 mt-2'>
                                        <label>Full Name</label><br />
                                        <input required type="text" className='w-100 py-1 px-2' placeholder='Full Name' name='fullName' onChange={handleChange} />
                                    </div>

                                    <div className='w-100 px-2 mt-2'>
                                        <label>Contact Number</label><br />
                                        <input required type="number" className='w-100 py-1 px-2' placeholder='9876543210' name='contactNo' size={10} onChange={handleChange} />
                                    </div>
                                
                                    <div className='w-100 px-2 mt-2'>
                                        <label>City</label><br />
                                        <input required type="text" className='w-100 py-1 px-2' placeholder='Pune' name='city' onChange={handleChange} />
                                    </div>

                                    <div className='w-100 px-2 mt-2'>
                                        <label>Current Occupation</label><br />
                                        <select required defaultValue='Select' name='occupationType' className='w-100 py-1 px-2' onChange={handleChange}>
                                            <option disabled value="Select">Select Occupation Type</option>
                                            {
                                                options.map(data=>{
                                                    return(
                                                        <option value={data.option}>{data.option}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                
                                    <div className='w-100 px-2 mt-2'>
                                        <label>Required Loan Amount</label><br />
                                        <input required type="number" className='w-100 py-1 px-2' placeholder='Loan Amount Required' name='loanAmount' onChange={handleChange} />
                                    </div>

                                    <div className='w-100 px-2 mt-2'>
                                        <label>Monthly Net Salary</label><br />
                                        <input required type="number" className='w-100 py-1 px-2' placeholder='In Rupees' name='netSalary' onChange={handleChange} />
                                    </div>
                                    
                                    <div className='w-100 px-2 mt-2'>
                                        <label>Monthly Obligations</label><br />
                                        <input required type="number" className='w-100 py-1 px-2' placeholder='In In Rupees' name='montlyObligations' onChange={handleChange} />
                                    </div>

                                    <div className='w-100 px-2 mt-2'>
                                        <label>Tenure</label><br />
                                        <input required type="text" className='w-100 py-1 px-2' placeholder='In Years' name='tenure' onChange={handleChange} />
                                    </div>
                                    
                                    <div className='w-100 px-2 mt-2'>
                                        <label>Loan Interest Rate</label><br />
                                        <input required type="number" className='w-100 py-1 px-2' placeholder='In Percent' name='intrestRate' onChange={handleChange} />
                                    </div>

                                    <input type="submit" className="btn btn-primary mt-5 w-100 py-2" value='Apply Now' /> 

                                </div>
                            </div>
                        </form>
                        
                    </div>
                    
                </div>
                
                
            </div>
        </>
    )
}
