export const homeLoanEstimate = {
    img : require('../../images/RetailLoanImages/home-frame.png'),
    heading : "Estimate Your Home Loan EMI Today!",
    para : "Plan your budget effectively by estimating your monthly home loan EMI using our convenient calculator. Get a clear picture of your repayment amount and tenure before making one of the biggest financial decisions of your life.",
    buttonTxt : "Calculate Home Loan EMI",
    id : 1
}

export const educationLoanEstimate = {
    img : require('../../images/RetailLoanImages/education-frame.png'),
    heading : "Estimate Your Education Loan EMI Today!",
    para : "Estimate your education loan Equated Monthly Installment (EMI) today with our easy-to-use calculator. Plan your finances effectively and make informed decisions about your education loan, ensuring a smooth repayment journey and achieving your educational goals.",
    buttonTxt : "Calculate Education Loan EMI",
    id : 3
}

