import './GettingStarted.css'

// Images 
import Img1 from '../images/process-1.png' 
import Img2 from '../images/process-2.png' 
import Img3 from '../images/process-3.png' 
import Img4 from '../images/process-4.png' 
import Img5 from '../images/process-5.png' 
import Img6 from '../images/process-6.png' 

export default function GettingStarted() {
    return (
        <>
            <div className="col-md-2 col-6 text-center text-md-start pros-1 ps-lg-4 ps-md-2">
                <p className='d-block d-md-none insideP firstP'>01</p>
                <img src={Img1} alt="Img 1" width='50%' />
                <p className='ps-lg-2'>Fill Application</p>
            </div>
            <div className="col-md-2 col-6 pros-2 text-center pe-md-2">
                <p className='d-block d-md-none insideP'>02</p>
                <img src={Img2} alt="Img 2" width='56%' style={{marginLeft : '-30px'}} />
                <p className='ps-lg-2 pe-lg-5'>Check your Business loan eligibility</p>
            </div>
            <div className="col-md-2 col-6 pros-3 text-center">
                <p className='d-block d-md-none insideP'>03</p>
                <img src={Img3} alt="Img 3" width='50%' />
                <p className='ps-lg-4 pe-md-3'>Share your documents and apply</p>
            </div>
            <div className="col-md-2 col-6 pros-4 text-center">
                <p className='d-block d-md-none insideP'>04</p>
                <img src={Img4} alt="Img 4" width='50%' />
                <p className='ps-lg-4 pe-md-2'>Get matched with the right lenders</p>
            </div>
            <div className="col-md-2 col-6 pros-5 text-center">
                <p className='d-block d-md-none insideP'>05</p>
                <img src={Img5} alt="Img 5" width='50%' style={{marginRight : '-20px'}} />
                <p className='ps-lg-5 pe-lg-2'>We'll negotiate the best terms for you</p>
            </div>
            <div className="col-md-2 col-6 pros-6 text-center">
                <p className='d-block d-md-none insideP'>06</p>
                <img src={Img6} alt="Img 6" width='50%' style={{marginRight : '-70px'}} />
                <p className='ps-lg-5 pe-lg-2 mt-3'>Get your loan in 3-7 days!</p>
            </div>
        </>
    )
}