import './USP.css'

export default function USP() {
    return (
        <>
            <div className="col-lg-1"></div>
            <div className="col-lg-3 col-md-4 px-3 mb-4 text-center">
                <img src={require("../images/home.png")} alt="Customised Services" width='15%' className='mb-2' />
                <p className='fs-5 mb-2'>Customised Service</p>
                <p>We offer tailored financial solutions for your unique needs, ensuring a personalized and effective financial journey.</p>
            </div>
            <div className="col-lg-3 col-md-4 px-3 mb-4 ms-lg-5 text-center">
                <img src={require("../images/cart.png")} alt="Customised Services" width='15%' className='mb-2' />
                <p className='fs-5 mb-2'>Trust and transparency</p>
                <p>Our commitment to trust and transparency means you can rely on us for clear, trustworthy financial advice and transactions.</p>
            </div>
            <div className="col-lg-3 col-md-4 px-3 mb-4 ms-lg-5 text-center">
                <img src={require("../images/trophy.png")} alt="Customised Services" width='15%' className='mb-2' />
                <p className='fs-5 mb-2'>No hidden charges</p>
                <p>With us, you'll find no hidden fees or unexpected costs, providing a transparent and cost-effective financial experience.</p>
            </div>
        </>
    )
}