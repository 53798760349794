export const SBLCollateral = {
    WCA : [
        {
            wca : 'Business Registration : Required'
        },
        {
            wca : 'Business Vintage : Min 3 Years'
        },
        {
            wca : 'Age : Min 23 Yrs Old'
        },
        {
            wca : 'Good Civil Track Record'
        },
    ],
    CTU : [
        {
            ctu : 'Owned Commercial Property'
        },
        {
            ctu : 'Owned Residential Property'
        },
        {
            ctu : 'Industrial Property'
        },
        {
            ctu : 'Warehouse & Buildings'
        },
        {
            ctu : 'Shares, Stocks, & Mutual Fund Investments'
        },
    ]
}

export const MELCollateral = {
    WCA : [
        {
            wca : 'Business Registration : Required'
        },
        {
            wca : 'New Businesses can also apply'
        },
        {
            wca : 'Business Vintage : Fresh Businesses'
        },
        {
            wca : 'Age : Min 23 Yrs Old'
        },
        {
            wca : 'Annual Turnover : No Min Req.'
        },
    ],
    CTU : [
        {
            ctu : 'Owned Commercial Property'
        },
        {
            ctu : 'Owned Residential Property'
        },
        {
            ctu : 'Industrial Property'
        },
        {
            ctu : 'Warehouse & Buildings'
        },
        {
            ctu : 'Shares, Stocks, & Mutual Fund Investments'
        },
    ]
}

export const LAPCollateral = {
    WCA : [
        {
            wca : 'Any individual/ non individual'
        },
        {
            wca : 'Business / Solarist : Min 3 Years'
        },
        {
            wca : 'Age : Min 21 Yrs '
        },
    ],
    CTU : [
        {
            ctu : 'Owned Commercial Property'
        },
        {
            ctu : 'Owned Residential Property'
        },
        {
            ctu : 'Industrial Property'
        },
        {
            ctu : 'Warehouse & Buildings'
        },
        {
            ctu : 'Open Land & Machinery'
        },
    ]
}

export const CGTMSECollateral = {
    WCA : [
        {
            wca : 'Age : Min 21 Yrs and above'
        },
        {
            wca : 'Good Credit Score & Repayment History'
        },
    ],
    CTU : [
        {
            ctu : 'Owned Commercial Property'
        },
        {
            ctu : 'Owned Residential Property'
        },
        {
            ctu : 'Industrial Property'
        },
        {
            ctu : 'Warehouse & Buildings'
        },
        {
            ctu : 'Shares, Stocks, & Mutual Fund Investments'
        },
    ]
}
