// Swiper 
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

// Custom css 
import './InternalLoanSwiper.css'


export default function InternalLoanSwiper ({prop}) {
    // console.log(prop);
    return (
        <>
            <div className="d-none d-lg-block pb-2">
                <Swiper
                modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{delay : 3000}}
                // navigation
                pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                className='internal-loan-swiper mt-3 mb-4'
                >
                    {
                        prop.map((data,i)=>{
                            return(
                                <SwiperSlide key={data.id}>
                                    <div className="container d-flex flex-wrap text-white">
                                        <div className="col-md-6 mt-4 mt-md-0 loan--img">
                                            <img src={data.img} alt="" width='100%' />
                                        </div>
                                        <div className="col-md-6 p-md-5 mt-xl-5 my-3 business-text p-2">
                                            <p>{data.top}</p>
                                            <h1 className='mb-5'>{data.heading}</h1>
                                            <p style={{ textAlign : 'justify' }}>{data.bottom}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                    
                    

                </Swiper>
            </div>

            <div className="d-none d-md-block d-lg-none pb-2">
                <Swiper
                modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{delay : 3000}}
                // navigation
                pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                className='internal-loan-swiper mt-3 mb-4'
                >
                    {
                        prop.map((data,i)=>{
                            return(
                                <SwiperSlide key={data.id}>
                                    <div className="container d-flex flex-wrap align-items-center text-white">
                                        <div className="col-md-6 mt-4 mt-md-0 loan--img">
                                            <img src={data.img} alt="" width='100%' />
                                        </div>
                                        <div className="col-md-6 p-md-5 business-text p-2">
                                            <p>{data.top}</p>
                                            <h1 className='mb-3'>{data.heading}</h1>
                                            <p>{data.bottom}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                    
                    

                </Swiper>
            </div>

            <div className="d-block d-md-none pb-2">
                <Swiper
                modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                spaceBetween={20}
                slidesPerView={1}
                autoplay={{delay : 3000}}
                // navigation
                pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                className='internal-loan-swiper mt-3 mb-5'
                >
                    {
                        prop.map((data,i)=>{
                            return(
                                <SwiperSlide key={data.id}>
                                    <div className=" text-white">
                                        <div className="col-md-6 mt-4 mt-md-0 loan--img">
                                            <img src={data.img} alt="" width='100%' />
                                        </div>
                                        <div className="col-md-6 p-md-5 mt-5 business-text p-2">
                                            <p>{data.top}</p>
                                            <h1 className='mb-md-5 mb-3'>{data.heading}</h1>
                                            <p>{data.bottom}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                    
                    

                </Swiper>
            </div>


        </>
    )
}