// Swiper 
import { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
// Custom css 
import './PartnersSwiperInternal.css'

// Images 
import Axis from '../../images/Axis.png'
import BOB from '../../images/BOB.png'
import CBI from '../../images/CBI.png'
// import CO from '../../images/CO.png'
import HDFC from '../../images/HDFC.png'
import ICICI from '../../images/ICICI.png'
import IDFC from '../../images/IDFC.png'
import Kotak from '../../images/Kotak.png'
import NKGSB from '../../images/NKGSB.png'
import SBI from '../../images/SBI.png'
import TJSB from '../../images/TJSB.png'
// import Kalupur from '../../images/Kalupur.png'
import Sarasvat from '../../images/Sarasvat.png'


export default function PartnersSwiperInternal () {
    return (
        <>
            <div className="d-none d-lg-block ">
                <Swiper
                modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y]}
                spaceBetween={30}
                slidesPerView={5}
                navigation
                autoplay={{delay : 2000}}
                // pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                className='partner-swiper-internal mt-3 mb-4'
                >

                    <SwiperSlide>
                        <div className="border">
                           <img src={Axis} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={BOB} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={CBI} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    {/* <SwiperSlide>
                        <div className="border">
                           <img src={CO} alt="" width='100%' />
                        </div>
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <div className="border">
                           <img src={HDFC} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={ICICI} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={IDFC} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={Kotak} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={NKGSB} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={SBI} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={TJSB} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    {/* <SwiperSlide>
                        <div className="border">
                           <img src={Kalupur} alt="" width='100%' />
                        </div>
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <div className="border">
                           <img src={Sarasvat} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>

            <div className="d-none d-md-block d-lg-none mb-3 ">
                <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={30}
                slidesPerView={3}
                autoplay={{delay : 2000}}
                navigation
                // pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                className='partner-swiper-internal mt-3 mb-4'
                >

                    <SwiperSlide>
                        <div className="border">
                           <img src={Axis} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={BOB} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={CBI} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    {/* <SwiperSlide>
                        <div className="border">
                           <img src={CO} alt="" width='100%' />
                        </div>
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <div className="border">
                           <img src={HDFC} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={ICICI} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={IDFC} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={Kotak} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={NKGSB} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={SBI} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border">
                           <img src={TJSB} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    {/* <SwiperSlide>
                        <div className="border">
                           <img src={Kalupur} alt="" width='100%' />
                        </div>
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <div className="border">
                           <img src={Sarasvat} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>

            <div className="d-sm-block dualFold d-xs-block d-md-none mb-2 pt-1 ">
                <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={10}
                slidesPerView={3}
                autoplay={{delay : 2000}}
                navigation
                // pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                className='partner-swiper-internal '
                >

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={Axis} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={BOB} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={CBI} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    {/* <SwiperSlide>
                        <div className="border foldSize">
                           <img src={CO} alt="" width='100%' />
                        </div>
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={HDFC} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={ICICI} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={IDFC} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={Kotak} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={NKGSB} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={SBI} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={TJSB} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                    {/* <SwiperSlide>
                        <div className="border foldSize">
                           <img src={Kalupur} alt="" width='100%' />
                        </div>
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <div className="border foldSize">
                           <img src={Sarasvat} alt="" width='100%' />
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>
        </>
    )
}