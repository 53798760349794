// import { Link } from 'react-router-dom'
import './MenuDropdown.css'
import { retailLoan } from './NavItems'
import { useState } from 'react'

export default function Retail_Dropdown () {
    const [dropdown, setDropdown] = useState(false)

    return(
        <ul className={dropdown ? "sme-dropdown retail-dropdown clicked" : "sme-dropdown retail-dropdown"} onClick={()=>setDropdown(!dropdown)}>
            {
                retailLoan.map(data =>{
                    
                    return(
                        <li className={`${data.cName} mt-2 pb-0 text-decoration-none`} key={data.id}>
                            <a href={data.path} className='text-decoration-none' onClick={()=>{setDropdown(false);}}>
                                {data.title} 
                            </a>
                        </li>
                    )
                })
            }
        </ul>
    )
}