import React from 'react'
import './resources.css'

import { BsTelephone } from 'react-icons/bs'
import { GoMail } from 'react-icons/go'
import { CiLocationOn } from 'react-icons/ci'

import ContactForm from '../../components/ResourcesComponent/ContactForm'
import WhatsappConnect from '../../components/Common/whatsappConnect'



export default function enquiry() {
    return (
        <>
            {/* Heading  */}
            <div className="container my-md-5 mb-5 pt-4">
                <p className="primary-color mb-2">Contact Us</p>
                <h1>Let's Make Financial Magic Happen!</h1>
            </div>

            <div className="container d-flex flex-wrap mb-5">
                {/* Information  */}
                <div className="col-md-6 enquiry-contact">
                    <div className="p-lg-5 pt-md-5 p-3">
                        <div className="contact-img ">
                            <img src={require("../../images/contact-info.png")} alt="Contact Info" width='100%' />
                        </div>
                        <h3 className="my-3">Enquire Your Doubts</h3>
                        <p className='gray-color'>
                            You can reach us anytime via email or contact number. <br />
                            We'll get back to you as soon as we can.
                        </p>
                        <p className="gray-color my-4 fs-5">Contact Details</p>

                        <div className='mb-3 d-flex'>
                            <div className='tact'>
                                <BsTelephone fill='gray-color' />
                            </div>
                            <div>
                                <a href="tel:9081570708" className='ms-2 text-decoration-none text-dark'>+91 9081570708</a>
                            </div>
                        </div>
                        <div className='mb-3 d-flex'>
                            <div className='tact'>
                                <GoMail fill='gray-color' />
                            </div>
                            <div>
                                <a href="mailto:info@finexacapital.com" className='ms-2 text-decoration-none text-dark'>info@finexacapital.com</a>
                            </div>
                        </div>
                        <div className='mb-3 d-flex'>
                            <div className='contact-svg'>
                                <CiLocationOn fill='gray-color' />
                            </div>
                            <div>
                                <span className='ms-2'>214, Soverign Shoppers, Nr Sindhu Sewa Samiti School, Anand Mahel Road Adajan, Surat - 395009</span>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Contact Form  */}
                <div className="col-md-6 px-lg-5 px-md-0 ps-md-4 px-2">
                    <h4 className='pt-5 '>We're here to help: Fill out the form and Let's Talk Finance</h4>
                    <p className='gray-color my-4'>
                        You can reach us anytime via <a href="mailto:info@finexacapital.com" className='text-decoration-none txt-color'>info@finexacapital.com</a>
                    </p>
                    <ContactForm />
                </div>

            </div>


            {/* Whatsapp connect  */}
            <WhatsappConnect />
        </>
    )
}
