// Secured Business Loan 
export const securedTermLoans ={
    img : require('../../images/SBL-Images/STL-banner.png'),
    heading : 'Drive growth with our secure term business loan.',
    text : 'Unlock your business growth potential with our customer-focused secured term loan, tailored to fuel your success and propel you towards your goals.',
    partner : "20+ SME Lending Partners"
}

export const machineryEquipmentLoan ={
    img : require('../../images/SBL-Images/ML-banner.png'),
    heading : 'Revolutionize Your Business with Machinery Loans',
    text : 'Experience the lowest ROIs on machinery loans and gain access to 30+ lenders through a single application. Unlock collateral-free financing options tailored for machinery investments.',
    partner : "20+ SME Lending Partners"
}

export const loanAgainstProperty ={
    img : require('../../images/SBL-Images/LAP-banner.png'),
    heading : 'Unlock the Value: Effortless Loans Against Property',
    text : 'Access the lowest property loan rates available. With just one application, connect with our network of 30+ ready lenders. Experience effortless property-backed loans for your financial needs.',
    partner : "We'll negotiate the best loans for you"
}

export const securedCashCredit ={
    img : require('../../images/SBL-Images/SCC-banner.png'),
    heading : 'Unlock Capital: Seamless Cash Credit Loans',
    text : 'Experience convenient cash credit loans tailored for SMEs. Connect with our network of 30+ lenders through a single application. Benefit from a fast process and quick loan disbursal for your business needs.',
    partner : "We'll negotiate the best loans for you"
}

export const workingCapitalLoans ={
    img : require('../../images/SBL-Images/WCL-banner.png'),
    heading : 'Supercharge Cash Flow: Working Capital Loans',
    text : 'Unlock working capital loans up to 15Cr for your business. Experience swift fund disbursal in just 3-7 days, coupled with a simple and seamless loan processing journey.',
    partner : "We'll negotiate the best loans for you"
}

export const secureOverdraft ={
    img : require('../../images/SBL-Images/SOD-banner.png'),
    heading : 'Enhance Liquidity: Effortless Overdraft Facility',
    text : 'Access competitive rate overdraft loans, offering high limits and connecting you with 30+ lenders. Join over 300,000 MSMEs across India who have chosen our trusted overdraft facility.',
    partner : "We'll negotiate the best loans for you"
}



// Unsecured Business Loan 
export const unsecuredTermLoans ={
    img : require('../../images/SBL-Images/UBL-banner.png'),
    heading : 'Ignite Growth: Unsecured Term Business Loans',
    text : "Accelerate your business with quick loans disbursed in 3-7 days, available for short or long-term needs. Benefit from the market's lowest ROIs and competitive terms. Connect with 30+ lenders through a single application for seamless access to financing options.",
    partner : "20+ SME Lending Partners"
}

export const invoiceDiscounting ={
    img : require('../../images/SBL-Images/UBL-banner.png'),
    heading : 'Ignite Growth: Streamlined Invoice Discounting',
    text : "Unlock affordable purchase invoice discounting terms that fuel your business's growth. Experience our seamless and efficient funding process, trusted by over 300,000 MSMEs across India.",
    partner : "20+ SME Lending Partners"
}

export const lineOfCredit ={
    img : require('../../images/SBL-Images/UBL-banner.png'),
    heading : 'Enhance Flexibility: Effortless Line of Credit',
    text : 'Enjoy the best ROIs for your business credit line, ensuring optimal financial flexibility. Receive your credit line loan in just 3-7 days. Join over 300,000 MSMEs across India who have chosen our trusted line of credit solution.',
    partner : "20+ Business Line of Credit Lending Partners"
}

export const unsecuredCashCredit ={
    img : require('../../images/SBL-Images/UBL-banner.png'),
    heading : 'Unlock Capital: Seamless Cash Credit Loans',
    text : 'Experience the convenience of cash credit loans designed for SMEs. Gain access to our network of 30+ lenders with just one application. Enjoy a fast and streamlined process, ensuring quick loan disbursal for your business needs.',
    partner : "20+ Business Line of Credit Lending Partners"
}



// Balance Transfer 
export const balanceTransfer ={
    img : require('../../images/SBL-Images/BT-banner.png'),
    heading : 'Streamline Debt: Effortless Loan Balance Transfer',
    text : 'Experience seamless business loan balance transfers that simplify your financial obligations. Enjoy the lowest rates on balance transfers, making it easier to manage your debt. Join over 300,000 SMEs across India who have chosen our trusted balance transfer solution.',
    partner : "20+ SME Lending Partners"
}



// Other Services 
export const creditCard ={
    img : require('../../images/SBL-Images/CC-banner.png'),
    heading : "Other Services you might want to know",
    text : 'Whether it is a Credit Card, Insurance , Investment or be it a funding for a Startup, you get all the insights at one place.',
    partner : "20+ SME Lending Partners"
}

export const insurance ={
    img : require('../../images/SBL-Images/Insurance-banner.png'),
    heading : 'Protect Your Finances',
    text : 'Explore a range of insurance options that can safeguard your assets and secure your financial future with us.'
}

export const investment ={
    img : require('../../images/SBL-Images/Investment-banner.png'),
    heading : 'Investment Options',
    text : 'Learn about Investment plans to secure your future and build your assets so that you can always sit back and relax with early retirement.'
}

export const fundingForStartUp ={
    img : require('../../images/SBL-Images/FFS-banner.png'),
    heading : 'Funding for Startup',
    text : 'Fueling Startup Success: Access the Capital You Need for Growth and Innovation.'
}


// CGTMSE 
export const loansUnderCGTMSE ={
    img : require('../../images/CHTSME-banner.png'),
    heading : 'Elevating Enterprises: Unleash Potential with CGTMSE',
    text : 'Discover how our CGTMSE program propels small and medium enterprises towards success, offering tailored financial solutions and expert guidance to foster growth and innovation in your business journey.',
    partner : "20+ SME Lending Partners"
}


// Retail Loans 
export const homeLoan ={
    img : require('../../images/RetailLoanImages/HomeLoan-banner.png'),
    heading : 'Home Loan',
    text : 'Experience seamless business loan balance transfers that simplify your financial obligations. Enjoy the lowest rates on balance transfers, making it easier to manage your debt. Join over 300,000 SMEs across India who have chosen our trusted balance transfer solution.',
    partner : '20+ SME Lending Partners'
}
export const educationLoan ={
    img : require('../../images/RetailLoanImages/EducationLoan-banner.png'),
    heading : 'Education Loan',
    text : 'Education loans provide financial assistance to students to pursue their higher education dreams. With flexible repayment options, competitive interest rates, and generous loan amounts, education loans enable students to access quality education without worrying about the financial burden. They serve as a crucial support system, helping students fulfill their educational aspirations and build a successful future.',
    partner : '20+ SME Lending Partners'
}


// Resources 
export const EMIBanner ={
    img : require('../../images/Resources/calculator-banner.png'),
    heading : 'EMI Calculator',
    text : 'Ignite your financial possibilities with our empowering loans, designed to help you overcome obstacles and seize opportunities for success.',
}
export const EligibilityBanner ={
    img : require('../../images/Resources/eligibility-banner.png'),
    heading : 'Get To Know Your Eligibility',
    text : 'Ignite your financial possibilities with our empowering loans, designed to help you overcome obstacles and seize opportunities for success.',
}


// About Us 
export const aboutUsBanner ={
    img : require('../../images/about-banner.png'),
    heading : 'Get to Know Us: Your Journey to Financial Prosperity',
    text : 'Discover Our Story and get to know us better by unveiling all about us in one place -The guide to our journey, values, and mission.',
}

