import React, { useState } from 'react'
import './Header.css'

import { Link } from 'react-router-dom';
import { navItems } from './NavItems'
import { BsChevronDown } from 'react-icons/bs'
import SMEDropdown from './SME_Dropdown';
import RetailDropdown from './Retail_Dropdown';
import ResorcesDropdown from './Resorces_Dropdown';

import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import Logo from '../../images/logo.png'
import './BootstrapHeader.css'



export default function BootstrapHeader() {

    const width = window.innerWidth

    const [
        // eslint-disable-next-line
        dropdown, setDropdown] = useState(false)


    const [SMEdropdown, setSMEDropdown] = useState(false)
    const [retailDropdown, setRetailDropdown] = useState(false)
    const [resourcesDropdown, setResourcesDropdown] = useState(false)
    const [
        // eslint-disable-next-line
        aboutDropdown, setAboutDropdown] = useState(false)

    const path = window.location.pathname

    return(
        <div className='header botstrap'>
            <div className=''>
                <Navbar expand='md'>
                    <Container >
                        <Nav.Link href="/" className='brand-logo py-2 navbar-brand'>
                            <img src={Logo} alt="Logo" height='auto' width='100%' />
                        </Nav.Link>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} id='CloseFunction' />
                        <Navbar.Offcanvas id={`offcanvasNavbar-expand-lg`} aria-labelledby={`offcanvasNavbarLabel-expand-lg`} placement="end" >
                            <Offcanvas.Header closeButton></Offcanvas.Header>
                            <Offcanvas.Body className='xyz'>
                                <Nav className="justify-content-end flex-grow-1 pe-4">
                                <li className={`nav-item pe-lg-2 pe-md-1 me-lg-4 mt-2 text-decoration-none`} key='0'>
                                    <a href='/' className={`${path === '/' && ('active')} text-decoration-none text-black pb-2 pb-md-1`}>Home</a>
                                </li>
                                {
                                    navItems.map(data => {
                                        if(data.title === 'SME Products'){
                                            return(
                                                <>
                                                {
                                                    width <= 767 ?
                                                    <li className={`${data.cName} pe-2 me-lg-4 me-1 mt-2 text-decoration-none`} key={data.id} onMouseEnter={()=>setSMEDropdown(true)} onMouseLeave={()=>setSMEDropdown(false)} >
                                                        <Link to={data.path} className='text-decoration-none text-black pb-2' onClick={()=>{setSMEDropdown(!SMEdropdown)}}>
                                                            {data.title} <BsChevronDown />
                                                        </Link>
                                                        {SMEdropdown && 
                                                        <SMEDropdown prop={setDropdown} />
                                                        }
                                                    </li>
                                                    :
                                                    <li className={`${data.cName} pe-2 me-lg-4 me-1 mt-2 text-decoration-none`} key={data.id} onMouseEnter={()=>setSMEDropdown(true)} onMouseLeave={()=>setSMEDropdown(false)} >
                                                        <Link to={data.path} className='text-decoration-none text-black' >
                                                            {data.title} <BsChevronDown />
                                                        </Link>
                                                        {SMEdropdown && 
                                                        <SMEDropdown prop={setDropdown} />
                                                        }
                                                    </li>
                                                }
                                                </>
                                            )
                                        }else if(data.title === 'Retail Loan'){
                                            return(
                                                <>
                                                {
                                                    width <= 767 ?
                                                    <li className={`${data.cName} pe-2 me-lg-4 me-1 mt-2 text-decoration-none`} key={data.id} onMouseEnter={()=>setRetailDropdown(true)} onMouseLeave={()=>setRetailDropdown(false)} >
                                                        <Link to={data.path} className='text-decoration-none text-black pb-2' onClick={()=>{setRetailDropdown(!retailDropdown)}}>
                                                            {data.title} <BsChevronDown />
                                                        </Link>
                                                        {retailDropdown && <RetailDropdown prop={setDropdown} />}
                                                    </li>
                                                    :
                                                    <li className={`${data.cName} pe-2 me-lg-4 me-1 mt-2 text-decoration-none`} key={data.id} onMouseEnter={()=>setRetailDropdown(true)} onMouseLeave={()=>setRetailDropdown(false)} >
                                                        <Link to={data.path} className='text-decoration-none text-black' >
                                                            {data.title} <BsChevronDown />
                                                        </Link>
                                                        {retailDropdown && <RetailDropdown prop={setDropdown} />}
                                                    </li>
                                                }
                                                </>
                                            )
                                        }else if(data.title === 'Resources'){
                                            return(
                                                <>
                                                {
                                                    width <= 767 ?
                                                    <li className={`${data.cName} pe-2 me-lg-4 me-1 mt-2 text-decoration-none`} key={data.id} onMouseEnter={()=>setResourcesDropdown(true)} onMouseLeave={()=>setResourcesDropdown(false)} >
                                                        <Link to={data.path} className='text-decoration-none text-black pb-2' onClick={()=>{setResourcesDropdown(!resourcesDropdown)}}>
                                                            {data.title} <BsChevronDown />
                                                        </Link>
                                                        {resourcesDropdown && <ResorcesDropdown prop={setDropdown} />}
                                                    </li>
                                                    :
                                                    <li className={`${data.cName} pe-2 me-lg-4 me-1 mt-2 text-decoration-none`} key={data.id} onMouseEnter={()=>setResourcesDropdown(true)} onMouseLeave={()=>setResourcesDropdown(false)} >
                                                        <Link to={data.path} className='text-decoration-none text-black' >
                                                            {data.title} <BsChevronDown />
                                                        </Link>
                                                        {resourcesDropdown && <ResorcesDropdown prop={setDropdown} />}
                                                    </li>
                                                }
                                                </>
                                            )
                                        }
                                        return(
                                            <li className={`${data.cName} pe-2 me-lg-4 me-1 mt-2 text-decoration-none`} key={data.id}>
                                                <a href={data.path} className={`text-decoration-none text-black pb-md-0 pb-2 ${path === '/about-us' && ('active')}`} >
                                                    {data.title} 
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                                <li className={`nav-item pe-lg-2 me-lg-4 mt-2 text-decoration-none`} key='5'>
                                    <a href='/contact-us' className={`${path === '/contact-us' && ('active')} text-decoration-none text-black`}>Contact Us</a>
                                </li>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </div>
        </div>
    )
}
