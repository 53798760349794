import React from 'react'
import './Estimates.css'

export default function Estimates({prop}) {
    return (
        <>
            <div className="container d-md-flex justify-content-center align-items-center estimates mb-5">
                <div className="col-md-6">
                    <img src={prop.img} className='estimate-1' alt="Loan" width='100%' />
                </div>
                <div className="col-md-6 pt-md-5  estimate-txt">
                    <h2 className=''>{prop.heading}</h2>
                    <p className='py-md-2'>{prop.para}</p>
                    <a href={`/resources/emi-calculator?id=${prop.id}`} className='btn btn-primary px-sm-5 px-3 py-2'>{prop.buttonTxt}</a>
                </div>
            </div>
        </>
    )
}
