import React from 'react'
import './FAQ.css'
import { BsPlusCircle } from 'react-icons/bs'
import { CiCircleMinus } from 'react-icons/ci'


// Accordion 
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


export const FAQ = () => {
  return (
    <div className="col-lg-9 mx-auto pb-md-5 pt-3 pb-5 pt-md-4">
        <Accordion allowZeroExpanded='true'>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton >
                        What services do you offer?
                        <div className="plus-sign">
                            <BsPlusCircle />
                        </div>
                        <div className="minus-sign">
                            <CiCircleMinus />
                        </div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        We offer a range of financial services including loans, credit cards, insurance, and investment options to meet your diverse financial needs.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton >
                        How can I apply for a loan or credit card?
                        <div className="plus-sign">
                            <BsPlusCircle />
                        </div>
                        <div className="minus-sign">
                            <CiCircleMinus />
                        </div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Applying for a loan or credit card is easy! Simply visit our website, fill out the online application form, provide the required documents, and submit your application. Our team will review your application and guide you through the process.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton >
                        How secure is my personal and financial information?
                        <div className="plus-sign">
                            <BsPlusCircle />
                        </div>
                        <div className="minus-sign">
                            <CiCircleMinus />
                        </div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        We prioritize the security of your personal and financial information. We use advanced encryption technology and follow strict security protocols to ensure the confidentiality and integrity of your data.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What are the eligibility criteria for your services?
                        <div className="plus-sign">
                            <BsPlusCircle />
                        </div>
                        <div className="minus-sign">
                            <CiCircleMinus />
                        </div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Eligibility criteria may vary depending on the specific service you are interested in. Generally, factors such as your age, income, credit history, and other relevant parameters are considered. Please refer to the specific service page for detailed eligibility requirements.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How long does it take to get a loan approved?
                        <div className="plus-sign">
                            <BsPlusCircle />
                        </div>
                        <div className="minus-sign">
                            <CiCircleMinus />
                        </div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        The loan approval process may vary depending on various factors such as the type of loan, documentation requirements, and the time taken by the lender for verification. In general, we strive to provide quick loan approvals, and you can expect to receive a decision within a few business days.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            
        </Accordion>
    </div>

  )
}