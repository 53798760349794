import React from 'react'
import './Banner.css'
import PartnersSwiperInternal from './PartnersSwiperInternal'

export default function Banner({prop}) {
    let width = window.innerWidth
    let height

    if (width > 1200) {
        height = 700
    }else if(width > 480 && width < 1200){
        height = 450
    }else{
        height = 300
    }


    return (
        <>
            <div className="banner-img">
                <img src={prop.img} alt="Banner" width='100%' height={height} />
                <div className="container headings">
                    <div className=" text-white text-center">
                        <h1 className='mb-4'>{prop.heading}</h1>
                        <p className='banner-p'>{prop.text}</p>
                    </div>
                </div>
                
                <div className="container">
                    <div className="box-div col-9 mx-auto banner-bottom">
                        <div className="text-center">
                            {/* <p className="primary-color mb-1">Secured Terms Business Loans</p> */}
                            <h4 className="">{prop.partner}</h4>
                        </div>
                        <PartnersSwiperInternal />
                    </div>
                </div>

            </div>
        </>
    )
}
