import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Home 
import Header from './components/Header/BootstrapHeader';
import Footer from './components/Footer';
import Home from './pages/home';
import AboutUs from './pages/aboutUs';

// Secured business loan 
import SecuredTermLoans from './pages/SME/securedTermLoans'
import MachineryEquipmentLoan from './pages/SME/machineryEquipmentLoan';
import LoanAgainstProperty from './pages/SME/loanAgainstProperty';
import SecuredCashCredit from './pages/SME/securedCashCredit';
import WorkingCapitalLoans from './pages/SME/workingCapitalLoans';
import SecureOverdraft from './pages/SME/secureOverdraft';

// Unsecured business loan 
import UnsecuredTermLoans from './pages/SME/unsecuredTermLoans'
import InvoiceDiscounting from './pages/SME/invoiceDiscounting'
import LineOfCredit from './pages/SME/lineOfCredit'
import UnsecuredCashCredit from './pages/SME/unsecuredCashCredit'

// Balance Transfer 
import BalanceTransfer from './pages/SME/balanceTransfer'

// Other Services 
// import CreditCard from './pages/OtherServices/creditCard'
// import Insurance from './pages/OtherServices/insurance'
// import Investment from './pages/OtherServices/investment'
// import FundingForStartups from './pages/OtherServices/fundingForStartups'
import OtherServices from './pages/OtherServices/otherServices'

// Loans under CGSTME scheme 
import LoansUnderCGTMSE from './pages/SME/loansUnderCGTMSE'

// Retail Loan
import HomeLoan from './pages/RetailLoan/homeLoan'
import EducationLoan from './pages/RetailLoan/educationLoan'

// Resources 
import EMICalculator from './pages/Resources/emiCalculatorPage';
import CheckEligibility from './pages/Resources/checkEligibility';
import Enquiry from './pages/Resources/enquiry'




function App () {
  return(
    <>
      <ToastContainer />
      <BrowserRouter>
        <Header />
        <Routes>

          <Route path='/' element={<Home/>} />

          {/* Secured business loan  */}
          <Route path='/secured-business-loan/secured-term-loan' element={<SecuredTermLoans />} />
          <Route path='/secured-business-loan/machinery-equipment-loan' element={<MachineryEquipmentLoan />} />
          <Route path='/secured-business-loan/loan-against-property' element={<LoanAgainstProperty />} />
          <Route path='/secured-business-loan/secured-cash-credit' element={<SecuredCashCredit />} />
          <Route path='/secured-business-loan/working-capital-loans' element={<WorkingCapitalLoans />} />
          <Route path='/secured-business-loan/secure-overdraft' element={<SecureOverdraft />} />


          {/* Unsecured business loan  */}
          <Route path='/unsecured-business-loan/unsecured-term-loan' element={<UnsecuredTermLoans />} />
          <Route path='/unsecured-business-loan/invoice-discounting' element={<InvoiceDiscounting />} />
          <Route path='/unsecured-business-loan/line-of-credit' element={<LineOfCredit />} />
          <Route path='/unsecured-business-loan/unsecured-cash-credit' element={<UnsecuredCashCredit />} />


          {/* Balance Transfer  */}
          <Route path='/balance-transfer' element={<BalanceTransfer />} />


          {/* Other Services  */}
          {/* <Route path='/other-services/credit-card' element={<CreditCard />} /> */}
          {/* <Route path='/other-services/insurance' element={<Insurance />} /> */}
          {/* <Route path='/other-services/investments' element={<Investment />} /> */}
          {/* <Route path='/other-services/funding-for-startups' element={<FundingForStartups />} /> */}
          <Route path='/other-services' element={<OtherServices />} />


          {/* Loans Under CGTMSE scheme  */}
          <Route path='/loans-under-cgtmse-scheme' element={<LoansUnderCGTMSE />} />


          {/* Retail loan  */}
          <Route path='/retail-loan/home-loan' element={<HomeLoan />} />
          <Route path='/retail-loan/education-loan' element={<EducationLoan />} />
          <Route path='/retail-loan/loan-against-property' element={<LoanAgainstProperty />} />
          <Route path='/retail-loan/balance-transfer' element={<BalanceTransfer />} />


          {/* Resources */}
          <Route path='/resources/emi-calculator' element={<EMICalculator />} />
          <Route path='/resources/check-eligibility' element={<CheckEligibility />} />
          {/* <Route path='/resources/enquiry' element={<Enquiry />} /> */}

          {/* About Us  */}
          <Route path='/about-us' element={<AboutUs />} />

          {/* Contact Us  */}
          <Route path='/contact-us' element={<Enquiry />} />
          
          
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App;