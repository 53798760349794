import React from 'react'
import './OSComponent.css'

export default function OSFeatures() {
    return (
        <>
            <div className="features-bg insure mb-5">
                <div className="container d-flex flex-wrap">
                    <div className="col-md-3 col-6">
                        <div className="feature-img-cc special-feature pt-5 pt-md-0">
                            <img src={require("../../images/OtherServices/shield.png")} alt="Money" width='70%' />
                        </div>
                        <div className="pt-sm-5 pt-2 pb-4 text-center fet-txt">
                            <h3 className="px-3 primary-color d-none d-lg-block">Protection Against Uncertainty</h3>
                            <h6 className="px-3 primary-color d-lg-none">Protection Against Uncertainty</h6>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img-cc pt-4 pt-md-0">
                            <img src={require("../../images/OtherServices/wallet.png")} alt="Percent" width='60%' />
                        </div>
                        <div className="pt-sm-5 pt-2 pb-4 text-center fet-txt">
                            <h3 className="px-3 primary-color d-none d-lg-block">Cash Flow Management</h3>
                            <h6 className="px-3 primary-color d-lg-none">Cash Flow Management</h6>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img-cc">
                            <img src={require("../../images/OtherServices/rising.png")} alt="Time" width='60%' />
                        </div>
                        <div className="pt-sm-5 pt-2 pb-4 text-center fet-txt">
                            <h3 className="px-3  pt-md-0 primary-color d-none d-lg-block">Investment Opportunities</h3>
                            <h6 className="px-3  pt-md-0 primary-color d-lg-none">Investment Opportunities</h6>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img-cc">
                            <img src={require("../../images/OtherServices/tax.png")} alt="Collateral" width='70%' />
                        </div>
                        <div className="pt-sm-5 pt-2 pb-4 text-center fet-txt">
                            <h3 className="px-3 primary-color  pt-md-3 d-none d-lg-block">Tax Benefits</h3>
                            <h6 className="px-3 primary-color  pt-md-3 d-lg-none">Tax Benefits</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
