import React from 'react'
import './RetailFeatures.css'

export default function Features({prop}) {
    console.log(prop);
    return (
        <>
        {
            prop.txt1 === "Tax Benefits"?
            <div className="features-bg retail mb-5">
                <div className="container d-flex flex-wrap">
                    <div className="col-md-3 col-6">
                        <div className="feature-img fold-style spec-img ">
                            <img src={prop.img1} alt="" width='100%' height='125' />
                        </div>
                        <div className="foldP pt-5 pb-4 text-center">
                            <h3 className="fw-bold primary-color pt-md-4 py-2">{prop.txt1}</h3>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img fold-style">
                            <img src={prop.img2} alt="" width='100%' height='100' />
                        </div>
                        <div className="foldP pt-5 pb-4 text-center homeFold">
                            <h3 className="fw-bold primary-color pt-md-4 py-2">{prop.txt2}</h3>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img fold-style">
                            <img src={prop.img3} alt="" width='100%' height='115' />
                        </div>
                        <div className="foldP pt-5 pb-4 text-center">
                            <h3 className="fw-bold pt-4 pt-md-0 primary-color pt-md-4 py-2">{prop.txt3}</h3>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img fold-style ">
                            <img src={prop.img4} alt="" width='100%' height='120' />
                        </div>
                        <div className="foldP pt-5 pb-4 text-center homeFold">
                            <h3 className="fw-bold pt-4 pt-md-0 primary-color pt-md-4 py-2">{prop.txt4}</h3>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="features-bg retail mb-5">
                <div className="container d-flex flex-wrap">
                    <div className="col-md-3 col-6">
                        <div className="feature-img fold-style spec-img ">
                            <img src={prop.img1} alt="" width='100%' height='125' />
                        </div>
                        <div className="foldP pt-5 pb-4 text-center">
                            <h3 className="fw-bold primary-color pt-md-4 py-2">{prop.txt1}</h3>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img fold-style foldSpecial">
                            <img src={prop.img2} alt="" width='100%' height='100' />
                        </div>
                        <div className="foldP pt-5 pb-4 text-center">
                            <h3 className="fw-bold primary-color pt-md-4 py-2">{prop.txt2}</h3>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img fold-style">
                            <img src={prop.img3} alt="" width='100%' height='115' />
                        </div>
                        <div className="foldP pt-5 pb-4 text-center">
                            <h3 className="fw-bold pt-4 pt-md-0 primary-color pt-md-4 py-2">{prop.txt3}</h3>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img fold-style ">
                            <img src={prop.img4} alt="" width='100%' height='120' />
                        </div>
                        <div className="foldP pt-5 pb-4 text-center">
                            <h3 className="fw-bold pt-4 pt-md-0 primary-color pt-md-4 py-2">{prop.txt4}</h3>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}
