import React from 'react'
import './OS.css'
import CreditCard from './creditCard'
// import Insurance from './insurance'
// import Investment from './investment'
// import FundingForStartups from './fundingForStartups'

import { InternalFAQ } from '../../components/Common/InternalFAQ'
import { CommonFAQ } from '../../components/Common/InternalFAQItems'
import OSFeatures from '../../components/OtherServices/OSFeatures'
import WhatsappConnect from '../../components/Common/whatsappConnect'


export default function otherServices() {
    return (
        <>
            {/* Credit card  */}
            <CreditCard />

            {/* Insurance */}
            <div className="insurance-section">
                <div className="container my-5">
                    <div className="text-center">
                        <p className="primary-color mb-1">Other Services</p>
                        <h1>Insurance</h1>
                    </div>
                </div>
                <div className=" d-flex flex-wrap align-items-center ">
                    <div className="col-md-6 col-8 mx-auto mobile-width px-md-0 px-2">
                        <img src={require("../../images/OS-insurance.png")} alt="Insurance" className="img-fluid w-100" />
                    </div>
                    <div className="col-md-1 d-none d-xl-block"></div>
                    <div className="col-md-6 col-lg-5 col-xl-5 col-12 px-lg-0 ps-md-2 pe-md-0 px-2 pt-md-0 pt-3">
                        <div className="bg-insu text-end mb-3">
                            <h4 className='py-1 px-2'>What is Insurance?</h4>
                        </div>
                        <p className='pb-lg-5 pb-md-2 pb-4'>Insurance is a way to protect yourself or your belongings from unexpected events by paying a small amount regularly. If something bad happens, the insurance company helps you cover the costs, so you don't have to bear the financial burden alone.</p>
                        <div className="bg-insu text-end my-3">
                            <h4 className='py-1 px-sm-2'>How does Insurance work?</h4>
                        </div>
                        <p>Insurance works by pooling together money from many people who face similar risks. Each person pays a premium to an insurance company. When someone in the group experiences a covered event, like an accident or illness, the insurance company uses the collected money to pay for their losses.</p>
                        <p>This system helps individuals manage unexpected expenses and provides a safety net during challenging times.</p>
                    </div>
                </div>
                <div className="bg-insu mt-lg-5 mt-3">
                    <div className="container">
                        <div className="col-md-10 col-12 mx-auto">
                            <h2 className='py-2 mb-2'>Benefits of buying Insurance</h2>
                        </div>
                    </div>
                </div>
                <div className="container pb-md-5">
                    <div className="col-md-10 col-12 mx-auto">
                        <p>Acquiring an insurance policy offers a range of advantages for an individual. It safeguards against unforeseen financial losses and helps create a life after retirement fund. Here are some additional benefits of an insurance policy:</p>
                    </div>
                </div>
                <OSFeatures />
            </div>

            {/* Investment  */}
            <div className="investment-section">
                <div className="container my-5">
                    <div className="text-center">
                        <p className="primary-color mb-1">Other Services</p>
                        <h1>Investment</h1>
                    </div>
                </div>
                <div className="position-relative">
                    <img src={require("../../images/Investment-banner-new.png")} alt="Insureance Banner" className="img-fluid w-100 os-banner" />
                    <div className="invest-banner position-absolute w-100 text-center text-white">
                        <h2 className='py-2 mb-0'>What are the Investment Plans?</h2>
                    </div>
                </div>
                <div className="bg-insu">
                    <div className="container">
                        <div className="col-md-10 col-12 mx-auto pt-lg-5 pt-4">
                            <p className='my-3'>Our top-rated home loan services can make your dream home a reality. Urban Money has partnered with several banks to offer quick and easy mortgage loans. You can access home credit loans that come with numerous advantages, including low interest rates on housing loans and smaller EMIs that allow you to spread out your payments over a more extended period.</p>
                            <ul className="ps-md-0 ps-2">
                                <li><span className='fw-bold'>Stocks:</span> fractional ownership of companies, bought and sold on exchanges, potential for profitable returns.</li>
                                <li><span className='fw-bold'>Bonds:</span> loaning money to companies, small recurring payments, repayment of full amount when maturity period is up.</li>
                                <li><span className='fw-bold'>Mutual Funds:</span> pooled money from various investors, managed by expert fund managers, returns based on contribution.</li>
                                <li><span className='fw-bold'>ETFs:</span> similar to mutual funds but can be bought and sold on stock exchanges like stocks.</li>
                                <li><span className='fw-bold'>Options:</span> agreement to buy or sell a company's underlying assets at a fixed price, high-risk, high-reward.</li>
                                <li><span className='fw-bold'>Annuities:</span> steady stream of income for a set period of time, contract signed between investor and insurance company, tax benefits.</li>
                                <li><span className='fw-bold'>Cryptocurrencies:</span> high-risk, high-reward investment that has risen in popularity, trading happens on investment platforms and apps.</li>
                                <li><span className='fw-bold'>Commodity Futures:</span> buyers have the right to buy a commodity at a preset cost and date, reserved for those with expertise in the commodity and its market value.</li>
                                <li><span className='fw-bold'>Security Futures:</span> buyers enter an agreement with producers to buy commodities at future date but with current price, profitable if commodity price rises.</li>
                            </ul>
                            <div className="py-md-4 py-4">
                                <h2 className='mb-3'>How does an Investment work?</h2>
                                <p>Investing involves using different channels and platforms to put your money into an asset, leaving it there to grow over time, and earning a higher return on your initial investment. There are various investment types with different durations, such as short-term and long-term investments.</p>
                                <p>Contrary to savings, where the objective is short-term and the growth rate of money is steady yet slow, invested funds entirely depend on the market value of an asset. As a result, it is crucial to carefully review a company's annual reports and strategies before making any investments.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Startup  */}
            <div className="startup-section">
                <div className="container my-5">
                    <div className="text-center">
                        <p className="primary-color mb-1">Other Services</p>
                        <h1>Funding for a Startup</h1>
                    </div>
                </div>
                <img src={require("../../images/FFS-banner-new.png")} alt="FFS Banner" className="img-fluid w-100 os-banner" />
                <div className="container">
                    <div className="col-md-10 col-12 mx-auto">
                        <div className="insider py-5">
                            <h2 className="mb-4">What is a Startup?</h2>
                            <p>A Startup is the spark of an idea transformed into a remarkable journey. It's an audacious venture driven by passionate individuals with a hunger for innovation and a vision to disrupt the norm. Startups are the catalysts of change, the pioneers of tomorrow, and the fearless dreamers who turn possibilities into realities.</p>
                            <p>A startup is not merely a business; it is an embodiment of passion, creativity, and boundless potential. It is a venture born out of a bold vision, driven by relentless determination, and fueled by the courage to challenge the status quo. Startups are the catalysts of change, the disruptors of industries, and the trailblazers who shape our future.</p>
                        </div>
                        <div className="insider pb-5">
                            <h2 className="mb-4">What is a Startup funding?</h2>
                            <p>Startup funding refers to the financial resources and investments acquired by a startup company to support its operations, growth, and development. This funding is crucial for startups to cover various expenses such as product development, marketing, hiring talent, infrastructure, and other operational costs.</p>
                            <p>Startup funding can come from different sources, including angel investors, venture capital firms, crowdfunding platforms, government grants, bank loans, and strategic partnerships. Securing adequate funding is essential for startups to fuel their innovation, expand their market presence, and achieve their business objectives.</p>
                        </div>
                        <div className="insider pb-5">
                            <h2 className="mb-4">Key Factors Investors Seek in Startups</h2>
                            <ul className="ps-md-0 ps-2">
                                <li><span className='fw-bold'>Compelling Vision:</span> A clear and captivating vision that demonstrates innovation and market potential.</li>
                                <li><span className='fw-bold'>Strong Team:</span> Talented and dedicated team members with industry expertise and a track record of success.</li>
                                <li><span className='fw-bold'>Market Potential:</span> Addressing sizable markets with unmet needs and offering scalable solutions.</li>
                                <li><span className='fw-bold'>Traction and Validation:</span> Early signs of customer adoption, revenue generation, and strategic partnerships.</li>
                                <li><span className='fw-bold'>Sustainable Business Model:</span> Clear path to profitability, solid financial projections, and monetization strategies.</li>
                                <li><span className='fw-bold'>Differentiation and Competitive Advantage:</span> Unique value proposition and a competitive edge in the market.</li>
                                <li><span className='fw-bold'>Growth Potential and Exit Strategy:</span> High growth potential and a clear plan for investor exit</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Internal FAQ  */}
            <div className="faq">
                <div className="container text-center">
                    <p className="primary-color mb-0 pt-5">Useful Links</p>
                    <h2 className='my-4'>Frequently Asked Questions</h2>
                    <p>Everything you need to know about  loan  services and other finance option</p>
                </div>
                <div className="container">
                    <InternalFAQ prop={CommonFAQ}/>
                </div>
            </div>

            {/* Whatsapp  */}
            <WhatsappConnect />

        </>
    )
}
