import React, { useState } from 'react'
import './Benifits.css'
import RetailFeatures from './RetailFeatures'
import { ELFeatures } from '../Common/FeatureItems'

export default function EducationBenifits() {
    const [opened, setOpened] = useState(1)

    const handleChange = (data) => {
        let FBbutton = document.getElementById('FBButton').classList
        let ECbutton = document.getElementById('ECButton').classList
        if(data === 1){
            ECbutton.remove('current')
            FBbutton.add('current')
            setOpened(1)
        }
        if(data === 2){
            ECbutton.add('current')
            FBbutton.remove('current')
            setOpened(2)
        }
    }


    return (
        <>
            <div className="back-gray">
                <div className="container d-flex flex-wrap text-center">
                    <div className="col-6">
                        <button className='py-md-2 py-3 px-md-4 px-2 h-100 current' id='FBButton' onClick={()=>handleChange(1)} >
                            Features & Benefits
                        </button>
                    </div>
                    <div className="col-6">
                        <button className='py-md-2 py-3 px-md-4 px-2 h-100' id='ECButton' onClick={()=>handleChange(2)} >
                            Eligibility
                        </button>
                    </div>
                </div>
            </div>

            {
                opened === 1 ?
                <div id="FBtxt" className=' mt-5'>
                    <div className="container pb-4 px-md-5 ">
                        <div className=''>
                            <p className='primary-color'>Education Loan</p>
                            <h1>Benefits of taking an Education Loan</h1>
                        </div>
                    </div>
                    <RetailFeatures prop={ELFeatures} />
                    <div className="container px-md-5 px-3">
                        <div className="pb-4">
                            <h1 className='mb-3'>Education Loan Overview</h1>
                            <p>An education loan is a financial product designed to provide funding for higher education expenses. It helps students and their families cover the cost of tuition fees, books, accommodation, and other related expenses. Education loans typically come with competitive interest rates, flexible repayment options, and extended repayment periods, making education more accessible and affordable for students.</p>
                            <p>They offer the opportunity to pursue quality education without the burden of immediate financial strain, empowering individuals to invest in their future and achieve their academic aspirations.</p>
                            <p></p>
                        </div>

                        <div className="pb-4">
                            <h1 className="mb-3">Education Loan Tax Benefits</h1>
                            <p>Maximize your tax benefits on education loan interest payments with Section 80 EEA. Claim deductions of up to INR 1.5 lakhs on the interest component, ensuring significant tax savings while repaying your education loan.</p>

                            <div className="col-lg-8 col-md-10 col-12 mt-4 mb-5 mx-auto">
                                <table className='table table-bordered text-center'>
                                    <thead>
                                        <th className='border py-2'>Particulars</th>
                                        <th className='border py-2'>Quantum of Amount Deduction</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Under Section 24</td>
                                            <td className='text-start'>
                                                a. For self-occupied property: ₹ 2,00,000 <br />
                                                b. For non-self occupied property: No set limit
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Under Section 24</td>
                                            <td className='text-start'>
                                                a. For self-occupied property: ₹ 2,00,000 <br />
                                                b. For non-self occupied property: No set limit
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Under Section 24</td>
                                            <td className='text-start'>
                                                a. For self-occupied property: ₹ 2,00,000 <br />
                                                b. For non-self occupied property: No set limit
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div id="ECtxt" className='mt-5'>
                    <div className="container px-md-5 px-3">
                        <div className="pb-4">
                            <p className="primary-color">Education Loan</p>
                            <h1 className='mb-3'>Eligibility for Education Loan</h1>
                            <p>Eligibility criteria for education loans:</p>
                            <ul className="ul-style">
                                <li><b>Indian residency:</b>The applicant must be a resident of India.</li>
                                <li><b>Admission confirmation:</b>The candidate should have confirmed admission to recognized educational institutions in India or abroad.</li>
                                <li><b>Age requirement:</b>The age of the applicant should be between 18 and 35 years during the loan application.</li>
                                <li><b>Course enrollment:</b>The candidate must be enrolled in a graduate/postgraduate degree or PG diploma program.</li>
                                <li><b>Affiliated institutions:</b>The applicant should have secured admission to a college or university affiliated with UGC/AICTE/Govt., etc.</li>
                                <li><b>Co-applicant requirement:</b>Full-time course students need a co-applicant, such as a parent/guardian or spouse/parent-in-law, who should have a regular income source.</li>
                                <li><b>Academic record:</b>A strong academic record can facilitate faster loan approval.</li>
                            </ul>
                        </div>
                        <div className="pb-4">
                            <h1 className="mb-3">Reasons for Education Loan Rejection</h1>
                            <ul className="ul-style">
                                <li><b>Low CIBIL Score:</b>Maintain a good credit score by paying your EMIs and credit card bills on time to increase the chances of loan approval.</li>
                                <li><b>Incomplete or Fake Documents:</b>Ensure all required documents are complete and genuine to avoid rejection of your loan application.</li>
                                <li><b>Study Destination:</b>Some lenders prioritize loans for popular study destinations, so choose recognized institutions in preferred study destinations to enhance your loan approval chances.</li>
                                <li><b>Academic Performance:</b>Maintain a consistent and strong academic record to improve your chances of loan approval.</li>
                                <li><b>Multiple Loan Applications:</b>Limit the number of loan applications to avoid negative impacts on your creditworthiness and credit score.</li>
                                <li><b>Institution Recognition:</b>Confirm that your chosen institution is recognized by lenders to avoid rejection based on institution authenticity.</li>
                                <li><b>Repaying Capacity:</b>Demonstrate a strong repaying capacity by having a stable income source and a good credit history.</li>
                                <li><b>Choose the Right Lender:</b>Approach the right financial institution that offers suitable loan options for your specific needs and loan amount requirements.</li>
                            </ul>
                        </div>
                        <div className="pb-4">
                            <h1 className="mb-3">Required Education Loan Document</h1>
                            <p>You need these  documents to get the Education loan approved</p>

                            <div className="col-lg-8 col-md-10 col-12 mt-4 mb-5 mx-auto">
                                <table className='table table-bordered text-center'>
                                    <thead>
                                        <th className='border py-2'>Type</th>
                                        <th className='border py-2'>Documents include</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Identity Poof</td>
                                            <td className='text-start'>PAN and Aadhar Card, Applicant's Passport, Voter ID Card, Driving Licence</td>
                                        </tr>
                                        <tr>
                                            <td>Residence Proof</td>
                                            <td className='text-start'>Utility bills (electricity or telephone bill), Rental Agreement, Bank Statement of last 6 months and Form 16, Identity proof that contain address, like Aadhaar CardBank account statements, Income proof and employment proof</td>
                                        </tr>
                                        <tr>
                                            <td>Other Document</td>
                                            <td className='text-start'>Passport-size photographs of the applicant,<br />
                                            For self-employed, business continuity proof will be required (5 years)<br />
                                            Most recent salary slip or Form 16 of Parent/Guardian, 10th and 12th Marksheet</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </>
    )
}

