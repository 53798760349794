// Swiper 
import { Navigation, Autoplay, Pagination, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'


// Images 
import SBImg from '../images/secured-business.png'
import UBImg from '../images/unsecured-business.png'
import BTImg from '../images/balance-transfer-business.png'
import OSImg from '../images/other-services-business.png'

// Custom css 
import './BusinessLoanSwiper.css'


export default function BusinessLoanSwiper () {
    return (
        <>
            <div className="d-none d-lg-block pb-2 homeBusiness">
                <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{delay : 3000}}
                // navigation
                pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                className='businessLoan-swiper mt-3 mb-4'
                >
                    

                    <SwiperSlide>
                        <div className="container d-flex flex-wrap text-white">
                            <div className="col-md-6 p-md-5 mt-4 mt-md-0 loan-img">
                                <img src={SBImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-md-5 business-text p-2">
                                <p>Business Loans</p>
                                <h1>Your Business Loan Options</h1>
                                <p>Secured Business Loans</p>
                                <p>Secured business loans offer a range of options, including secured term loans, machinery/equipment loans, loan against property, secured cash credit, working capital loans, and secured overdraft. These financing solutions provide businesses with access to capital by leveraging collateral, ensuring flexible funding for various needs such as expansion, equipment purchase, property-backed financing, cash flow management, and more.</p>
                                <a href='/secured-business-loan/secured-term-loan' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="container d-flex flex-wrap text-white">
                            <div className="col-md-6 p-md-5 mt-4 mt-md-0 loan-img">
                                <img src={UBImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-md-5 business-text p-2">
                                <p>Business Loans</p>
                                <h1>Your Business Loan Options</h1>
                                <p>Unsecured Business Loans</p>
                                <p>Unsecured business loans provide flexible financing options without the need for collateral. With unsecured term loans, invoice discounting, line of credit, and unsecured cash credit, businesses can access funds for various purposes such as working capital, invoice management, short-term financing needs, and operational expenses, allowing them to meet financial requirements without pledging assets.</p>
                                <a href='/unsecured-business-loan/unsecured-term-loan' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="container d-flex flex-wrap text-white">
                            <div className="col-md-6 p-md-5 mt-4 mt-md-0 loan-img">
                                <img src={BTImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-md-5 business-text p-2">
                                <p>Business Loans</p>
                                <h1>Your Business Loan Options</h1>
                                <p>Balance Transfer</p>
                                <p>A Balance Transfer is a loan-swapping arrangement that allows you to transfer your existing loan from one financial institution to another, often with improved terms and interest rates. By taking advantage of this facility, borrowers can consolidate debt, reduce interest costs, and simplify repayments, resulting in potential savings and enhanced financial management.</p>
                                <a href='/balance-transfer' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="container d-flex flex-wrap text-white">
                            <div className="col-md-6 p-md-5 mt-4 mt-md-0 loan-img">
                                <img src={OSImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-md-5 business-text p-2">
                                <p>Business Loans</p>
                                <h1>Your Business Loan Options</h1>
                                <p>Other Services</p>
                                <p>At Finexa Capital, you can explore a range of financial services tailored to your needs. We provide options such as credit cards, offering convenient payment solutions and access to credit. Additionally, we offer insurance coverage to protect against unforeseen risks, investment opportunities to help grow your wealth, and funding specifically designed for startups, providing capital to fuel growth and innovation.</p>
                                <a href='/other-services' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide> 

                </Swiper>
            </div>

            <div className="d-none d-md-block d-lg-none mb-3 ">
                <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={30}
                slidesPerView={1}
                // autoplay={{delay : 3000}}
                // navigation
                pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                className='businessLoan-swiper mt-3 mb-4'
                >
                    

                    <SwiperSlide>
                        <div className="container d-flex flex-wrap  text-white">
                            <div className="col-md-6 p-xl-5 px-md-3 p-2 mt-5 loan-img">
                                <img src={SBImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-xl-5 business-text p-2">
                                <p>Business Loans</p>
                                <h1 className='special-h1'>Your Business Loan Options</h1>
                                <p>Secured Business Loans</p>
                                <p className='special-txt'>Secured business loans offer a range of options, including secured term loans, machinery/equipment loans, loan against property, secured cash credit, working capital loans, and secured overdraft. These financing solutions provide businesses with access to capital by leveraging collateral, ensuring flexible funding for various needs such as expansion, equipment purchase, property-backed financing, cash flow management, and more.</p>
                                <a href='/secured-business-loan/secured-term-loan' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="container d-flex flex-wrap  text-white">
                            <div className="col-md-6 p-xl-5 px-md-3 p-2 mt-5 loan-img">
                                <img src={UBImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-xl-5 business-text p-2">
                                <p>Business Loans</p>
                                <h1 className='special-h1'>Your Business Loan Options</h1>
                                <p>Unsecured Business Loans</p>
                                <p className='special-txt'>Unsecured business loans provide flexible financing options without the need for collateral. With unsecured term loans, invoice discounting, line of credit, and unsecured cash credit, businesses can access funds for various purposes such as working capital, invoice management, short-term financing needs, and operational expenses, allowing them to meet financial requirements without pledging assets.</p>
                                <a href='/unsecured-business-loan/unsecured-term-loan' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="container d-flex flex-wrap  text-white">
                            <div className="col-md-6 p-xl-5 px-md-3 p-2 mt-5 loan-img">
                                <img src={BTImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-xl-5 business-text p-2">
                                <p>Business Loans</p>
                                <h1 className='special-h1'>Your Business Loan Options</h1>
                                <p>Balance Transfer</p>
                                <p className='special-txt'>A Balance Transfer is a loan-swapping arrangement that allows you to transfer your existing loan from one financial institution to another, often with improved terms and interest rates. By taking advantage of this facility, borrowers can consolidate debt, reduce interest costs, and simplify repayments, resulting in potential savings and enhanced financial management.</p>
                                <a href='/balance-transfer' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="container d-flex flex-wrap  text-white">
                            <div className="col-md-6 p-xl-5 px-md-3 p-2 mt-5 loan-img">
                                <img src={OSImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-xl-5 business-text p-2">
                                <p>Business Loans</p>
                                <h1 className='special-h1'>Your Business Loan Options</h1>
                                <p>Other Services</p>
                                <p className='special-txt'>At Finexa Capital, you can explore a range of financial services tailored to your needs. We provide options such as credit cards, offering convenient payment solutions and access to credit. Additionally, we offer insurance coverage to protect against unforeseen risks, investment opportunities to help grow your wealth, and funding specifically designed for startups, providing capital to fuel growth and innovation.</p>
                                <a href='/other-services' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide> 

                </Swiper>
            </div>

            <div className="d-sm-block d-xs-block d-md-none mb-3 ">
                <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={20}
                slidesPerView={1}
                autoplay={{delay : 3000}}
                // navigation
                pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                className='businessLoan-swiper mt-3 mb-4'
                >
                    

                    <SwiperSlide>
                        <div className="text-white">
                            <div className="col-md-6 p-md-5 mt-4 mt-md-0 loan-img">
                                <img src={SBImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-md-5 business-text p-2">
                                <p className='pt-4 pt-md-0'>Business Loans</p>
                                <h1>Your Business Loan Options</h1>
                                <p>Secured Business Loans</p>
                                <p>Secured business loans offer a range of options, including secured term loans, machinery/equipment loans, loan against property, secured cash credit, working capital loans, and secured overdraft. These financing solutions provide businesses with access to capital by leveraging collateral, ensuring flexible funding for various needs such as expansion, equipment purchase, property-backed financing, cash flow management, and more.</p>
                                <a href='/secured-business-loan/secured-term-loan' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="text-white">
                            <div className="col-md-6 p-md-5 mt-4 mt-md-0 loan-img">
                                <img src={UBImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-md-5 business-text p-2">
                                <p className='pt-4 pt-md-0'>Business Loans</p>
                                <h1>Your Business Loan Options</h1>
                                <p>Unsecured Business Loans</p>
                                <p>Unsecured business loans provide flexible financing options without the need for collateral. With unsecured term loans, invoice discounting, line of credit, and unsecured cash credit, businesses can access funds for various purposes such as working capital, invoice management, short-term financing needs, and operational expenses, allowing them to meet financial requirements without pledging assets.</p>
                                <a href='/unsecured-business-loan/unsecured-term-loan' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="text-white">
                            <div className="col-md-6 p-md-5 mt-4 mt-md-0 loan-img">
                                <img src={BTImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-md-5 business-text p-2">
                                <p className='pt-4 pt-md-0'>Business Loans</p>
                                <h1>Your Business Loan Options</h1>
                                <p>Balance Transfer</p>
                                <p>A Balance Transfer is a loan-swapping arrangement that allows you to transfer your existing loan from one financial institution to another, often with improved terms and interest rates. By taking advantage of this facility, borrowers can consolidate debt, reduce interest costs, and simplify repayments, resulting in potential savings and enhanced financial management.</p>
                                <a href='/balance-transfer' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="text-white">
                            <div className="col-md-6 p-md-5 mt-4 mt-md-0 loan-img">
                                <img src={OSImg} alt="" width='100%' />
                            </div>
                            <div className="col-md-6 p-md-5 business-text p-2">
                                <p className='pt-4 pt-md-0'>Business Loans</p>
                                <h1>Your Business Loan Options</h1>
                                <p>Other Services</p>
                                <p>At Finexa Capital, you can explore a range of financial services tailored to your needs. We provide options such as credit cards, offering convenient payment solutions and access to credit. Additionally, we offer insurance coverage to protect against unforeseen risks, investment opportunities to help grow your wealth, and funding specifically designed for startups, providing capital to fuel growth and innovation.</p>
                                <a href='/other-services' className='btn btn-secondary px-5 mt-4 mb-5'>Know More</a>
                            </div>
                        </div>
                    </SwiperSlide> 

                </Swiper>
            </div>
        </>
    )
}