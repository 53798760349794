import React from 'react'
import './Features.css'

export default function Features({ prop, specialFeature, loneTenure }) {
    return (
        <>
            <div className="container">
                <div className="text-center mt-5">
                    <p className='primary-color'>{prop.head}</p>
                    <h2 className='pb-4'>Distinctive Features</h2>
                </div>
            </div>
            <div className="features-bg mb-5">
                <div className="container d-flex flex-wrap">
                    <div className="col-md-3 col-6">
                        <div className="feature-img spc pt-2 pt-md-0">
                            <img src={require("../../images/SBL-Images/money.png")} alt="Money" width='100%' />
                        </div>
                        <div className="pt-md-5 py-0 pb-md-4 text-center">
                            {
                                specialFeature ?
                                <p className='px-md-4 px-lg-0 px-0'>Loan amount upto</p>
                                :
                                <p className='px-md-4 px-lg-0 px-0'>Loan amount starting from</p>
                            }
                            <h3 className="fw-bold primary-color px-lg-5 px-md-3">{prop.money}</h3>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img">
                            <img src={require("../../images/SBL-Images/percent.png")} alt="Percent" width='100%' height='100' />
                        </div>
                        <div className="pt-md-5 py-0 pb-md-4 text-center">
                            <p className='px-md-4 px-lg-0 px-0'>Interest rate starting from</p>
                            <h3 className="fw-bold primary-color px-lg-5 px-md-3">{prop.percent}</h3>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img">
                            <img src={require("../../images/SBL-Images/time.png")} alt="Time" width='100%' height='115' />
                        </div>
                        <div className="pt-md-5 py-4 pb-md-4 text-center">
                            {
                                loneTenure ?
                                <p className='px-md-4 px-lg-0 px-0'>Loan Tenure</p>
                                :
                                <p className='px-md-4 px-lg-0 px-0'>Loan Tenure up to</p>

                            }
                            <h3 className="fw-bold primary-color px-lg-5 px-md-3">{prop.time}</h3>
                        </div>
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="feature-img">
                            <img src={require("../../images/SBL-Images/home.png")} alt="Collateral" className='pt-0' width='100%' height='120' />
                        </div>
                        <div className="pt-md-5 py-4 pb-md-4 text-center">
                            <p className='px-md-4 px-lg-0 px-0'>Collateral Required</p>
                            <h3 className="fw-bold primary-color px-lg-5 px-md-3">{prop.home}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
