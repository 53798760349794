export const EMIData = {
    business : {
        AmOuNt : 1500000,
        TeNuRe : 5,
        InTrEsT : 11,
        monthlyEMI : 32610,
        totInt : 457000
    },
    education : {
        AmOuNt : 1000000,
        TeNuRe : 5,
        InTrEsT : 9,
        monthlyEMI : 20760,
        totInt : 245501
    }
}