import React from "react";
import HelpInternalSlider from "./HelpInternalSlider";

export default function HelpSection({ prop }) {
  // console.log(prop);
  return (
    <>
      {prop[0].title ? (
        <>
          {/* <div className="col-12 d-block d-md-none ">
                        <img src={prop[0].img} alt="Help section" width='100%' className='help-img pb-2' />
                    </div> */}
          <div className="col-md-6 col-12 p-lg-4 pe-md-4 mt-3">
            <p className="primary-color">{prop[0].head}</p>
            <h2 className="mb-4">{prop[0].title}</h2>
            <div className="internal-slider pe-lg-5">
              <HelpInternalSlider prop={prop} />
            </div>
          </div>
          <div className="col-md-6  px-lg-5">
            <img
              src={prop[0].img}
              alt="Help section"
              width="100%"
              className="help-img about-img pb-5"
            />
          </div>
        </>
      ) : (
        <>
          <div className="col-12 d-block d-md-none ">
            <img
              src={prop[0].img}
              alt="Help section"
              width="100%"
              className="help-img pb-2"
            />
          </div>
          <div className="col-md-6 col-12 px-lg-4 pe-md-4 mt-3">
            <p className="primary-color">{prop[0].head}</p>
            <h2 className="mb-4">Experience Our Value</h2>
            <div className="internal-slider pe-lg-5">
              <HelpInternalSlider prop={prop} />
              {window.location.pathname === "/other-services" ||
              window.location.pathname === "/loans-under-cgtmse-scheme" ? (
                // window.location.pathname === '/other-services/credit-card' ?
                <></>
              ) : (
                <a
                  href="/resources/check-eligibility"
                  className="btn btn-primary px-5 my-md-3 mb-5"
                >
                  Apply Now
                </a>
              )}
            </div>
          </div>
          <div className="col-md-6 d-none d-md-block">
            <img
              src={prop[0].img}
              alt="Help section"
              width="100%"
              className="help-img pb-5"
            />
          </div>
        </>
      )}
    </>
  );
}
