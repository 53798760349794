import React from 'react'
import './OSComponent.css'

export default function OSFeatures() {
    return (
        <>
            <div className="container">
                <div className="text-center mt-5">
                    <p className='primary-color'>Credit card</p>
                    <h2 className='pb-4'>Distinctive Features</h2>
                </div>
            </div>
            <div className="features-bg credit-card mb-5">
                <div className="container d-flex flex-wrap">
                    <div className="col-md-4 col-6 other-feature">
                        <div className="feature-img-1">
                            <img src={require("../../images/OtherServices/reward.png")} alt="Money" width='100%' />
                        </div>
                        <div className="pt-0 pb-md-4 pt-md-5 text-center feature-padd">
                            <h3 className="fw-bold primary-color ">Rewards</h3>
                            <p className='px-md-5 px-2'>Exclusive Rewards and Category Bonuses Await</p>
                        </div>
                    </div>

                    <div className="col-md-4 col-6 other-feature">
                        <div className="feature-img-1">
                            <img src={require("../../images/OtherServices/cashback.png")} alt="Percent" width='100%' />
                        </div>
                        <div className="pt-0 pb-md-4 pt-md-5 text-center feature-padd">
                            <h3 className="fw-bold primary-color ">Cashback</h3>
                            <p className='px-md-5 px-2'>Instant Cashback with Lucrative Rewards</p>
                        </div>
                    </div>

                    <div className="col-md-4 col-6 other-feature">
                        <div className="feature-img-1">
                            <img src={require("../../images/OtherServices/balanceTransfer.png")} alt="Time" width='100%' />
                        </div>
                        <div className="pt-0 pb-md-4 pt-md-5 text-center feature-padd">
                            <h3 className="fw-bold primary-color ">Balance Transfer</h3>
                            <p className='px-md-5 px-2'>Convenient Top-Up and Balance Transfer Options</p>
                        </div>
                    </div>

                    <div className="col-md-4 col-6">
                        <div className="feature-img-2">
                            <img src={require("../../images/OtherServices/travel.png")} alt="Collateral" width='100%' />
                        </div>
                        <div className="pt-0 pt-md-4 pb-md-4 text-center feature-padd">
                            <h3 className="fw-bold primary-color ">Travel</h3>
                            <p className='px-md-5 px-2'>Travel with Rewards: Hassle-Free Credit Card Benefits</p>
                        </div>
                    </div>

                    <div className="col-md-4 col-6">
                        <div className="feature-img-2">
                            <img src={require("../../images/OtherServices/percent.png")} alt="Collateral" width='100%' />
                        </div>
                        <div className="pt-0 pt-md-4 pb-4 text-center feature-padd">
                            <h3 className="fw-bold primary-color ">0%</h3>
                            <p className='px-md-5 px-2'>Accelerate Rewards: Zero Interest Credit Card Options</p>
                        </div>
                    </div>

                    <div className="col-md-4 col-6">
                        <div className="feature-img-2">
                            <img src={require("../../images/OtherServices/intrest.png")} alt="Collateral" width='100%' />
                        </div>
                        <div className="pt-0 pt-md-4 pb-4 text-center feature-padd">
                            <h3 className="fw-bold primary-color ">Low Interest</h3>
                            <p className='px-md-5 px-2'>Save and Earn with Every Swipe: Credit Card Perks Await</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
