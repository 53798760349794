import './FundingOpportunities.css'

// Images 
import HomeLoan from '../images/HomeLoan.png';
import EducationLoan from '../images/EducationLoan.png';
import PropertyLoan from '../images/PropertyLoan.png';
import BalanceTransfer from '../images/BalanceTransfer.png';


export default function FundingOpportunities() {
    return (
        <>
            <div className="col-md-3 col-6 px-2 px-md-2 px-lg-5">
                <a href="/retail-loan/home-loan">
                    <img src={HomeLoan} alt="Home Loan" width='100%' />
                </a>
                <p className='pt-2'>Home Loan</p>
            </div>
            <div className="col-md-3 col-6 px-2 px-md-2 px-lg-5">
                <a href="/retail-loan/education-loan">
                    <img src={EducationLoan} alt="Education Loan" width='100%' />
                </a>
                <p className='pt-2'>Education Loan</p>
            </div>
            <div className="col-md-3 col-6 px-2 px-md-2 px-lg-5">
                <a href="/retail-loan/loan-against-property">
                    <img src={PropertyLoan} alt="Loan Against Property" width='100%' />
                </a>
                <p className='pt-2'>Loan Against Property</p>
            </div>
            <div className="col-md-3 col-6 px-2 px-md-2 px-lg-5">
                <a href="/retail-loan/balance-transfer">
                    <img src={BalanceTransfer} alt="Balance Transfer" width='100%' />
                </a>
                <p className='pt-2'>Balance Transfer</p>
            </div>
        </>
    )
}