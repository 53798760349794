import { useState } from 'react';
import '../EMI_Calculator.css'
import './ResourcesComponent.css'

import Typography from '@material-ui/core/Typography';
import { HiOutlineArrowRight } from 'react-icons/hi2';
import { Pie } from 'react-chartjs-2';

import LoanJs from 'loanjs'


function nFormatter(num) {
    const lookup = [
      { value: 1, symbol: "" },
    //   { value: 1e3, symbol: " K" },
      { value: 1e5, symbol: " Lakh" },
      { value: 1e7, symbol: " Crore" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(2).replace(rx, "$1") + item.symbol : "0";
}


export default function BusinessEMICalculator({ setVisible, visible, propData }) {

    let minValue = 50000
    let minDuration = 1
    let minIntrest = 8

    let maxValue = 10000000
    let maxDuration = 5
    let maxIntrest = 25

    const [totalpAmountPie, setTotalpAmountPie] = useState(propData.AmOuNt);
    const [totalintrestPie, setTotalIntrestPie] = useState(propData.totInt);


    const [values, setValues] = useState({
        'loanAmount' : propData.AmOuNt,
        'loanTerm' : 5,
        'intrestRate' : propData.InTrEsT,
    })

    const [installments, setInstallments] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
        calculate(values.loanAmount, values.loanTerm, values.intrestRate);
    }

    const calculate = (amount, tenure, intrest) => {
        var loan = new LoanJs.Loan(amount, tenure * 12 , intrest)
        // console.log(loan);
        setInstallments(loan.installments)
        setTotalpAmountPie(loan.amount)
        setTotalIntrestPie(loan.interestSum)
    }

    const handleInputChange = (event) => {
        setVisible(false)
        const {name, value} = event.target

        setValues({
            ...values,
            [name] : parseFloat(value)
        })
    }

    const printContent = () => {
        const printableContent = document.getElementById("printable-content");
        const printWindow = window.open("", "", "height=1000,width=1000");
        printWindow.document.write(printableContent.innerHTML);
        setTimeout(() => {
            printWindow.print();
        }, 100);
    };

    // Restrict input type number to specificlength 
    const minBlockAmount = 50000;
    const maxBlockAmount = 10000000;

    const minBlockTenure = 1;
    const maxBlockTenure = 5;
    
    const minBlockIntrest = 8;
    const maxBlockIntrest = 25;
  
  
    const handleChange = (event, minu, maxu) => {
        const {name, value} = event.target
        // const valu = Math.max(minu, Math.min(maxu, Number(value)));
        setVisible(false)
        setValues({
            ...values,
            [name] : parseFloat(value)
        })
    };


    return (
        <>
            <div className=" ">
                <div className="container d-flex flex-wrap back-resource-emi">
                    {/* Calculator  */}
                    <div className="col-md-6 col-12 mt-4 px-lg-5 px-4 border-end">
                        <div className='mb-4'>
                            <div className='d-flex justify-content-between'>
                                <Typography gutterBottom><strong>Loan Amount</strong></Typography>
                                <input type="number" name='loanAmount' value={values.loanAmount} onChange={(e)=>{handleChange(e, minBlockAmount, maxBlockAmount)}} className='text-center mobWidth loan-amount' maxLength={8} />
                                {/* <p className='mb-1'>₹ {nFormatter(values['loanAmount'])} </p> */}
                            </div>
                            <input type='range' className='w-100 py-2' name='loanAmount' value={values.loanAmount} onChange={(e,vAmt)=>{handleInputChange(e);}} step={50000} max={maxValue} min={minValue} />
                            <div className="d-flex justify-content-between">
                                <p className="mb-3">₹ {nFormatter(minValue)}</p>
                                <p>₹ {nFormatter(maxValue)}</p>
                            </div>
                        </div>
                        <div className='mb-4'>
                            <div className='d-flex justify-content-between'>
                                <Typography gutterBottom><strong>Loan Tenure</strong></Typography>
                                <input type='number' className='text-center w-25' name='loanTerm' value={values.loanTerm} onChange={(e,vDur)=>{handleChange(e, minBlockTenure, maxBlockTenure)}} max={maxDuration} min={minDuration} />
                                {/* <p className="mb-1">{values['loanTerm']} years</p> */}
                            </div>
                            <input type='range' className='w-100 py-2' name='loanTerm' value={values.loanTerm} step={1} onChange={(e,vDur)=>{handleInputChange(e);}} max={maxDuration} min={minDuration} />
                            <div className="d-flex justify-content-between">
                                <p className="mb-3">{minDuration} years</p>
                                <p>{maxDuration} years</p>
                            </div>
                        </div>
                        <div className='mb-4'>
                            <div className='d-flex justify-content-between'>
                                <Typography gutterBottom><strong>Rate of Intrest</strong></Typography>
                                <input type='number' className='w-25 text-center' name='intrestRate' value={values.intrestRate} onChange={(e,vInt)=>{handleChange(e, minBlockIntrest, maxBlockIntrest)}} max={maxIntrest} min={minIntrest} />
                                {/* <p className="mb-1">{values['intrestRate']} %</p> */}
                            </div>
                            <input type='range' className='w-100 py-2' name='intrestRate' value={values.intrestRate} step={0.25} onChange={(e,vInt)=>{handleInputChange(e);}} max={maxIntrest} min={minIntrest} />
                            <div className="d-flex justify-content-between">
                                <p className="mb-3">{minIntrest} %</p>
                                <p>{maxIntrest} %</p>
                            </div>
                        </div>
                        <div className="text-center mb-4 mb-md-0">
                            <button onClick={(e)=>{handleSubmit(e);setVisible(true);}} className="btn btn-primary px-5 py-2 mt-2">
                                View EMI Schedule <HiOutlineArrowRight />
                            </button>
                        </div>
                    </div>


                    {/* Chart and Calculation  */}
                    <div className="col-md-6 col-12 p-md-4 ">
                        <div className="piechart charts">
                            <Pie data={{
                                labels : ['Total Intrest', 'Total Amount'],
                                datasets : [{
                                    data : [totalintrestPie, totalpAmountPie],
                                    backgroundColor : ['#79BE43', '#FFDE3C']
                                }]
                            }}
                            width={200}
                            height={200}
                            />
                        </div>
                        <div className="text-center ">
                            <p><b>Select Loan Product Type</b></p>
                            <div className="emi-txt">
                                <div className='px-1 d-flex justify-content-between'>
                                    <p>🟡 Principal Amount</p>
                                    <p>{nFormatter(totalpAmountPie)}</p>
                                </div>
                                <div className='px-1 d-flex justify-content-between'>
                                    <p>🟢 Interest Amount</p>
                                    <p>{nFormatter(totalintrestPie)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Blank code  */}
                    <div className="col-md-6 col-12"></div>

                    {/* Montly Emi  */}
                    <div className="col-md-6 col-12 montly-emi-section px-0">
                        <div className="d-flex justify-content-around montly-emi fw-bold my-3">
                            <p className='mb-0 fs-5'>Monthly Loan EMI</p>
                            <p className='mb-0 fs-5'>₹ {nFormatter(installments.length !== 0 ? installments[0].installment : propData.monthlyEMI)}</p>
                        </div>
                    </div>

                    
                </div>

                {
                    visible && (
                        <div className='tableStyle'>
                            <div className="d-flex flex-wrap justify-content-between align-items-end">
                                <div className='col-sm-6'>
                                    <h2 className='mt-5 mb-3'>Monthly EMI Breakup</h2>
                                </div>
                                <div className='col-sm-6 text-end pb-3'>
                                    <button className='btn btn-primary px-4' onClick={()=>{printContent();}}>Download / Print</button>
                                </div>
                            </div>
                            {/* "path === '/resources/emi-calculator' && ('d-none')" */}
                            <div id="printable-content">
                                <div className='d-none' > 
                                    <img src={require("../../images/logo.png")} alt="Logo" className="img-fluid" style={{width: '200px', marginBottom: '1rem'}} />
                                </div>
                                <table className='back-resource-emi' style={{width : '100%', textAlign : 'center'}} >
                                    <thead style={{ backgroundColor : '#F2F2F2'}}>
                                        <tr>
                                            <th style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>Months</th>
                                            <th style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>Principal</th>
                                            <th style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>Interest</th>
                                            <th style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>Total Payment</th>
                                            <th style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            installments.map((data, i)=>{
                                                return(
                                                    <tr key={i}>
                                                        <td style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>{i+1}</td>
                                                        <td style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>₹ {nFormatter(data.capital)}</td>
                                                        <td style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>₹ {nFormatter(data.interest)}</td>
                                                        <td style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>₹ {nFormatter(data.installment)}</td>
                                                        <td style={{paddingBottom : '.5rem', paddingTop : '.5rem'}}>₹ {nFormatter(data.remain)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                }
                
            </div>
        </>
    )
}
