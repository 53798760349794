// import { Link } from 'react-router-dom'
import './MenuDropdown.css'
import { resources } from './NavItems'
import { useState } from 'react'

export default function Resorces_Dropdown ({prop}) {
    const [dropdown, setDropdown] = useState(false)

    return(
        <ul className={dropdown ? "sme-dropdown resource-dropdown clicked" : "sme-dropdown resource-dropdown"} onClick={()=>setDropdown(!dropdown)}>
            {
                resources.map(data =>{
                    
                    return(
                        <li className={`${data.cName} mt-2 pb-0 text-decoration-none`} key={data.id}>
                            <a href={data.path} className='text-decoration-none' onClick={()=>{setDropdown(false);prop(false)}}>
                                {data.title} 
                            </a>
                        </li>
                    )
                })
            }
        </ul>
    )
}