import React from 'react'
import './Collateral.css'

export default function Collateral({ prop, noCollateral }) {
    return (
        <>
            {
                noCollateral ?
                noCollateral === 'GO' ?
                <div className="container collateral special-collateral">
                    <div className="col-md-9 col-12 mx-auto box-div">
                        <div className="row">
                            <div className="container d-md-flex justify-content-evenly align-items-center">
                                <div className="col-lg-4 col-md-5 col-12 text-center">
                                    <h3>Who Can Apply</h3>
                                </div>
                                <div className="col-lg-4 col-md-5 col-12">
                                    <ul className='ps-3'>
                                        <li>Business Registration : Required</li>
                                        <li>Business Vintage : Min 3 Years</li>
                                        <li>Age : Min 23 Yrs Old</li>
                                        <li>Annual Turnover : Min 12L</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="container collateral special-collateral">
                    <div className="col-md-9 col-12 mx-auto box-div">
                        <div className="row">
                            <div className="container d-md-flex justify-content-evenly align-items-center py-3">
                                <div className="col-lg-4 col-md-5 col-12 text-center">
                                    <h3>Who Can Apply</h3>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <ul className='ps-3'>
                                        <li>Age : Min 21 Yrs and above</li>
                                        <li>Good Credit Score & Repayment History</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                prop ?
                <div className="container collateral">
                    <div className="col-md-9 col-12 mx-auto box-div">
                        <div className="row">
                            <div className="container d-md-flex justify-content-evenly">
                                <div className="col-lg-4 col-md-5 col-12">
                                    <h3>Who Can Apply</h3>
                                    <ul className='ps-3'>
                                        {
                                            prop.WCA.map((data,i)=>{
                                                return(
                                                    <li key={i}>{data.wca}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="col-lg-4 col-md-5 col-12">
                                    <h3>Collateral to Use</h3>
                                    <ul className='ps-3'>
                                        {
                                            prop.CTU.map((data,i)=>{
                                                return(
                                                    <li key={i}>{data.ctu}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="container collateral">
                    <div className="col-md-9 col-12 mx-auto box-div">
                        <div className="row">
                            <div className="container d-md-flex justify-content-evenly">
                                <div className="col-lg-4 col-md-5 col-12">
                                    <h3>Who Can Apply</h3>
                                    <ul className='ps-3'>
                                        <li>Business Registration : Required</li>
                                        <li>Business Vintage : Min 3 Years</li>
                                        <li>Age : Min 23 Yrs Old</li>
                                        <li>Annual Turnover : Min 12L</li>
                                    </ul>
                                </div>
                                <div className="col-lg-4 col-md-5 col-12">
                                    <h3>Collateral to Use</h3>
                                    <ul className='ps-3'>
                                        <li>Owned Commercial Property</li>
                                        <li>Owned Residential Property</li>
                                        <li>Industrial Property</li>
                                        <li>Warehouse & Buildings</li>
                                        <li>Gold Jewellery & Machinery</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
