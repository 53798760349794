// Secured Business Loans 
export const STLFeature = {
    money : '15L - 15 Cr',
    percent : '9%',
    time : '20 Years',
    home : 'Yes',
    head : 'Secured Term Loans'
}
export const MELFeature = {
    money : '15 L - 100 Cr',
    percent : '8.75%',
    time : '1-10 Years',
    home : 'Yes',
    head : 'Machinery / Equipment Loan'
}
export const LAPFeature = {
    money : '70% value of your property',
    percent : '8.4%',
    time : '20 Years',
    home : 'Yes',
    head : 'Loan Against Property'
}
export const SCCFeature = {
    money : '15L-15Cr',
    percent : '8.25%',
    time : 'Varies on your needs',
    home : 'Yes',
    head : 'Secured Cash Credit'
}
export const WCLFeature = {
    money : '15L',
    percent : '8.25%',
    time : 'Yearly Renewal',
    home : 'Yes',
    head : 'Working Capital Loan'
}
export const SODFeature = {
    money : '15L-15Cr',
    percent : '8.25%',
    time : 'Varies on your needs',
    home : 'Yes',
    head : 'Secured Overdraft'
}



// Unsecured Business Loans 
export const UTLFeature = {
    money : '1L - 5 Cr',
    percent : '10.25%',
    time : 'Upto 5 years',
    home : 'No',
    head : 'Unsecured Term Loan'
}
export const IDFeature = {
    money : '1L - 5 Cr',
    percent : '10.25%',
    time : 'Upto 12 months',
    home : 'No',
    head : 'Unsecured Term Loan'
}
export const LOCFeature = {
    money : '1L - 75 L',
    percent : '16%',
    time : '12 - 60 Months',
    home : 'Yes',
    head : 'Line of Credit'
}
export const UCCFeature = {
    money : '15L - 15 Cr',
    percent : '8.25%',
    time : 'Varies on your needs',
    home : 'Yes',
    head : 'Unsecured Cash Credit'
}



// Balance Transfer 
export const BTFeature = {
    money : '15 Cr',
    percent : '8%',
    time : '30 Years',
    home : 'Yes',
    head : 'Balance Transfer'
}


// Retail Loan
export const HLFeatures = {
    img1 : require('../../images/SBL-Images/money.png'),
    img2 : require('../../images/SBL-Images/percent.png'),
    img3 : require('../../images/SBL-Images/time.png'),
    img4 : require('../../images/SBL-Images/home.png'),
    txt1 : "Tax Benefits",
    txt2 : "Lower Interest",
    txt3 : "Credit History",
    txt4 : "Home Ownership",
}
export const ELFeatures = {
    img1 : require('../../images/SBL-Images/books.png'),
    img2 : require('../../images/SBL-Images/time.png'),
    img3 : require('../../images/SBL-Images/percent.png'),
    img4 : require('../../images/SBL-Images/money.png'),
    txt1 : "Quality Education",
    txt2 : "Flexible Repayment",
    txt3 : "Low Interest",
    txt4 : "Tax Benefits",
}


// Balance Transfer 
export const CGTMSEFeature = {
    money : '5Cr',
    percent : '9.5 - 12%',
    time : '7 Years',
    home : 'No',
    head : 'Credit Guarantee Fund Trust for Micro and Small Enterprises'
}



