import React from 'react'
import './InternalFAQ.css'
import { BsPlusCircle } from 'react-icons/bs'
import { CiCircleMinus } from 'react-icons/ci'




// Accordion 
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


export const InternalFAQ = ({prop}) => {
    // console.log(prop);
  return (
    <div className="col-lg-9 col-12 mx-auto pb-5 pt-4 internal-faq">
        <Accordion allowZeroExpanded='true'>
            {
                prop.map(data=>{
                    return(
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {data.title}
                                    <div className="plus-sign">
                                        <BsPlusCircle />
                                    </div>
                                    <div className="minus-sign">
                                        <CiCircleMinus />
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    {data.para}
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    )
                })
            }
        </Accordion>
    </div>

  )
}
