import React from 'react'
import './OS.css'
import Banner from '../../components/Common/Banner'
import { creditCard } from '../../components/Common/BannerItems'
import HelpSection from '../../components/Common/HelpSection'
// import { InternalFAQ } from '../../components/Common/InternalFAQ'
// import { CCFAQ } from '../../components/Common/InternalFAQItems'
import { CCHelpSlider } from '../../components/Common/HelpSliderItems'
import CCFeatures from '../../components/OtherServices/CCFeatures'
// import WhatsappConnect from '../../components/Common/whatsappConnect'




export default function CreditCard() {
    return (
        <>
            {/* Banner */}
            <div className="banner">
                <Banner prop={creditCard} />
            </div>

            {/* help section  */}
            <div className="bg-internal pad-top">
                <div className="container d-flex flex-wrap">
                    <HelpSection prop={CCHelpSlider} />
                </div>
            </div>

            {/* Distinctive Features */}
            <CCFeatures />

            {/* Reward Freedom  */}
            <div className="bg-dark-blue">
                <div className="container text-white d-flex flex-wrap align-items-center">
                    <div className="col-md-6 col-12 reward-txt mt-5 mt-md-3 mt-lg-5 ">
                        <p className="color-primary mb-4 mb-md-2 mb-lg-4">Discover Our Premier Credit Cards</p>
                        <h2 className="fw-bold mb-4 mb-md-2 mb-lg-4">Convenience. Reward. Freedom.</h2>
                        <p>Experience the convenience and flexibility of a credit card, providing you with financial freedom and peace of mind. Enjoy secure transactions, attractive rewards, and unparalleled customer service. Choose our credit cards for a seamless payment experience and unlock a world of possibilities.</p>
                    </div>

                    <div className="col-md-6 col-12">
                        <div className="reward-img">
                            <img src={require('../../images/SBL-Images/Credit-card-reward.png')} alt="" width='100%' className='' />
                        </div>
                    </div>
                </div>
            </div>

            {/* Internal FAQ  */}
            {/* <div className="faq">
                <div className="container text-center">
                    <p className="primary-color mb-0 pt-5">Useful Links</p>
                    <h2 className='my-4'>Frequently Asked Questions</h2>
                    <p>Everything you need to know about  loan  services and other finance option</p>
                </div>
                <div className="container">
                    <InternalFAQ prop={CCFAQ}/>
                </div>
            </div> */}


            {/* Whatsapp connect  */}
            {/* <WhatsappConnect /> */}
            
            
        </>
    )
}
