export const SBLDocValidation = [
    {
        doc : 'PAN Card and Aadhaar Card'
    },
    {
        doc : 'Residence and Office Ownership Proof'
    },
    {
        doc : 'Business Registration Proof'
    },
    {
        doc : 'GST Certifications and 12 Months GST Returns (if applicable)'
    },
    {
        doc : 'Business Bank Account Statements'
    },
    {
        doc : 'Income Tax Returns (ITR)'
    },
    {
        doc : 'Audited Finances'
    },
    {
        doc : 'Collateral Property Documents'
    },
]

export const MELDocValidation = [
    {
        doc : 'PAN Card and Aadhaar Card'
    },
    {
        doc : 'Residence and Office Ownership Proof'
    },
    {
        doc : 'Business Registration Proof'
    },
    {
        doc : 'GST Certifications and 12 Months GST Returns (if applicable)'
    },
    {
        doc : 'Business Bank Account Statements'
    },
    {
        doc : 'Income Tax Returns (ITR)'
    },
    {
        doc : 'Audited Finances'
    },
    {
        doc : 'Collateral Property Documents'
    },
]

export const LAPDocValidation = [
    {
        doc : 'Aadhar Card and PAN Card'
    },
    {
        doc : '3 years ITR and Form 16'
    },
    {
        doc : '1 year Bank Statement'
    },
    {
        doc : 'Latest Business/Residential Address'
    },
    {
        doc : 'Collateral Details'
    },
]

export const UBLDocValidation = [
    {
        doc : 'PAN Card and Aadhaar Card'
    },
    {
        doc : 'Residence and Office Ownership Property Documents'
    },
    {
        doc : 'Business Registration Proof'
    },
    {
        doc : 'GST Certification and 12 months GST Returns (if applicable)'
    },
    {
        doc : 'Business Bank Account Statements'
    },
    {
        doc : 'Income Tax Return'
    },
    {
        doc : 'Audited Finances'
    },
]

export const LOCDocValidation = [
    {
        doc : 'Promoter Aadhar Card'
    },
    {
        doc : 'Promoter PAN Card'
    },
    {
        doc : 'Residence & Office Ownership Proof'
    },
    {
        doc : 'Current Residential Address Proof'
    },
    {
        doc : 'Business Registration Proof'
    },
    {
        doc : 'Business Bank Account Statements'
    },
]

export const IDDocValidation = [
    {
        doc : 'Promoter Aadhar Card'
    },
    {
        doc : 'Promoter PAN Card'
    },
    {
        doc : 'Residence & Office Ownership Proof'
    },
    {
        doc : 'Current Residential Address Proof'
    },
    {
        doc : 'Business Registration Proof'
    },
    {
        doc : 'Business Bank Account Statements'
    },
    {
        doc : 'Income Tax Returns (ITR) and Computation of Income'
    },
    {
        doc : 'Audited Financials'
    },
    {
        doc : 'GSTR - 3B'
    }
]

export const CGTMSEDocValidation = [
    {
        doc : 'PAN Card and Aadhaar Card'
    },
    {
        doc : 'Residence and Office Ownership Proof'
    },
    {
        doc : 'Business Registration Proof'
    },
    {
        doc : 'GST Certifications and 12 Months GST Returns (if applicable)'
    },
    {
        doc : 'Business Bank Account Statements'
    },
    {
        doc : 'Income Tax Returns (ITR)'
    },
    {
        doc : 'Audited Finances'
    },
]

export const WCLDocValidation = [
    {
        doc : 'Aadhar Card'
    },
    {
        doc : 'PAN Card'
    },
    {
        doc : 'Residence & Office Ownership Proof'
    },
    {
        doc : 'Current Residential Address Proof'
    },
    {
        doc : 'Business Registration Proof'
    },
    {
        doc : 'Business Bank Account Statements'
    },
    {
        doc : 'Income Tax Returns (ITR) and Computation of Income'
    },
    {
        doc : 'Audited Financials'
    },
    {
        doc : 'GSTR - 3B'
    },
    {
        doc : 'Collateral Documents'
    },
]