import React from 'react'

export default function OLS({ prop }) {
    return (
        <>
            {
                prop ?
                <>
                    <div className="container text-center mt-4">
                        <p className='primary-color'>Our Loan Services</p>
                        <h2 className="mb-5 "><b>Explore Limitless Funding Opportunities</b></h2>
                    </div>
                    <div className="container d-flex flex-wrap mb-5">
                        <div className="col-md-3 col-6 px-2 px-md-0">
                            <a href="/secured-business-loan/secured-term-loan">
                                <img src={require("../../images/RetailLoanImages/secured-loan.png")} alt="Secured Business Loan" width='100%' className='px-lg-5 px-md-3' />
                            </a>
                            <p className='px-lg-5 px-md-3 pt-2 pb-3 pb-md-0'>Secured Business Loan</p>
                        </div>

                        <div className="col-md-3 col-6 px-2 px-md-0">
                            <a href="/unsecured-business-loan/unsecured-term-loan">
                                <img src={require("../../images/RetailLoanImages/unsecured-loan.png")} alt="Unsecured Business Loan" width='100%' className='px-lg-5 px-md-3' />
                            </a>
                            <p className='px-lg-5 px-md-3 pt-2 pb-3 pb-md-0'>Unsecured Business Loan</p>
                        </div>

                        <div className="col-md-3 col-6 px-2 px-md-0">
                            <a href="/retail-loan/balance-transfer">
                                <img src={require("../../images/RetailLoanImages/balance-transfer.png")} alt="Balance Transfer" width='100%' className='px-lg-5 px-md-3' />
                            </a>
                            <p className='px-lg-5 px-md-3 pt-2 pb-3 pb-md-0'>Balance Transfer</p>
                        </div>

                        <div className="col-md-3 col-6 px-2 px-md-0">
                            <a href="/other-services">
                                <img src={require("../../images/RetailLoanImages/other-services.png")} alt="Other Services" width='100%' className='px-lg-5 px-md-3' />
                            </a>
                            <p className='px-lg-5 px-md-3 pt-2 pb-3 pb-md-0'>Other Services</p>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="container text-center mt-4">
                        <p className='primary-color'>Our Loan Services</p>
                        <h2 className="mb-5 "><b>Other Loan Services Available</b></h2>
                    </div>
                    <div className="container d-flex flex-wrap mb-5">
                        <div className="col-md-3 col-6 px-2 px-md-0">
                            <a href="/retail-loan/home-loan">
                                <img src={require("../../images/SBL-Images/loanServices/Home-Loan.png")} alt="Home Loan" width='100%' className='px-lg-5 px-md-3' />
                            </a>
                            <p className='px-lg-5 px-md-3 pt-2 pb-3 pb-md-0'>Home Loan</p>
                        </div>

                        <div className="col-md-3 col-6 px-2 px-md-0">
                            <a href="/retail-loan/education-loan">
                                <img src={require("../../images/SBL-Images/loanServices/Education-Loan.png")} alt="Education Loan" width='100%' className='px-lg-5 px-md-3' />
                            </a>
                            <p className='px-lg-5 px-md-3 pt-2 pb-3 pb-md-0'>Education Loan</p>
                        </div>

                        <div className="col-md-3 col-6 px-2 px-md-0">
                            <a href="/retail-loan/loan-against-property">
                                <img src={require("../../images/SBL-Images/loanServices/Loan-Against-Property.png")} alt="Loan Against Property" width='100%' className='px-lg-5 px-md-3' />
                            </a>
                            <p className='px-lg-5 px-md-3 pt-2 pb-3 pb-md-0'>Loan Against Property</p>
                        </div>

                        <div className="col-md-3 col-6 px-2 px-md-0">
                            <a href="/retail-loan/balance-transfer">
                                <img src={require("../../images/SBL-Images/loanServices/Balance-Transfer.png")} alt="Balance Transfer" width='100%' className='px-lg-5 px-md-3' />
                            </a>
                            <p className='px-lg-5 px-md-3 pt-2 pb-3 pb-md-0'>Balance Transfer</p>
                        </div>
                    </div>
                </>
            }
        
        </>
        
    )
}
