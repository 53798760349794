// Home Page 
export const HomeHelpSlider = [
    {
        top : 'Invoice Discounting Made Affordable',
        bottom : 'Take advantage of our Unsecured Business Loan interest rates that are 9% lower than your initial offer, and secured loan interest rates that are 2% lower. Enjoy the best loan structure and terms without any service fees, ensuring you have more funds available to invest in what truly matters - the growth of your business!',
        img : require('../../images/help-section.png'),
        head : 'Bank Tie-ups'
    },
    {
        top : 'Simplify your Business Dreams',
        bottom : 'Unlock limitless possibilities with just one application and a minute of your time! While you work smart, we work diligently to assess your business loan eligibility within seconds. Expect your loan to be disbursed within 3-7 days, enabling you to expedite the realization of your dreams.'
    },
    {
        top : 'Experience Secured Term Loans Redefined by Experts',
        bottom : 'Leveraging the expertise of our professionals, we optimize your opportunities by connecting you with the perfect lender, skillfully negotiating favorable rates, and ensuring the acquisition of the most advantageous business loan deal tailored to your needs.'
    },
    {
        top : 'Trustworthy Partners for Your Business Loans',
        bottom : "Our primary focus is to provide you with business loans you can rely on. That's why we prioritize building trustworthy relationships. With our platform already partnered with over 20 leading banks and NBFCs, and more joining every month, you'll have access to a wide range of options and endless possibilities - all conveniently available in one place."
    },
]


// Secured Business Loan 
export const STLHelpSlider = [
    {
        top : 'Simplify your Business Dreams',
        bottom : 'Experience seamless simplicity through our streamlined process. With a single application in just one minute, unlock endless possibilities for your Secured Term Loan needs. Our dedicated team works tirelessly to ensure you receive the funding you need within 3-7 days.',
        img : require('../../images/SBL-Images/STL-help.png'),
        head : 'Secure Business Loans'
    },
    {
        top : 'Experience Secured Term Loans Redefined by Experts',
        bottom : 'Unlock the power of Secured Term Loans with expert guidance. Our team of professionals maximizes your loan opportunities by connecting you with the ideal lender, negotiating competitive rates, and securing favorable terms tailored to your specific needs.'
    },
    {
        top : 'Trustworthy Partners for Your Business Loans',
        bottom : 'Prioritizing  the ideal business funding for you, we commit to building trust so we collaborate closely with our extensive network of 30+ lenders to secure the best deals on Secured Term Loans in the market. Our goal is to fuel the growth of your small business and empower your dreams to thrive.'
    },
]

export const MELHelpSlider = [
    {
        top : 'Machinery Loans by Experts, Maximizing Success',
        bottom : 'Rely on our team of experts to maximize your chances of success with Machinery Loans. We carefully match you with the ideal lender, negotiate the most favorable interest rates, and secure a business loan deal tailored to your needs. Trust us to unlock the full potential of your loan opportunities.',
        img : require('../../images/SBL-Images/ML-help.png'),
        head : 'Secure Business Loans'
    },
    {
        top : 'Realize Business Dreams with Ease',
        bottom : 'Unlock endless possibilities with just one application in minutes. While you work smart, we work tirelessly to approve your Machinery Loan within 3-7 days, accelerating your journey towards business success and the realization of your dreams.'
    },
    {
        top : 'Trusted Partners for Machinery Loans',
        bottom : 'Your Machinery Loan for MSMEs is our top priority. ? With strong collaboration with our lender partners, we secure the best interest rates and terms for Machinery Loans, empowering your small business to thrive and your dreams to blossom.'
    },
]

export const LAPHelpSlider = [
    {
        top : 'Trustworthy Business Loan Partners',
        bottom : 'When you apply for a Loan Against Property with us, we make it our top priority to obtain you the money you need for your business. That is why we prioritise developing trusting connections with our borrowers and lending partners. We work hard with our partners to achieve the best secured business loan terms possible, allowing your small business to thrive and your goals to grow.',
        img : require('../../images/SBL-Images/LAP-help.png'),
        head : 'Secure Business Loans'
    },
    {
        top : 'Property Loan for Business Delivered by Professionals',
        bottom : 'Applying for a Loan Against Property becomes a breeze with our aid. We prioritise pairing you with the best lender, expertly negotiating the best loan against property interest rates, and providing a business loan arrangement that suits your individual requirements.'
    },
    {
        top : 'Simple Ways to Realise Your Business Goals',
        bottom : 'Unlock infinite possibilities in a couple of minutes with only one application! We understand your drive, and our devoted team works relentlessly to expedite your Loan Against Property, guaranteeing you obtain the funds you require within 3-7 days. With our quick and effective service, you can make your fantasies a reality.'
    },
]

export const SCCHelpSlider = [
    {
        top : 'Simple Ways to Realise Your Business Goals',
        bottom : 'With our streamlined borrowing process, you may simplify your path to business growth. Unlock the possibilities of a Cash Credit loan for SMEs in only 3-7 days with a single application and a few minutes of your time. Trust us to help you achieve your goals while making sound borrowing selections for your company.',
        img : require('../../images/SBL-Images/SCC-help.png'),
        head : 'Secure Business Loans'
    },
    {
        top : 'Loans for no credit. Delivered by Professionals',
        bottom : 'Use our credit knowledge to increase your chances of success by pairing you with your perfect lender, securing reasonable interest rates, and negotiating the finest terms for your Cash Credit loan for SMEs. Trust us to maximise your financial possibilities and fulfil your unique business needs.'
    },
    {
        top : 'Trustworthy Cash Credit Loan Partners',
        bottom : 'Our objective is to secure business capital that is tailored to your specific requirements. We prioritise trusting connections and work closely with our lender partners to provide you with the best Cash Credit loan options available. With us by your side, you can empower your small business to succeed and witness your ideas come true.'
    },
]

export const WCLHelpSlider = [
    {
        top : 'Trust the Experts to Secure your Working Capital Loans',
        bottom : 'Let our team of professionals assist you in finding the most suitable Working Capital Term Loan for your business. With our extensive knowledge and experience, we will connect you with the perfect lender, negotiate excellent rates, and ensure you obtain the best possible deal to meet your business needs. Get the most out of your funding options with our expert guidance.',
        img : require('../../images/SBL-Images/WCL-help.png'),
        head : 'Secure Business Loans'
    },
    {
        top : 'Realize Your Business Dreams with Ease',
        bottom : 'Get ready for a hassle-free, seamless loan application experience with our Working Capital Loans for SMEs. Enjoy lightning-fast approval within 3-7 days, thanks to our dedicated team of experts who work tirelessly to ensure a swift and efficient process. Now, you can pursue your business goals conveniently and effortlessly.'
    },
    {
        top : 'Working Capital Loans Delivered by the Experts',
        bottom : 'Obtain the perfect Working Capital Term Loan for your business with the help of our experienced team. We will connect you with the most suitable lender, negotiate competitive rates, and ensure that you get the best possible deal for your Working Capital Term Loan. Let us help you maximize your funding opportunities'
    },
]

export const SODHelpSlider = [
    {
        top : 'Realize Your Business Dreams with Ease',
        bottom : 'Unlock infinite possibilities with just one loan application. Our dedicated team ensures a swift loan approval process, with overdraft funds available within 3-7 days. Get access to accelerated momentum that empowers you to achieve your dreams and aspirations without any hurdles or delays.',
        img : require('../../images/SBL-Images/SOD-help.png'),
        head : 'Secure Business Loans'
    },
    {
        top : 'Get the Best Overdraft Loans with the Help of Our Experts',
        bottom : "Optimize your loan prospects with the assistance of our skilled credit specialists. We'll match you with the most appropriate lenders who are more likely to approve your loan application. Enjoy favorable overdraft interest rates and negotiate favorable terms for your OD facility with our expert guidance."
    },
    {
        top : 'Business Funding Solutions you can trust',
        bottom : 'At Creditable, we are committed to securing the funding your business requires. We have cultivated strong relationships with our network of lender partners, ensuring that we can negotiate the best overdraft interest rates and terms on your behalf. This will enable your small business to thrive and grow, allowing you to achieve bigger and better things.'
    },
]



// Unsecured Business Loan
export const UTLHelpSlider = [
    {
        top : 'Simplify your Business Dreams',
        bottom : "Simplified Application, Swift Results! With just one-minute application, the possibilities are limitless! We put in the hard work to secure your Unsecured Term Loan within 3-7 days, enabling you to realize your dreams faster and more efficiently.",
        img : require('../../images/SBL-Images/UBL-help.png'),
        head : 'Unsecure Business Loans'
    },
    {
        top : 'Experience Secured Term Loans Redefined by Experts',
        bottom : "With a deep understanding of your business and financial needs, we are committed to maximizing your chances of obtaining a business loan by connecting you with the most suitable lender. Our expertise includes negotiating the best interest rates and securing the most favorable deal for your unsecured term loan, ensuring you receive optimal financing solutions tailored to your specific requirements."
    },
    {
        top : 'Trustworthy Partners for Your Business Loans',
        bottom : "We prioritize delivering your business funding in the quickest timeframe possible. That's why we focus on fostering strong relationships and offering reliable products. Through close collaboration with our trusted lender partners, we secure the most competitive interest rates for Unsecured Term Business Loans in the market. This way, your business can continue to thrive and your dreams can continue to expand."
    },
]

export const IDHelpSlider = [
    {
        top : "Invoice Discounting Made Affordable ",
        bottom : "Harness the potential of your accounts receivables with Invoice Discounting business loans! Benefit from interest rates lower than the market average and access the finest loan structure and terms, all without incurring any service fees. Leave the cash management to us while you concentrate on what truly matters: nurturing growth and achieving success.",
        img : require('../../images/SBL-Images/UBL-help.png'),
        head : 'Unsecure Business Loans'
    },
    {
        top : "Simplify your Business Dreams",
        bottom : "Experience a world of opportunities with Purchase Invoice Discounting or Sales Invoice Discounting by simply submitting your application in just one minute! Our streamlined and efficient process guarantees you receive the necessary funding within 3-7 days, enabling you to swiftly achieve your business aspirations."
    },
    {
        top : "Experience Secured Term Loans Redefined by Experts",
        bottom : "Tap into the knowledge and experience of our Credit Professionals to be paired with the ideal lender for your Sales Invoice Discounting or Purchase Invoice Discounting requirements. We will skillfully negotiate on your behalf to secure the most favorable rates and ensure the optimal deal for your business."
    },
    {
        top : "Trustworthy Partners for Your Business Loans",
        bottom : "At the heart of our operations is the utmost priority of securing your business funding. We place great emphasis on fostering reliable relationships and work closely with our extensive network of 30+ lender partners. This collaborative approach ensures we obtain the most advantageous Invoice Discounting loan deals for your small business, empowering it to flourish and propelling your dreams to new heights."
    },
]

export const LOCHelpSlider = [
    {
        top : "Affordable Line of Credit Loans",
        bottom : "Access business line of credit with interest rates lower than the market! Finexa Capital offers you the opportunity to secure the best loan structure and favorable terms, all without any service fees. This allows you to allocate more resources towards the growth and success of your business.",
        img : require('../../images/SBL-Images/UBL-help.png'),
        head : 'Unsecure Business Loans'
    },
    {
        top : "Simplify your Business Dreams",
        bottom : "Fast and Streamlined Line of Credit Loans for Small Businesses. We empathize with the challenges faced by small businesses when it comes to obtaining a Line of Credit. That's why our goal is to speed up the process and secure your Line of Credit loan within 3-7 days, allowing you to achieve your entrepreneurial dreams sooner. Experience the power of unlocking opportunities with a simple and brief application that takes just minutes to complete."
    },
    {
        top : "Experience Secured Term Loans Redefined by Experts",
        bottom : "Enhance your chances of success by applying for a Line of Credit online with Finexa  Capital. Our team of Credit professionals will help you leverage their expertise. We will match you with the perfect lender, negotiate competitive interest rates for your business Line of Credit, and secure the best funding deal that suits your specific needs and requirements."
    },
    {
        top : "Trustworthy Partners for Your Business Loans",
        bottom : "Your Business Funding Needs, Our Top Priority At our core, we value your business's funding requirements and aim to build trusted relationships. By working closely with our extensive network of lender partners, we ensure that you secure the most advantageous deals for your business lines of credit. This empowers your small business to thrive and allows your aspirations to soar to new heights."
    },
]

export const UCCHelpSlider = [
    {
        top : "Affordable Cash Credit Loans",
        bottom : "Experience lower interest rates that are below market rates! Gain access to a cash credit loan with favorable terms and structure, all without any service fees charged by us. This allows you to allocate more resources towards your business priorities, enabling you to concentrate on the growth and success of your business.",
        img : require('../../images/SBL-Images/UBL-help.png'),
        head : 'Unsecure Business Loans'
    },
    {
        top : "Simplify your Business Dreams",
        bottom : "Streamline your path to business expansion through our effortless borrowing process. By completing a single application in just a few minutes, you can unlock the potential of a Cash Credit loan designed for SMEs within a timeframe of 3-7 days. Rely on us to assist you in achieving your goals while making intelligent borrowing choices for your business."
    },
    {
        top : "Experience Secured Term Loans Redefined by Experts",
        bottom : "Maximize your chances of success by leveraging our credit expertise. We will match you with the perfect lender, secure competitive interest rates, and ensure favorable terms for your Cash Credit loan designed for SMEs. Trust us to optimize your funding options and fulfill your unique business needs."
    },
    {
        top : "Trustworthy Partners for Your Business Loans",
        bottom : "Our mission revolves around obtaining customized business funding that suits your specific requirements. We place great importance on establishing trustworthy relationships and work closely with our network of lender partners to secure the best Cash Credit loan deals for you. With our support, empower your small business to flourish and witness your dreams transform into reality."
    },
]


// Balance Transfer 
export const BTHelpSlider = [
    {
        top : "Simplify your Business Dreams",
        bottom : "Experience boundless opportunities with just a one-minute application! While you work smart, we tirelessly strive to secure your business loan within days, helping you achieve your dreams faster.",
        img : require('../../images/SBL-Images/BT-help.png'),
        head : 'Balance Transfer'
    },
    {
        top : "Experience Secured Term Loans Redefined by Experts",
        bottom : "Enhance your opportunities for success by connecting with the ideal lender for your business loan balance transfer, guided by our team of Credit Experts. Benefit from our expertise as we assist you in negotiating the most favorable balance transfer interest rates and securing a business loan deal that works best for you."
    },
    {
        top : "Trustworthy Partners for Your Business Loans",
        bottom : "Our top priority is securing business funding for you. That's why we prioritize building trustworthy relationships. We collaborate closely with our lender partners to ensure you receive the most favorable deals on secured business loans. This way, your small business can continue to thrive, allowing your aspirations to soar higher."
    }
]


// Credit Card 
export const CCHelpSlider = [
    {
        top : '',
        bottom : "Discover the power of financial flexibility with our credit cards. Enjoy a wide range of benefits, from cashback rewards to exclusive privileges, all while experiencing hassle-free transactions and global acceptance. Choose a credit card that matches your lifestyle and elevate your financial management to new heights.",
        img : require('../../images/Credit-card-help.png'),
        head : 'Other Services'
    }
]

// CGTSME
export const CGTSMEHelpSlider = [
    {
        top : '',
        bottom : "Elevate your business prospects through CGSTME, a dynamic government scheme to empower SMEs. With CGTMSE, you gain access to tailored financial solutions without any collateral security that drive growth and innovation. Explore this opportunity to unleash your enterprise's true potential and secure a brighter future for your business.",
        img : require('../../images/CGTSME-help.png'),
        head : 'CGTMSE',
        title : 'Boost Business Growth'
    }
]

// About us 
export const AUHelpSlider = [
    {
        top : 'Our Vision for the Future',
        bottom : "At Finexa Capital, our vision is to empower individuals and businesses with tailored financial solutions that pave the way for their dreams and aspirations. We strive to be the cornerstone of financial stability, providing expert guidance and unwavering support to help our clients navigate the complex world of finance. Through innovation, integrity, and excellence, we aim to redefine the possibilities of financial success and make a positive impact on the lives we touch.",
        img : require('../../images/AU-help.png'),
        head : 'Our Vision',
        title : 'Shaping Tomorrow Together'
    }
]
export const AUCoreValues = [
    {
        top : 'Guided by Principles',
        bottom : "At Finexa Capital, our values drive everything we do. Integrity guides us to prioritize clients' interests, while expertise ensures top-tier financial guidance. Tailoring solutions to individual goals, we build trust and deliver innovation through technology. Transparent communication empowers informed decisions, fostering lasting relationships. With education and responsibility, we create a positive impact on clients and society.",
        img : require('../../images/AU-values.png'),
        head : 'Our Values',
        title : 'Core Values That Drive Us'
    }
]

