import React from 'react'

export default function whatsappConnect() {
    return (
        <div className="whatsapp-connect bounce2 text-center">
            <div className="p-2 bg-white rounded mb-1 bx-shdow" >
                <p className='text-black mb-0'>Get in touch!</p>
            </div>
            <a href="https://api.whatsapp.com/send?phone=9081570708" target="_blank" rel="noopener noreferrer">
                <img src={require("../../images/whatsapp-logo.png")} alt="Whatsapp Connect" width={50} height={50} />
            </a>
        </div>
    )
}
