import React, { useState } from 'react'
import './resources.css'
import BannerWithoutPartner from '../../components/Common/BannerWithoutPartner'
import { EligibilityBanner } from '../../components/Common/BannerItems'
import BusinessForm from '../../components/ResourcesComponent/BusinessForm'
import HomeForm from '../../components/ResourcesComponent/HomeForm'
import EducationForm from '../../components/ResourcesComponent/EducationForm'
import WhatsappConnect from '../../components/Common/whatsappConnect'


export default function CheckEligibility() {
    const [check, setCheck] = useState(2)


    return (
        <>
            {/* Banner */}
            <div className="banner">
                <BannerWithoutPartner prop={EligibilityBanner} />
            </div>
            

            {/* Heading */}
            <div className="container mt-5">
                <p className="primary-color">Financial Calculator</p>
                {
                    check === 1 && (<h2>Home Loan Eligibility Check</h2>)
                }
                {
                    check === 2 && (<h2>Business Loan Eligibility Check</h2>)
                }
                {
                    check === 3 && (<h2>Education Loan Eligibility Check</h2>)
                }
                
            </div>

            {/* EMI Calculator  */}
            <div className="container mt-5">
                <div className="col-12 col-lg-10 mx-auto back-resource-emi mb-5">
                    <div className="back-menu d-flex">
                        <div id="home-loan" className={`home-loan loan-top-btn ${check === 1 ? 'current' : ''}`}>
                            <button onClick={(e)=>{e.preventDefault();setCheck(1)}}>Home Loan</button>
                        </div>
                        <div id="business-loan" className={`business-loan loan-top-btn ${check === 2 ? 'current' : ''}`}>
                            <button onClick={(e)=>{e.preventDefault();setCheck(2)}}>Business Loan</button>
                        </div>
                        <div id="education-loan" className={`education-loan loan-top-btn ${check === 3 ? 'current' : ''}`}>
                            <button onClick={(e)=>{e.preventDefault();setCheck(3)}}>Educational Loan</button>
                        </div>
                    </div>
                    {
                        check === 1 && (<HomeForm />)
                    }
                    {
                        check === 2 && (<BusinessForm />)
                    }
                    {
                        check === 3 && (<EducationForm />)
                    }
                </div>
            </div>


            {/* Whatsapp connect  */}
            <WhatsappConnect />


        </>
    )
}
