import React from 'react'
import './SME.css'
import Banner from '../../components/Common/Banner'
import { secureOverdraft } from '../../components/Common/BannerItems'
import HelpSection from '../../components/Common/HelpSection'
import Features from '../../components/Common/Features'
import InternalLoanSwiper from '../../components/Common/InternalLoanSwiper'
import { SODFeature } from '../../components/Common/FeatureItems'
import Collateral from '../../components/Common/Collateral'
import { SODLoanSwiper } from '../../components/Common/LoanSwiperItems'
import DocValidation from '../../components/Common/DocValidation'
import OLS from '../../components/Common/OLS'
import { InternalFAQ } from '../../components/Common/InternalFAQ'
import { SODFAQ } from '../../components/Common/InternalFAQItems'
import { SODHelpSlider } from '../../components/Common/HelpSliderItems'
import WhatsappConnect from '../../components/Common/whatsappConnect'



export default function SecureOverdraft() {
    return (
        <>
            {/* Banner */}
            <div className="banner">
                <Banner prop={secureOverdraft} />
            </div>

            {/* help section  */}
            <div className="bg-internal pad-top">
                <div className="container d-flex flex-wrap align-items-center">
                    <HelpSection prop={SODHelpSlider} />
                </div>
            </div>

            {/* Distinctive Features */}
            <Features prop={SODFeature} />

            {/* Loan slider */}
            <div className='bg-light-green pb-5 z-indexx'>
                <div className="container pt-4 pb-5">
                    <InternalLoanSwiper prop={SODLoanSwiper} />
                </div>
            </div>

            {/* who can apply  */}
            <Collateral  />

            {/* Required documents */}
            <div className="req-doc">
                <DocValidation />
            </div>

            {/* Other loan services  */}
            <OLS />

            {/* Internal FAQ  */}
            <div className="faq">
                <div className="container text-center">
                    <p className="primary-color mb-0 pt-5">Useful Links</p>
                    <h2 className='my-4'>Frequently Asked Questions</h2>
                    <p>Everything you need to know about  loan  services and other finance option</p>
                </div>
                <div className="container">
                    <InternalFAQ prop={SODFAQ}/>
                </div>
            </div>


            {/* Whatsapp connect  */}
            <WhatsappConnect />
            
            
        </>
    )
}
