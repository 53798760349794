import './Footer.css'
import Logo from '../images/logo.png'
import { BsTelephone } from 'react-icons/bs'
import { MdOutlineMailOutline } from 'react-icons/md'
import { TfiLocationPin } from 'react-icons/tfi'
import { Link } from 'react-router-dom'
import { navItems, smeProducts, retailLoan, resources } from './Header/NavItems'
import TermsAndConditions from '../pdfs/Finexa_Capital_Terms_and_conditions.pdf'
import PrivacyPolicy from '../pdfs/Finexa_Capital_Privacy_Policy.pdf'


export default function Footer() {


    return(
        <div className='footer pt-3 '>
            {/* Footer top */}
            <div className="container d-flex footer-top my-4">
                <div className="col-md-3">
                    <hr/>
                </div>
                <div className="col-md-6 text-center">
                    <h4 className='primary-color'>Where Financial Dreams Become Reality</h4>
                </div>
                <div className="col-md-3">
                    <hr/>
                </div>
            </div>

            {/* logo & desc  */}
            <div className="container mb-4">
                <div className="col-lg-2 col-4 foot-logo mb-2">
                    <img src={Logo} alt="Logo" className='mb-md-3 mb-2' width='100%' />
                </div>
                <div className="col-md-10 col-8"></div>
                <div className='desc'>
                    <p className='pb-md-3 pb-2'>At FinexaCapital, we are a globally recognized credit insights and technology firm, utilizing advanced data analytics, AI, and technology to tackle the world's most significant financial obstacles. With our pan-India presence, we are committed to delivering innovative solutions that address the diverse needs of the financial landscape.</p>
                </div>
            </div>

            {/* mob mail & add  */}
            <div className="container d-md-flex flex-wrap mb-4 foot-contact">
                <div className="col-md-4 telephone">
                    <div className="d-flex">
                        <BsTelephone />
                        <div className="primary-color ps-2">
                            <p className='mb-0 '>Tel</p>
                            <p>
                                <a href="tel:+919081570708" className='text-decoration-none'>+91 9081570708</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mail">
                    <div className="d-flex">
                        <MdOutlineMailOutline />
                        <div className="primary-color ps-2">
                            <p className='mb-0 '>Mail</p>
                            <p>
                                <a href="mailto:info@finexacapital.com" className='text-decoration-none'>info@finexacapital.com</a>
                            </p>
                        </div>
                    </div>
                    
                </div>
                <div className="col-md-4 location">
                    <div className="d-flex">
                        <TfiLocationPin />
                        <div className="primary-color ps-2">
                            <p className='mb-0 '>Address</p>
                            <p className='pe-2'>214, Soverign Shoppers, Nr Sindhu Sewa Samiti School, Anand Mahel Road Adajan, Surat - 395009</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer menu  */}
            <div className="container d-flex foot-menu">
                <div className="col-md-3 col-5 pb-3 pb-md-0">
                    <h5 className='fw-bold mb-3'>Home</h5>
                    <ul>
                        {
                            navItems.map(data => {
                                return(
                                    <li className='mb-2 pe-lg-0 pe-md-3 ' key={data.id}>
                                        <Link to={data.path} onClick={()=>window.scrollTo(0,0)} className='text-decoration-none light-color'>
                                            {data.title}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                        <li className='mb-2 pe-lg-0 pe-md-3 '>
                            <Link to='/contact-us' onClick={()=>window.scrollTo(0,0)} className='text-decoration-none light-color'>
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="col-md-3 col-7 me-4 me-md-0 pb-3 pb-md-0">
                    <h5 className='fw-bold mb-3'>SME Products</h5>
                    <ul className='position-relative'>
                        {
                            smeProducts.map(data => {
                                // if(data.title === 'Secured Business Loans'){
                                //     return(
                                //         <div >
                                //             <li className={`${data.cName} mt-2 pb-0 text-decoration-none w-100 mb-2 pe-lg-0 pe-md-3 `} onClick={()=>setSblDropdown(!sblDropdown)} onMouseEnter={()=>setSblDropdown(true)} onMouseLeave={()=>setSblDropdown(false)} >
                                //                 <Link to={data.path} className='text-decoration-none d-block w-100 light-color' onClick={()=>{setSblDropdown(false);}}>
                                //                     {data.title} {data.title === 'Balance Transfer' ? <></> : <BsChevronRight />  } 
        
                                //                 </Link>
                                //                 { sblDropdown && <FooterSecuredDropdown perops={setDropdown} />}
                                //             </li>
                                //         </div>
                                //     )
                                // }else if( data.title === 'Unsecured Business Loans'){
                                //     return(
                                //         <div >
                                //             <li className={`${data.cName} mt-2 pb-0 text-decoration-none w-100 mb-2 pe-lg-0 pe-md-3 `} onClick={()=>setUblDropdown(!ublDropdown)} onMouseEnter={()=>setUblDropdown(true)} onMouseLeave={()=>setUblDropdown(false)} >
                                //                 <Link to={data.path} className='text-decoration-none d-block w-100 light-color' onClick={()=>{setUblDropdown(false);}}>
                                //                     {data.title} {data.title === 'Balance Transfer' ? <></> : <BsChevronRight />  } 
                                //                 </Link>
                                //                 { ublDropdown && <FooterUnsecuredDropdown perops={setDropdown} /> }
                                //             </li>
                                //         </div>
                                //     )
                                // }
                                return(
                                    <li className='mb-2 pe-lg-0 pe-md-3 ' key={data.id}>
                                        <Link to={data.path} onClick={()=>window.scrollTo(0,0)} className='text-decoration-none light-color'>
                                            {data.title}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="col-md-3 col-7 me-4 me-md-0 pb-3 pb-md-0">
                    <h5 className='fw-bold mb-3'>Retail Loan</h5>
                    <ul>
                        {
                            retailLoan.map(data => {

                                return(
                                    <li className='mb-2 pe-lg-0 pe-md-3 ' key={data.id}>
                                        <Link to={data.path} onClick={()=>window.scrollTo(0,0)} className='text-decoration-none light-color'>
                                            {data.title}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="col-md-3 col-4 pb-3 pb-md-0">
                    <h5 className='fw-bold mb-3'>Resources</h5>
                    <ul>
                        {
                            resources.map(data => {
                                return(
                                    <li className='mb-2 pe-lg-0 pe-md-3 ' key={data.id}>
                                        <Link to={data.path} onClick={()=>window.scrollTo(0,0)} className='text-decoration-none light-color'>
                                            {data.title}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                        <li className='mb-2 pe-lg-0 pe-md-3 ' key={4}>
                            <a href={TermsAndConditions} target='__blank' rel='noreferrer' className='text-decoration-none light-color'>
                                Terms & Conditions
                            </a>
                        </li>
                        <li className='mb-2 pe-lg-0 pe-md-3 ' key={4}>
                            <a href={PrivacyPolicy} target='__blank' rel='noreferrer' className='text-decoration-none light-color'>
                                Privacy Policy
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Copyright  */}
            <div className="copyright container">
                <div className="col-12 pb-3 text-center">
                    <hr />
                    <p className='mb-0 light-text d-inline-block'>FinexaCapital © 2023, All Rights Reserved.</p>
                    {/* <a href="https://datartsolutions.in/" target="_blank" rel="noopener noreferrer" className='text-decoration-none primary-color opacity-50 link-opacity-100-hover'>Datart Solutions</a> */}
                </div>
            </div>
        </div>
    )
}