import React, { useState } from 'react'
import { toast } from 'react-toastify'
import './ContactForm.css'



export default function ContactForm() {

    const [formData, setformData] = useState({})

    const handleChange = (event) => {
        // console.log(formData);
        const { name, value } = event.target
        let nameValue = name; 
        let valueValue = value;
        setformData({ ...formData, [nameValue]: valueValue })

        if (nameValue === 'fullName') {
            // Validate 'name' field (e.g., it should not be empty)
            const errorElement = document.getElementById('nameError');
            errorElement.innerHTML = valueValue.trim() === '' ? 'Name is required' : '';
        } else if (nameValue === 'emailId') {
            // Validate 'email' field (e.g., it should be a valid email address)
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const errorElement = document.getElementById('emailError');
            errorElement.innerHTML = !emailPattern.test(valueValue) ? 'Invalid email address' : '';
        } else if (nameValue === 'contactNo') {
            // Validate 'contactNo' field (e.g., it should be a 10-digit number)
            const contactNoPattern = /^\d{10}$/;
            const errorElement = document.getElementById('contactNoError');
            errorElement.innerHTML = !contactNoPattern.test(valueValue) ? 'Invalid contact number (10 digits required)' : '';
        }else if (nameValue === 'message') {
            // Validate 'name' field (e.g., it should not be empty)
            const errorElement = document.getElementById('messageError');
            errorElement.innerHTML = valueValue.trim() === '' ? 'Message is required' : '';
        }
    }

    const submitForm = (e) => {
        const config = {
            SecureToken : "b23eeb83-56da-448d-aef0-5af8b919f17d",
            To : 'info@finexacapital.com',
            From : formData.emailId,
            Subject : "New enquiry received form Contact Us",
            Body : `
                Full Name : ${formData.fullName} <br />
                Email Id : ${formData.emailId} <br />
                Phone Number : ${formData.contactNo} <br />
                Occupation : ${formData.occupation} <br />
                Message : ${formData.message}
            `
        }

        if(formData.occupation){
            if(window.Email){
                window.Email.send(config)
                .then(
                    document.getElementById("contactForm").reset(),
                    toast.success('Your application sent successfully', {
                        position: "top-right", // Toast position
                        autoClose: 3000, // Duration in milliseconds
                        hideProgressBar: false, // Whether to display a progress bar
                        closeOnClick: true, // Close toast on click
                        pauseOnHover: true, // Pause the autoClose timer on hover
                        draggable: true, // Enable dragging to dismiss the toast
                        progress: undefined // Custom progress bar component
                    })
                );
            }
        }else{
            toast.error('Please select occupation first', {
                position: "top-right", // Toast position
                autoClose: 3000, // Duration in milliseconds
                hideProgressBar: false, // Whether to display a progress bar
                closeOnClick: true, // Close toast on click
                pauseOnHover: true, // Pause the autoClose timer on hover
                draggable: true, // Enable dragging to dismiss the toast
                progress: undefined // Custom progress bar component
            })
        }
    }

    const options = [
        { option : 'Salaried'},
        { option : 'Self Employed Professional'},
        { option : 'Partner'},
        { option : 'Proprietorship'},
        { option : 'Partnership/LLP'},
        { option : 'Private Limited'},
    ]

    return (
        <form onSubmit={(e)=>{submitForm(e); e.preventDefault();}} id='contactForm'>
            
            <div className='w-100 '>
                <label className='mb-1'>Full Name <span className='text-danger'>*</span></label><br />
                <input required type="text" className='w-100 py-1 px-2' placeholder='Your Name' name='fullName' onChange={handleChange} />
                <small className='error text-danger' id='nameError'></small>
            </div>

            
            <div className='w-100 mt-3'>
                <label className='mb-1'>Email <span className='text-danger'>*</span></label><br />
                <input required type="text" className='w-100 py-1 px-2' placeholder='abc@email.com' name='emailId' onChange={handleChange} />
                <small className='error text-danger' id='emailError'></small>
            </div>

            <div className='w-100 mt-3'>
                <label className='mb-1'>Phone Number <span className='text-danger'>*</span></label><br />
                <input required type="number" className='w-100 py-1 px-2' minLength={10} maxLength={10} placeholder='9876543210' name='contactNo' onChange={handleChange} />
                <small className='error text-danger' id='contactNoError'></small>
            </div>

            <div className='w-100 mt-3'>
                <label className='mb-1'>Occupation <span className='text-danger'>*</span></label><br />
                <select required name="occupation" defaultValue='Select' onChange={handleChange} className='w-100 py-1 px-2'>
                    <option disabled value="Select">Select Occupation Type</option>
                        {
                            options.map((data, i)=>{
                                return(
                                    <option value={data.option} key={i}>{data.option}</option>
                                )
                            })
                        }
                </select>
            </div>

            <div className='w-100 mt-3'>
                <label className='mb-1'>How can we help? <span className='text-danger'>*</span></label><br />
                <textarea required rows="4" cols="50" className='w-100 py-1 px-2' placeholder='Type your message here!' name='message' onChange={handleChange} />
                <small className='error text-danger' id='messageError'></small>
            </div>
            
            <input type="submit" className="btn mt-5 contact-btn w-100 py-2" />

        </form>
    )
}
