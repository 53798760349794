import React, { useEffect, useState } from 'react'
import './resources.css'
import BannerWithoutPartner from '../../components/Common/BannerWithoutPartner'
import { EMIBanner } from '../../components/Common/BannerItems'
import BusinessEMICalculator from '../../components/ResourcesComponent/BusinessEMICalculator'
import WhatsappConnect from '../../components/Common/whatsappConnect'
import { EMIData } from '../../components/ResourcesComponent/EMIData'
import HomeLoanCalculator from '../../components/ResourcesComponent/HomeLoanCalculator'



export default function EMICalculator() {
    const [visible, setVisible] = useState(false)

    
    // Toggle loan calculator
    const [check, setCheck] = useState(2)
    
    // Set redirection
    const id = window.location.search
    useEffect(() => {
        if(id === '?id=1'){
            setCheck(1)
            window.scrollTo({top: 900, left: 0, behavior: 'smooth'})
        }
        if(id === '?id=3'){
            setCheck(3)
            window.scrollTo({top: 900, left: 0, behavior: 'smooth'})
        }
    }, [id])

    // get values and show accordion 
    const getEMI = () => {
        setVisible(true)
    }


    return (
        <>
            {/* Banner */}
            <div className="banner">
                <BannerWithoutPartner prop={EMIBanner} />
            </div>
            

            {/* Heading */}
            <div className="container mt-5">
                <p className="primary-color">Financial Calculator</p>
                {
                    check === 1 && (<h2>Home Loan EMI Calculator</h2>)
                }
                {
                    check === 2 && (<h2>Business Loan EMI Calculator</h2>)
                }
                {
                    check === 3 && (<h2>Education Loan EMI Calculator</h2>)
                }
            </div>

            {/* EMI Calculator  */}
            <div className="container my-5">
                <div className="col-12 col-lg-10 mx-auto">
                    <div className="back-menu d-flex">
                        <div id="home-loan" className={`home-loan loan-top-btn ${check === 1 ? 'current' : ''}`}>
                            <button onClick={(e)=>{e.preventDefault();setCheck(1);setVisible(false);}}>Home Loan</button>
                        </div>
                        <div id="business-loan" className={`business-loan loan-top-btn ${check === 2 ? 'current' : ''}`}>
                            <button onClick={(e)=>{e.preventDefault();setCheck(2);setVisible(false);}}>Business Loan</button>
                        </div>
                        <div id="education-loan" className={`education-loan loan-top-btn ${check === 3 ? 'current' : ''}`}>
                            <button onClick={(e)=>{e.preventDefault();setCheck(3);setVisible(false);}}>Educational Loan</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 mx-auto">
                    {
                        check === 1 && (<HomeLoanCalculator getEMI={getEMI} setVisible={setVisible} visible={visible} />)
                    }
                    {
                        check === 2 && (<BusinessEMICalculator getEMI={getEMI} setVisible={setVisible} visible={visible} propData={EMIData.business} />)
                    }
                    {
                        check === 3 && (<BusinessEMICalculator getEMI={getEMI} setVisible={setVisible} visible={visible} propData={EMIData.education} />)
                    }
                </div>
            </div>


            {/* Whatsapp connect  */}
            <WhatsappConnect />
            

        </>
    )
}
